<ng-container>
  <ng-container *ngIf="cartData$ | async as cartData; else cartEmpty">
    <ng-container *ngIf="profile$ | async as profile">
      <div
        class="d-flex flex-column justify-content-between h-100"
        *ngIf="!isLoading; else loading"
      >
        <div
          class="cart-content-container"
          [ngClass]="
            cartData.entries && cartData.entries.length > 0
              ? 'full-minicart-content'
              : 'empty-minicart-content'
          "
        >
          <div
            *ngIf="cartData.entries && cartData.entries.length === 0"
            class="d-flex align-items-center h-100"
          >
            <fff-mini-cart-empty
              [ngClass]="{ 'w-100': !showActions }"
              [showActions]="showActions"
            ></fff-mini-cart-empty>
          </div>

          <div *ngFor="let entry of reverseArray(cartData.entries)">
            <fff-cart-item
              [entry]="entry"
              [currentB2bUnitIsSplitBiller]="profile.selected.splitBiller"
              [isCustomerSplitBiller]="!!profile.selected.autoSplitBillerActive"
              [accountUid]="profile.selected.uid"
              [showActions]="showActions"
            ></fff-cart-item>
          </div>
        </div>

        <fff-mini-cart-footer
          [showActions]="showActions"
        ></fff-mini-cart-footer>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #cartEmpty>
  <fff-mini-cart-empty
    [ngClass]="{ 'w-100': !showActions }"
    [showActions]="showActions"
  ></fff-mini-cart-empty>
</ng-template>

<ng-template #loading>
  <fff-drawer-loader></fff-drawer-loader>
</ng-template>
