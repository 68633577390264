import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffApplicationConfigService } from '@app/shared/services/fff-application-config.service';
import { take } from 'rxjs/operators';
import { FffInvoiceListDrawerService } from './fff-invoice-list-drawer.service';

@Component({
  selector: 'fff-invoice-list-drawer-body',
  templateUrl: './fff-invoice-list-drawer-body.component.html',
})
export class FffInvoiceListDrawerBodyComponent implements OnInit {
  form: UntypedFormGroup = this.fb.group({
    invoiceNumber: [''],
    open: [false],
    paid: [false],
    dateFrom: [''],
    dateTo: [''],
    manufacturer: [''],
  });

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  isPaymentEnabled: boolean = false;
  manufacturers: any[] = [];
  private __delimiter = '|';

  constructor(
    protected fb: UntypedFormBuilder,
    private fffInvoiceListDrawerService: FffInvoiceListDrawerService,
    private fffApplicationConfigService: FffApplicationConfigService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadConfig();
  }

  loadConfig() {
    this.fffApplicationConfigService
      .getApplicationProperties()
      .pipe(take(1))
      .subscribe(config => {
        this.isPaymentEnabled = !!config.invoicesPaymentEnabled;
        this.manufacturers = config?.invoicesPaymentManufacturer
          ? config.invoicesPaymentManufacturer
              .split(this.__delimiter)
              .map(name => ({ name: name.trim(), selected: false }))
          : [];
        this.manufacturers.push({ name: 'MULTIPLE', selected: false });
        let currentFilters =
          this.fffInvoiceListDrawerService.currentFilters.value;

        if (currentFilters) {
          this.form.patchValue(currentFilters);
          if (currentFilters.manufacturer) {
            this.manufacturers.forEach(m => {
              m.selected = currentFilters.manufacturer
                .split(this.__delimiter)
                .includes(m.name);
            });
          }
        }
        this.fffInvoiceListDrawerService.filtersForm.next(this.form);
        this.cd.markForCheck();
      });
  }

  onManufacturerSelectionChange() {
    this.form.patchValue({
      manufacturer: this.manufacturers
        .filter((m: any) => !!m.selected)
        .map(({ name }) => name)
        .join(this.__delimiter),
    });
    this.fffInvoiceListDrawerService.filtersForm.next(this.form);
  }
}
