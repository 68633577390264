import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffPrebookCartService } from '@app/fff-enterprise/fff-prebook-category/services/fff-prebook-cart.service';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AsmComponentService } from '@spartacus/asm/components';
import { AsmService } from '@spartacus/asm/core';
import {
  AsmAuthStorageService,
  AsmEnablerService,
  AsmUi,
  CsAgentAuthService,
  CustomerListColumnActionType,
  TokenTarget,
} from '@spartacus/asm/root';
import {
  AuthService,
  AuthToken,
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  TranslationService,
  User,
} from '@spartacus/core';
import {
  ICON_TYPE,
  LAUNCH_CALLER,
  LaunchDialogService,
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { cloneDeep } from 'lodash';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

interface CustomerListAction {
  selectedUser: User;
  actionType: CustomerListColumnActionType;
}

@Component({
  selector: 'cx-asm-main-ui',
  templateUrl: './asm-main-ui.component.html',
})
export class FffAsmMainUiComponent implements OnInit, OnDestroy {
  customerSupportAgentLoggedIn$!: Observable<boolean>;
  csAgentTokenLoading$!: Observable<boolean>;
  customer$!: Observable<User | undefined>;
  isCollapsed$: Observable<boolean> | undefined;
  iconTypes = ICON_TYPE;
  filledIconTypes = FILLED_ICON_TYPE;
  @ViewChild('accountNotesPopOver')
  accountNotesPopOver!: NgbPopover;

  private wasOpenedBeforeScroll: boolean = false;
  private isScrollingUp: boolean = false;
  private lastScrollTop: number = 0;
  isPrebookCategoryPage = false;
  loginError!: string;

  @HostBinding('class.hidden') disabled = false;

  protected startingCustomerSession = false;

  subscription: Subscription = new Subscription();

  @ViewChild('customerListLink') element!: ElementRef;

  displayAccountNotes: boolean = false;
  accountNotesList = [
    {
      titleKey: 'asm.accountNotesDialog.account_base.title',
      accountType: 'S',
      accountNotes: '',
      uid: '',
    },
    {
      titleKey: 'asm.accountNotesDialog.account_a.title',
      accountType: 'A',
      accountNotes: '',
      uid: '',
    },
    {
      titleKey: 'asm.accountNotesDialog.account_b.title',
      accountType: 'B',
      accountNotes: '',
      uid: '',
    },
    {
      titleKey: 'asm.accountNotesDialog.account_w.title',
      accountType: 'W',
      accountNotes: '',
      uid: '',
    },
  ];

  constructor(
    protected authService: AuthService,
    protected csAgentAuthService: CsAgentAuthService,
    protected asmComponentService: AsmComponentService,
    protected globalMessageService: GlobalMessageService,
    protected routingService: RoutingService,
    protected asmService: AsmService,
    protected userAccountFacade: UserAccountFacade,
    protected launchDialogService: LaunchDialogService,
    private fffCommunicationService: FffCommunicationService,
    private cd: ChangeDetectorRef,
    protected fffUserAccountService: FffUserAccountService,
    protected asmEnablerService: AsmEnablerService,
    protected prebookCartService: FffPrebookCartService,
    protected authStorageService: AsmAuthStorageService,
    protected translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.disabled = !this.asmEnablerService.isEnabled();
    this.customerSupportAgentLoggedIn$ = this.csAgentAuthService
      .isCustomerSupportAgentLoggedIn()
      .pipe(
        distinctUntilChanged(),
        tap(loggedIn => {
          if (!loggedIn) {
            this.closeModal();
          }
        })
      );

    this.csAgentTokenLoading$ =
      this.csAgentAuthService.getCustomerSupportAgentTokenLoading();

    this.customer$ = this.authService.isUserLoggedIn().pipe(
      switchMap(isLoggedIn => {
        if (isLoggedIn) {
          this.handleCustomerSessionStartRedirection();
          return this.userAccountFacade.get();
        } else {
          return of(undefined);
        }
      })
    );

    this.isCollapsed$ = this.asmService
      .getAsmUiState()
      .pipe(
        map((uiState: AsmUi) =>
          uiState.collapsed === undefined ? false : uiState.collapsed
        )
      );

    this.subscription.add(
      this.launchDialogService.dialogClose
        .pipe(filter(result => Boolean(result)))
        .subscribe((result: CustomerListAction) => {
          if (result.selectedUser) {
            this.startCustomerEmulationSession(result.selectedUser);
            if (
              result.actionType === CustomerListColumnActionType.ORDER_HISTORY
            ) {
              this.routingService.go({ cxRoute: 'orders' });
            }
          }
        })
    );

    this.subscription.add(
      this.customer$
        .pipe(
          tap(user => {
            if (!user) {
              this.displayAccountNotes = false;
              this.cd.markForCheck();
            }
          }),
          filter(user => Boolean(user)),
          switchMap(user => this.loadCurrentAccount(user))
        )
        .subscribe()
    );
    this.subscription.add(
      this.prebookCartService.isPrebookCategoryPage().subscribe(res => {
        this.isPrebookCategoryPage = res;
      })
    );
  }

  protected handleCustomerSessionStartRedirection(): void {
    this.asmComponentService
      .isCustomerEmulationSessionInProgress()
      .pipe(take(1))
      .subscribe(isCustomerEmulated => {
        if (this.startingCustomerSession && isCustomerEmulated) {
          this.startingCustomerSession = false;
          this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
          this.routingService.go('/');
        }
      });
  }

  openPopover() {
    if (this.accountNotesPopOver) {
      this.accountNotesPopOver.open();
    }
  }

  isScrollingUpward(): boolean {
    const scrollTop = window.scrollY;
    const isUp = scrollTop < this.lastScrollTop;
    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For mobile or negative scrolling
    return isUp;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.isPrebookCategoryPage) {
      const currentScrollY = window.scrollY;

      this.isScrollingUp = this.isScrollingUpward();

      if (currentScrollY > 0 && this.accountNotesPopOver?.isOpen()) {
        this.wasOpenedBeforeScroll = true;
        this.accountNotesPopOver.close();
      }

      if (
        this.isScrollingUp &&
        currentScrollY === 0 &&
        this.wasOpenedBeforeScroll
      ) {
        this.accountNotesPopOver.open();
        this.wasOpenedBeforeScroll = false;
      }
    }
  }

  loginCustomerSupportAgent({
    userId,
    password,
  }: {
    userId: string;
    password: string;
  }): void {
    this.csAgentAuthService
      .authorizeCustomerSupportAgent(userId, password)
      .then(() => {
        this.loginError = '';
        let tokenTarget: TokenTarget | undefined;
        let token: AuthToken | undefined;

        combineLatest([
          this.authStorageService.getToken(),
          this.authStorageService.getTokenTarget(),
        ])
          .pipe(take(1))
          .subscribe(([tok, tokTarget]) => {
            token = tok;
            tokenTarget = tokTarget;

            if (
              !(
                Boolean(token?.access_token) &&
                tokenTarget === TokenTarget.CSAgent
              )
            ) {
              this.translationService
                .translate('asm.loginForm.error')
                .subscribe(errorMessage => {
                  this.loginError = errorMessage;
                });
            }
          });
      });
  }

  logout(): void {
    this.asmComponentService.logoutCustomerSupportAgentAndCustomer();
  }

  startCustomerEmulationSession({ customerId }: { customerId?: string }): void {
    if (customerId) {
      this.csAgentAuthService.startCustomerEmulationSession(customerId);
      this.startingCustomerSession = true;
      this.fffUserAccountService.isLoggedInMsg$.next(true);
    } else {
      this.globalMessageService.add(
        { key: 'asm.error.noCustomerId' },
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  hideUi(): void {
    this.disabled = true;
    this.asmComponentService.unload();
  }

  showCustomList(): void {
    this.launchDialogService.openDialogAndSubscribe(
      LAUNCH_CALLER.ASM_CUSTOMER_LIST,
      this.element
    );
  }

  closeModal(): void {
    this.launchDialogService.closeDialog('logout');
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  loadCurrentAccount(user: any) {
    return this.fffCommunicationService.getCurrentAccount(user).pipe(
      tap(user => {
        const allAccounts: any[] = cloneDeep(user?.abwUnits || []);

        allAccounts.push({
          accountNotes: (user?.accountNotes || '').trim(),
          uid: user?.uid || '',
          accountType: user?.accountType || '',
        });

        this.accountNotesList.forEach(item => {
          const matchingItem = cloneDeep(allAccounts).find(
            (unit: any) => unit?.accountType == item.accountType
          );

          item.accountNotes = (matchingItem?.accountNotes || '').trim();
          item.uid = matchingItem?.uid || '';
        });

        this.displayAccountNotes = this.accountNotesList.some(
          item => !!item.accountNotes?.trim()
        );
        this.cd.markForCheck();
        //opens the account notes popover if applicable
        if (this.displayAccountNotes) {
          setTimeout(() => {
            this.openPopover();
          }, 0);
        }
      })
    );
  }
}
