import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { FffRingCentralChatBotService } from '@app/fff-enterprise/fff-service-third-party/fff-ring-central-chat-bot.service';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { OrderConfirmationState } from '@app/reducers';
import { BASE_URL_KEYS } from '@config/content/constants';
import { Store } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';

@Component({
  selector: 'fff-order-confirmation-totals',
  templateUrl: './fff-order-confirmation-totals.component.html',
})
export class FffOrderConfirmationTotalsComponent
  implements OnInit, AfterViewInit
{
  filledIconTypes = FILLED_ICON_TYPE;
  orderTypes = {
    standarOrder: 'STANDAR_ORDER',
    orderRequest: 'ORDER_REQUEST',
    splitBillingOrder: 'SPLIT_BILLING_ORDER',
    semiSplitBillingOrder: 'SEMI_SPLIT_BILLING_ORDER',
    recurringOrder: 'RECURRING_ORDER',
  };

  orderType: string = this.orderTypes.standarOrder;
  order$: Observable<any> = new Subject<any>();
  orderTotals$: Observable<any> = new Subject<any>();
  BASE_URL_KEYS = BASE_URL_KEYS;
  @Input()
  isFromMultiOrder!: boolean;
  @Input()
  orderCode!: string;
  multi: any;

  constructor(
    private elementRef: ElementRef,
    private chatbotService: FffRingCentralChatBotService,
    private store: Store<OrderConfirmationState>
  ) {}

  ngOnInit(): void {
    this.store
      .select((state: any) => {
        return state.OrderConfirmationState;
      })
      .subscribe(orderConfirmationData => {
        if (orderConfirmationData && orderConfirmationData.data) {
          this.order$ = of(orderConfirmationData.data);
          this.getOrderTotalData(orderConfirmationData.data);
        } else {
          this.order$ = of();
        }
      });
  }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.querySelector('.live-chat')) {
      this.elementRef.nativeElement
        .querySelector('.live-chat')
        .addEventListener('click', this.openLiveChat.bind(this));
    }
  }

  openLiveChat(): void {
    this.chatbotService.openChatBot();
  }

  getOrderTotalData(orderConfirmationData: any): void {
    if (
      (orderConfirmationData.orders &&
        orderConfirmationData.orders.length > 0) ||
      (orderConfirmationData.orderRequests &&
        orderConfirmationData.orderRequests.length > 0)
    ) {
      let orderIndex = 0;
      let orderRequestIndex = 0;
      if (this.isFromMultiOrder) {
        orderIndex = orderConfirmationData.orders.findIndex(
          (obj: any) => obj.order && obj.order.code === this.orderCode
        );
        if (orderIndex && orderIndex === -1) {
          orderIndex = 0;
          orderRequestIndex = 0;
        }
      }
      const orderData =
        orderConfirmationData.orders.length > 0
          ? orderConfirmationData.orders[orderIndex].order
          : orderConfirmationData.orderRequests[orderRequestIndex].order;
      const orderType =
        orderConfirmationData.orders.length > 0
          ? orderConfirmationData.orders[orderIndex].type
          : orderConfirmationData.orderRequests[orderRequestIndex].type;
      let orderTotals: any = {
        type: orderType,
        prebookCart: orderData.prebookCart,
        subTotal: orderData.subTotal?.formattedValue,
        deliveryCost: orderData.deliveryCost?.formattedValue,
        totalPrice: orderData.totalPrice?.formattedValue,
      };
      if (orderData.totalTax?.value) {
        orderTotals.totalTax = orderData.totalTax?.formattedValue;
      }

      if (orderData.totalDiscounts?.value) {
        orderTotals.totalDiscounts = orderData.totalDiscounts?.formattedValue;
      }
      this.orderTotals$ = of(orderTotals);
    }
  }

  getOrderType(orderData: any): string {
    if (orderData.orders.length > 1 || orderData.quotes.length > 1) {
      return this.orderTypes.splitBillingOrder;
    } else if (orderData.orderRequests.length > 0) {
      return this.orderTypes.orderRequest;
    } else {
      return this.orderTypes.standarOrder;
    }
  }
}
