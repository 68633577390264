import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FFFActiveCartService } from '@app/fff-enterprise/fff-custom-cart/fff-active-cart-service';
import { FFF_CART_REMOVE_ENTRY_SUCCESS } from '@app/fff-enterprise/fff-custom-cart/fff-cart-entry.action';
import { FffPrebookCartService } from '@app/fff-enterprise/fff-prebook-category/services/fff-prebook-cart.service';
import { Actions, ofType } from '@ngrx/effects';
import { FffUserAccountService } from '@shared/services/fff-user-account.service';
import { CartActions } from '@spartacus/cart/base/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { CUSTOM_ICON_TYPE } from 'src/app/models/fff-custom-icons.model';

@Component({
  selector: 'fff-mini-cart-content',
  templateUrl: './fff-mini-cart-content.component.html',
})
export class FffMiniCartContentComponent implements OnInit, OnDestroy {
  customIconType = CUSTOM_ICON_TYPE;
  isLoading: boolean = false;
  showActions: boolean = false;
  loading$: Observable<boolean> = this.activeCartService.getLoading();
  cartData$ = this.activeCartService.getActive();
  profile$ = this.fffAccountService.getProfile();

  subs = new Subscription();

  constructor(
    private activeCartService: ActiveCartFacade,
    private fffAccountService: FffUserAccountService,
    private fffActiveCartService: FFFActiveCartService,
    private fffPrebookCartService: FffPrebookCartService,
    private actions$: Actions,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loading$.subscribe(loading => (this.isLoading = loading));
    this.subs.add(
      this.actions$
        .pipe(
          ofType(
            CartActions.CART_REMOVE_ENTRY_SUCCESS,
            FFF_CART_REMOVE_ENTRY_SUCCESS
          )
        )
        .pipe(switchMap(() => this.handlePrebookCart()))
        .subscribe()
    );

    this.subs.add(
      this.fffPrebookCartService.isPrebookPage().subscribe(isPrebookPage => {
        this.showActions = !isPrebookPage;
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  reverseArray(a: any) {
    return a;
  }

  handlePrebookCart() {
    return this.activeCartService.getActive().pipe(
      filter(cart => !!cart?.code),
      take(1),
      switchMap((cart: any) =>
        cart?.prebookCart && !cart.entries?.length
          ? this.fffActiveCartService.updateActiveCartPrebookFlag(false)
          : EMPTY
      )
    );
  }
}
