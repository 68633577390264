<ng-container>
  <div class="container" *ngIf="order$ | async as responseOrders">
    <ng-container *ngIf="profile$ | async as profile">
      <div
        class="confirmation-container-top p-4 mt-3"
        [class.confirmation-container-sb-items]="
          getOrderType(responseOrders) === orderTypes.splitBillingOrder ||
          getOrderType(responseOrders) === orderTypes.semiSplitBillingOrder
        "
        *ngFor="let orderData of getOrdersData(responseOrders)"
      >
        <div class="row">
          <div class="col-12">
            <h3 class="title text-center" *ngIf="'ORDER' === orderData.type">
              {{ 'fffCheckoutOrderConfirmation.order' | cxTranslate }}: #{{
                orderData.order.code
              }}
            </h3>
            <h3 class="title text-center" *ngIf="'QUOTE' === orderData.type">
              {{ 'fffCheckoutOrderConfirmation.quote' | cxTranslate }}: #{{
                orderData.order.code
              }}
            </h3>
          </div>
          <div class="col-12">
            <p class="d-flex mb-3">
              <span
                class="body-text-demi"
                *ngIf="orderData.order.purchaseOrderNumber"
                >{{ 'fffCheckoutOrderConfirmation.po' | cxTranslate }}#
                {{ orderData.order.purchaseOrderNumber }}</span
              >
              <span class="ml-auto"
                >{{ 'fffCheckoutOrderConfirmation.datePlaced' | cxTranslate }}
                {{
                  (orderData.order.created
                    ? orderData.order.created
                    : orderData.order.orderRequestSubmittedDate
                    ? orderData.order.orderRequestSubmittedDate
                    : ''
                  ) | date : 'MM/dd/yyyy'
                }}</span
              >
            </p>
          </div>
        </div>

        <ng-container *ngFor="let item of getOrderEntries(orderData)">
          <div
            class="item-entry d-flex pt-3 pb-5"
            [class.item-entry-sb]="
              getOrderType(responseOrders) === orderTypes.splitBillingOrder ||
              getOrderType(responseOrders) === orderTypes.semiSplitBillingOrder
            "
          >
            <div class="col-2 px-0">
              <a
                [routerLink]="
                  { cxRoute: 'product', params: item.product } | cxUrl
                "
                cxModal="dismiss"
                tabindex="-1"
              >
                <cx-media
                  [container]="getData(item.product)"
                  format="product"
                ></cx-media>
              </a>
            </div>

            <div class="cx-info col-10">
              <div class="cx-info-container row">
                <div class="col-md-4 col-sm-12">
                  <div class="text-left">
                    <p class="name mb-0">{{ item.product?.name }}</p>
                    <p class="mb-0">{{ item.product?.manufacturer }}</p>
                    <p class="mb-0">
                      {{ 'fffProduct.lblNdc' | cxTranslate }}
                      {{ item?.product?.ndc }}
                    </p>
                    <p class="mb-0">
                      {{ 'fffProduct.lblSku' | cxTranslate }}
                      {{ item?.product?.code }}
                    </p>
                    <p class="mb-0">
                      {{
                        'fffCheckoutOrderConfirmation.warehouse' | cxTranslate
                      }}
                      {{ item.warehouse }}
                    </p>
                    <p class="mb-0" *ngIf="item?.strength">
                      {{ 'fffProduct.actualStrength' | cxTranslate }}:
                      {{ item.strength }}
                    </p>
                    <p class="mb-0" *ngIf="item?.batch">
                      {{ 'fffProduct.lblBatch' | cxTranslate }}:
                      {{ item?.batch }}
                      <ng-container *ngIf="item?.expirationDate"
                        >| {{ 'fffProduct.exp' | cxTranslate }}:
                        {{
                          parseDate(item?.expirationDate)
                            | cxDate : 'MM/dd/yyyy'
                        }}</ng-container
                      >
                    </p>
                    <p class="mb-0" *ngIf="'S' !== item.accountType">
                      {{ 'fffProduct.lblAccount' | cxTranslate }}:
                      {{
                        'AUTO' === item.accountType
                          ? ('fffProduct.lblAccountAutoSplitBiller'
                            | cxTranslate)
                          : orderData.order.unit.uid
                      }}
                    </p>
                  </div>
                </div>

                <div class="col-md-4 col-sm-12 arrival-date">
                  <div
                    class="text-left"
                    *ngIf="!item.rapidCommit || !item.product.onDemand"
                  >
                    <p class="mb-0 order-qty">
                      {{
                        'fffCheckoutOrderConfirmation.quantity' | cxTranslate
                      }}
                      {{ item.quantity }} {{ item.product.unit }}
                    </p>
                    <ng-container
                      *ngIf="!isOfVaccineTypeI(item); else vaccineUomTemplate"
                    >
                      <ng-container
                        *ngIf="item?.unitConversionFactor && item?.altUom"
                      >
                        <div class="volume body-text-sm">
                          1 {{ item?.uom }} = {{ item?.unitConversionFactor }}
                          {{ item?.altUom }}
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-template #vaccineUomTemplate>
                      (1 {{ item.uom }} = 10 DS)
                    </ng-template>

                    <ng-container *ngIf="item?.altUnitConversion">
                      <div class="volume body-text-sm">
                        {{ item?.altUnitConversion }}
                      </div>
                    </ng-container>

                    <div class="my-3">
                      <div
                        class="mb-0 body-text-sm"
                        *ngIf="orderData.type !== 'QUOTE'"
                      >
                        <p class="mb-0">
                          {{ item.basePrice?.formattedValue }}/{{
                            item.uom | lowercase
                          }}
                        </p>
                      </div>
                      <ng-container
                        *ngIf="
                          item?.altDiscPrice &&
                          item?.altUom &&
                          orderData.type !== 'QUOTE'
                        "
                      >
                        <p class="body-text-sm" style="color: #5f5f5f">
                          <b>
                            ${{ item.altDiscPrice }}/{{
                              item?.altUom | lowercase
                            }}
                          </b>
                        </p>
                      </ng-container>
                    </div>

                    <p
                      class="mb-0 body-text-sm"
                      *ngIf="showEstArrivalDate(item)"
                    >
                      {{
                        'fffCheckoutOrderConfirmation.arrivalDate' | cxTranslate
                      }}
                      <span
                        *ngIf="
                          isPrebookOrder(orderData) && showEstArrivalDate(item)
                        "
                      >
                        <ng-container *ngIf="item.estStartDate">
                          {{ item.estStartDate | date : 'MM/dd/yyyy' }} -
                        </ng-container>
                        {{ item.estArrivalDate | date : 'MM/dd/yyyy' }}
                      </span>
                      <span *ngIf="!isPrebookOrder(orderData)">
                        <ng-container
                          *ngIf="item.estArrivalDate !== '0000-00-00'"
                        >
                          {{ getArrivalDate(item) | date : 'MM/dd/yyyy' }}
                          <span *ngIf="item?.arrivalDatePushed">
                            <fff-tooltip
                              #tooltip
                              text="{{
                                'fffShippingSelector.arrivalDateTooltipText'
                                  | cxTranslate
                              }}"
                              class="warn-tooltip fluseason-tooltip-font-size"
                            >
                            </fff-tooltip>
                            <span
                              class="cursor-pointer"
                              (click)="tooltip.isVisible = true"
                            >
                              <cx-icon
                                class="cx-icon d-inline"
                                [type]="outlinedIconTypes.ICON_INFO_CIRCLE"
                              ></cx-icon>
                            </span>
                          </span>
                        </ng-container>
                      </span>
                    </p>
                  </div>
                </div>

                <div class="col-md-4 col-sm-12 px-0">
                  <div class="text-right">
                    <p class="total" *ngIf="orderData.type !== 'QUOTE'">
                      {{ item.totalPrice?.formattedValue }}
                    </p>
                    <p
                      class="mb-0"
                      *ngIf="
                        item.discountValue &&
                        item.discountValue !== 0 &&
                        orderData.type !== 'QUOTE'
                      "
                    >
                      {{ 'orderCost.discount' | cxTranslate }}
                      (${{ item.discountValue | number : '1.2-2' }})
                    </p>
                    <p
                      class="mb-0"
                      *ngIf="
                        item.taxValue &&
                        item.taxValue !== 0 &&
                        orderData.type !== 'QUOTE'
                      "
                    >
                      {{ 'fffCheckoutOrderConfirmation.feTax' | cxTranslate }}:
                      ${{ item.taxValue | number : '1.2-2' }}
                    </p>
                    <p
                      class="mb-0"
                      *ngIf="
                        'ORDER' === orderData.type || 'QUOTE' !== orderData.type
                      "
                    >
                      {{
                        'fffCheckoutOrderConfirmation.shipping' | cxTranslate
                      }}
                      <span
                        [ngClass]="{ 'shipping-cost': +item.shippingCost > 0 }"
                      >
                        ${{ item.shippingCost ?? 0 | number : '1.2-2' }}
                      </span>
                    </p>
                    <p
                      class="mb-0"
                      *ngIf="
                        'ORDER_REQUEST' === orderData.type &&
                        item.shippingCost &&
                        item.shippingCost !== 0
                      "
                    >
                      {{
                        'fffCheckoutOrderConfirmation.shipping' | cxTranslate
                      }}
                      <span
                        [ngClass]="{ 'shipping-cost': +item.shippingCost > 0 }"
                      >
                        ${{ item.shippingCost ?? 0 | number : '1.2-2' }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <fff-order-confirmation-totals
          [isFromMultiOrder]="true"
          [orderCode]="orderData.order.code"
          *ngIf="
            orderData &&
            orderData.order &&
            orderData.order.code &&
            orderData.type !== 'QUOTE'
          "
        ></fff-order-confirmation-totals>
      </div>
    </ng-container>
  </div>
</ng-container>
