export interface ElavonApprovedPayment {
  ssl_issuer_response: string;
  ssl_partner_app_id: string;
  ssl_card_number: string;
  ssl_oar_data: string;
  ssl_result: string;
  ssl_txn_id: string;
  ssl_avs_response: string;
  ssl_approval_code: string;
  ssl_amount: string;
  ssl_avs_zip: string;
  ssl_txn_time: string;
  ssl_exp_date: string;
  ssl_card_short_description: string;
  ssl_get_token: string;
  ssl_customer_code: string;
  ssl_card_type: string;
  ssl_transaction_type: string;
  ssl_transaction_reference_number: string;
  ssl_avs_address: string;
  ssl_cvm_signature_override_result: string;
  ssl_account_balance: string;
  ssl_ps2000_data: string;
  ssl_result_message: string;
  ssl_invoice_number: string;
  ssl_cvv2_response: string;
  ssl_token: string;
  surchargeAllowed: string;
}

export enum InvoicePaymentEvents {
  FRAME_LOADED = 'FRAME_LOADED',
  APPROVAL = 'APPROVAL',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
  ERROR = 'DECLINED',
}
