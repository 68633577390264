import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, of } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { FffCartFacade } from '../fff-common-services/fff-cart-facade';
import { FffCommunicationService } from '../fff-common-services/fff-communication.service';
import { FffApprovalSentComponent } from './fff-approval-sent/fff-approval-sent.component';
import { FilterApprovalsHistoryComponent } from './filter-approvals-history/filter-approvals-history.component';
import { FilterApprovalsComponent } from './filter-approvals/filter-approvals.component';

@Component({
  selector: 'fff-approvals',
  templateUrl: './fff-approvals.component.html',
})
export class FffApprovalsComponent implements OnDestroy, OnInit {
  profile$: Observable<FffProfile | undefined> =
    this.fffAccountService.getProfile();
  activeIds: string[] = [];
  activeIdsHistory: string[] = [];
  private unsubscribe$ = new Subject<void>();

  constructor(
    public communicationService: FffCommunicationService,
    public cdr: ChangeDetectorRef,
    public fffCartFacade: FffCartFacade,
    public drawerService: FffDrawerService,
    public modalService: NgbModal,
    private fffAccountService: FffUserAccountService,
    private cd: ChangeDetectorRef
  ) {}

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  carts: any = [];
  approvalHistory: any = [];
  changes: any;
  expanded: boolean = false;
  productToAdd: any = [];
  defaultAutoSplitBillingAccount = 'A';
  productPrices: any[] = [];
  cartLoading: boolean = false;
  selectedTab: string = 'pendingApproval';
  approvalHistory$: Observable<any> | undefined;

  ngOnInit(): void {
    this.getApprovals();
    this.getApprovalHistory();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getApprovals() {
    this.toggleApprovalTableLoading(true);
    this.communicationService
      .getApprovals()
      .pipe(take(1))
      .subscribe(
        res => {
          this.carts = res.carts;
          this.toggleApprovalTableLoading(false);
          this.cdr.detectChanges();

          for (let i of this.carts) {
            this.productToAdd.push({
              code: null,
              quantity: null,
              name: null,
            });
          }

          for (let currentCart of this.carts) {
            currentCart.expand = false;
          }

          this.carts.forEach(async (cart: any) => {
            let promises = [];

            this.populateOldQuantity(cart);
            for (let entry of cart.entries) {
              promises.push(
                this.communicationService
                  .getPrices(entry.product.code)
                  .toPromise()
              );
            }

            let priceResponse = await Promise.all(promises);

            for (let prices of priceResponse) {
              //find the price that corresponds to account type S, if that one isn't available, find the A one.
              let findPrice =
                prices.prices.find((price: any) => price.accountType === 'S') ??
                prices.prices.find((price: any) => price.accountType === 'A');
              if (findPrice) {
                this.productPrices.push(findPrice);
              }
            }

            this.cdr.detectChanges();
          });
        },
        () => {
          this.toggleApprovalTableLoading(false);
        }
      );
  }

  getApprovalHistory(): void {
    localStorage.removeItem('approvalHistoryFilters');

    this.approvalHistory$ = this.communicationService
      .getApprovalsHistory()
      .pipe(take(1))
      .pipe(
        map(
          data =>
            (this.approvalHistory = this.getApprovalHistoryGrouped(
              data.approvalEntries
            ))
        )
      );
  }

  getApprovalHistoryGrouped(approvals: any[]): any[] {
    let approvalGrouped: any[] = [];
    approvals.forEach((approval: any) => {
      const existInList = approvalGrouped.some(
        unit => unit.unit === approval.unit
      );
      if (!existInList) {
        approvalGrouped.push({
          unit: approval.unit,
          orderNumber: approval.orderNumber,
          items: [],
          expand: false,
        });
      }
    });

    approvalGrouped.forEach((group: any) => {
      group.items = approvals.filter(approval => group.unit === approval.unit);
    });
    return approvalGrouped;
  }

  private populateOldQuantity(cart: any): void {
    for (let entry of cart?.entries) {
      entry.oldQuantity = entry.quantity;
    }
  }

  openFilterDrawer() {
    let component: Component = FilterApprovalsComponent as Component;
    if ('approvalHistory' === this.selectedTab) {
      component = FilterApprovalsHistoryComponent as Component;
    }
    this.drawerService.setContent({
      title: 'Filter Approvals',
      component: component,
      class: 'mini-cart',
      animation: 'SideRTL',
      data: this.carts,
    });
    this.drawerService.openDrawer();

    this.drawerService.getDrawerResponse
      .pipe(take(1))
      .subscribe((result: any) => {
        if ('approvalHistory' === this.selectedTab) {
          this.filteredApprovalsHistory(result);
        } else {
          this.getFilteredOrders(result);
        }
      });
  }

  getFilteredOrders(r: any) {
    Object.keys(r).forEach((k: any) => {
      r[k] = r[k] === 'All' ? '' : r[k];
    });

    this.communicationService
      .getFilteredApprovals(r.company, r.requested)
      .subscribe((res: any) => {
        this.carts = res.carts;
        this.cdr.detectChanges();
      });
  }

  filteredApprovalsHistory(filters: any): void {
    Object.keys(filters).forEach((k: any) => {
      filters[k] = filters[k] === 'All' ? '' : filters[k];
    });

    this.approvalHistory$ = this.communicationService
      .getApprovalsHistory(filters)
      .pipe(
        map(
          data =>
            (this.approvalHistory = this.getApprovalHistoryGrouped(
              data.approvalEntries
            ))
        )
      );
    this.cdr.detectChanges();
  }

  approvedList: any = [];
  rejectedList: any = [];

  approveOrder(o: any) {
    if (this.allApproved(o)) {
      this.approvedList = this.approvedList.filter((e: any) => e.order !== o);
      return;
    }

    this.approvedList = this.approvedList.filter((e: any) => e.order !== o);
    this.rejectedList = this.rejectedList.filter((e: any) => e.order !== o);

    for (let entry of this.carts[o].entries) {
      entry.entryKey = this.getEntryKey(o, entry);
      this.approvedList.push({ order: o, entry: entry });
    }
  }

  rejectOrder(o: any) {
    if (this.allRejected(o)) {
      this.rejectedList = this.rejectedList.filter((e: any) => e.order !== o);
    } else {
      this.approvedList = this.approvedList.filter((e: any) => e.order !== o);
      this.rejectedList = this.rejectedList.filter((e: any) => e.order !== o);
      for (let entry of this.carts[o].entries) {
        entry.entryKey = this.getEntryKey(o, entry);
        this.rejectedList.push({ order: o, entry: entry });
      }
    }
  }

  private getEntryKey(cartIndex: number, entry: any): string {
    const sapStockStatus = entry.sapStockLevelStatus
      ? entry.sapStockLevelStatus
      : '';
    return `${cartIndex}_${entry.accountType}_${entry.uom}_${entry.warehouse}_${entry.product.code}_${sapStockStatus}`;
  }

  allApproved(o: any) {
    let orderApproved = this.approvedList.filter((e: any) => e.order === o);
    return (
      this.carts[o].entries.length === orderApproved.length &&
      this.carts[o].entries.length > 0
    );
  }

  allRejected(o: any) {
    let orderRejected = this.rejectedList.filter((e: any) => e.order === o);
    return (
      this.carts[o].entries.length === orderRejected.length &&
      this.carts[o].entries.length > 0
    );
  }

  toggleApprovalTableLoading(approvalTableLoading: boolean) {
    this.approvalTableLoading = approvalTableLoading;
    this.cd.markForCheck();
  }

  setProductToAdd(p: any) {
    this.productToAdd = p;
    this.expanded = false;
  }

  async submitActions(cartIndex: any) {
    let calls: any = [];
    this.carts[cartIndex].isSubmitting = true;
    this.cdr.detectChanges();
    this.rejectedList.forEach((rejectedItems: any) => {
      if (rejectedItems.order === cartIndex) {
        calls.push(
          this.communicationService
            .rejectEntry(
              this.carts[cartIndex].code,
              rejectedItems.entry.entryNumber
            )
            .toPromise()
        );
      }
    });

    this.approvedList.forEach((approvedItems: any) => {
      if (approvedItems.order === cartIndex) {
        calls.push(
          this.communicationService
            .approveEntry(
              this.carts[cartIndex].code,
              approvedItems.entry.entryNumber
            )
            .toPromise()
        );
      }
    });

    try {
      await Promise.all(calls);
      this.carts[cartIndex].isSubmitting = false;
      this.openModal();
      this.communicationService
        .loadNotifications()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((notifications: any) => {
          this.communicationService.setNotifications(notifications);
        });
      this.approvedList = [];
      this.rejectedList = [];
      this.activeIds = [];
    } catch (e) {
      this.carts[cartIndex].isSubmitting = false;
      console.log(e);
    }

    this.getApprovals();
  }

  openModal() {
    this.modalService.open(FffApprovalSentComponent, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      modalDialogClass: 'modal-container',
    });
  }

  addProduct(cartIndex: any, userId: any) {
    this.cartLoading = true;
    let e = {
      product: {
        code: this.productToAdd[cartIndex].product.code,
        name: this.productToAdd[cartIndex].product.name.replace(
          /<\/?[^>]+(>|$)/g,
          ''
        ),
      },
      quantity: this.productToAdd[cartIndex].quantity,
      accountType: 'S',
      code: this.productToAdd[cartIndex].product.code,
      ...this.productToAdd[cartIndex].product,
    };

    this.fffCartFacade
      .addCartEntry({ orderEntries: [e] }, this.carts[cartIndex].code)
      .subscribe(
        res => {
          let productCode = res.cartModifications[0].entry.product.code;

          let itemAlreadyInCart = this.carts[cartIndex].entries.find(
            (c: any) => productCode === c.product.code
          );

          if (itemAlreadyInCart) {
            let index =
              this.carts[cartIndex].entries.indexOf(itemAlreadyInCart);
            this.carts[cartIndex].entries[index] =
              res.cartModifications[0].entry;
            res.cartModifications[0].entry.oldQuantity =
              res.cartModifications[0].entry.quantity;
          } else {
            res.cartModifications[0].entry.oldQuantity =
              res.cartModifications[0].entry.quantity;

            const entry = res.cartModifications[0].entry;
            res.cartModifications[0].entry.entryKey = this.getEntryKey(
              cartIndex,
              entry
            );
            this.carts[cartIndex].entries.push(res.cartModifications[0].entry);
            this.approvedList.push({
              order: cartIndex,
              entry: res.cartModifications[0].entry,
            });
          }
          this.fffCartFacade
            .getCartById(this.carts[cartIndex].code, userId)
            .subscribe(
              data => {
                this.populateOldQuantity(data);
                data.expand = true;
                this.carts[cartIndex] = data;
                this.hideSpinner();
              },
              () => {
                this.hideSpinner();
              }
            );

          this.cdr.detectChanges();
        },
        () => this.hideSpinner()
      );

    this.productToAdd[cartIndex] = {
      code: null,
      quantity: 0,
      name: null,
    };

    this.cdr.detectChanges();
  }

  canSubmit(cartIndex: any) {
    let amountOfEntries = this.carts[cartIndex].entries.length;
    let approvedForCart = this.approvedList.filter(
      (elem: any) => elem.order === cartIndex
    ).length;
    let rejectedForCart = this.rejectedList.filter(
      (elem: any) => elem.order === cartIndex
    ).length;

    return amountOfEntries === approvedForCart + rejectedForCart;
  }

  isApproved(c: any, entry: any) {
    const entryKey = this.getEntryKey(c, entry);
    return this.approvedList.find(
      (elem: any) => elem.order === c && elem.entry.entryKey === entryKey
    );
  }

  isRejected(c: any, entry: any) {
    const entryKey = this.getEntryKey(c, entry);
    return this.rejectedList.find(
      (elem: any) => elem.order === c && elem.entry.entryKey === entryKey
    );
  }

  addEntry(cart: any, entry: any, operation: any) {
    if (operation === 'approve') {
      if (this.isRejected(cart, entry)) {
        this.removeEntry(cart, entry, 'rejected');
      }

      entry.entryKey = this.getEntryKey(cart, entry);
      this.approvedList.push({ order: cart, entry: entry });
    } else {
      if (this.isApproved(cart, entry)) {
        this.removeEntry(cart, entry, 'approve');
      }

      entry.entryKey = this.getEntryKey(cart, entry);
      this.rejectedList.push({ order: cart, entry: entry });
      this.cdr.detectChanges();
    }
  }

  removeEntry(cart: any, entry: any, operation: any) {
    if (operation === 'approve') {
      let f = this.isApproved(cart, entry);
      this.approvedList.splice(this.approvedList.indexOf(f), 1);
    } else {
      let f = this.isRejected(cart, entry);
      this.rejectedList.splice(this.rejectedList.indexOf(f), 1);
    }
  }

  products$: Observable<any> | undefined;
  loading = false;
  approvalTableLoading = false;

  searchItems(e: any) {
    this.loading = true;
    if (!e.term) {
      this.loading = false;
      this.products$ = of([]);
      return;
    }

    this.products$ = this.communicationService.getProductsForOrder(e.term).pipe(
      map((response: any) => {
        response = { ...response };
        response.products.forEach((p: any) => {
          p.name = p.name.replace(/<\/?[^>]+(>|$)/g, '');
        });
        this.loading = false;
        return response.products;
      })
    );
  }

  mailTo(email: any) {
    window.open('mailto:' + email);
  }

  getProductPrice(product: any) {
    if (!this.productPrices || this.productPrices.length === 0) {
      return '$' + 0.0;
    }
    let p = this.productPrices.find(
      (price: any) => product.product.code === price.price.productCode
    );
    return p ? '$' + parseFloat(p.price.basicDiscountPrice) : '$' + 0.0;
  }

  getProductTotalPrice(product: any) {
    const productPrice = +this.getProductPrice(product).split('$')[1];
    const totalQty = productPrice * product.quantity;
    return totalQty;
  }

  getEvents(cartEntriesData: any): any {
    let eventsToUpdate = [];
    const cartEvents = cartEntriesData;

    for (const key in cartEvents) {
      if ('UPDATE' === cartEvents[key].action) {
        eventsToUpdate.push(cartEvents[key]);
      }
    }
    return {
      eventsToUpdate: eventsToUpdate,
    };
  }

  updateQuantity(
    event: any,
    entry: any,
    cartCode: any,
    userId: any,
    cartIndex: any
  ): void {
    if (event?.target?.value && entry.oldQuantity != event.target.value) {
      this.cartLoading = true;
      this.fffCartFacade
        .updateCartEntry(entry, entry.entryNumber!, cartCode)
        .subscribe(
          (data: any) => {
            entry.oldQuantity = event.target.value;
            this.fffCartFacade.getCartById(cartCode, userId).subscribe(
              data => {
                this.populateOldQuantity(data);
                data.expand = true;
                this.carts[cartIndex] = data;
                this.hideSpinner();
              },
              () => {
                this.hideSpinner();
              }
            );
          },
          () => {
            this.hideSpinner();
          }
        );
    }
  }

  openSection(id: number, cart: any): void {
    const panelId = `panel-${id}`;
    cart.expand = !cart.expand;

    const index = this.activeIds.indexOf(panelId);
    if (index !== -1) {
      this.activeIds.splice(index, 1);
    } else {
      this.activeIds.push(panelId);
    }
  }

  openSectionHistory(id: number, approvalOrder: any): void {
    const panelId = `oa-panel-${id}`;
    approvalOrder.expand = !approvalOrder.expand;

    const index = this.activeIdsHistory.indexOf(panelId);
    if (index !== -1) {
      this.activeIdsHistory.splice(index, 1);
    } else {
      this.activeIdsHistory.push(panelId);
    }
  }

  private hideSpinner(): void {
    this.cartLoading = false;
    this.cdr.detectChanges();
  }
}