import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { gridBreakpointsLG } from '@app/fff-config/content/constants';
import { defaultPageSize } from '@app/fff-config/pagination/pagination.config';
import { FffInvoiceHistoryService } from '@app/fff-enterprise/fff-common-services/fff-invoice-history-service';
import { FffInvoicePaymentService } from '@app/fff-enterprise/fff-common-services/fff-invoice-payment.service';
import {
  INVOICE_PAYMENT_STEPS,
  InvoiceFilters,
} from '@app/models/fff-invoice.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffTagItem } from '@app/shared/components/form/fff-form-controls/fff-tags/fff-tags.model';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { FffApplicationConfigService } from '@app/shared/services/fff-application-config.service';
import { FffExportedDocumentMessageComponent } from '@enterprise/fff-user-price-list/fff-exported-document-message/fff-exported-document-message';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { RoutingService, TranslationService } from '@spartacus/core';
import { ExportCsvFileService, ExportFileOptions } from '@spartacus/storefront';
import { cloneDeep } from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, skip, take, takeUntil, tap } from 'rxjs/operators';
import { B2BUnitState } from 'src/app/reducers';
import { FffAddCreditCardDialogComponent } from '../fff-add-credit-card/fff-add-credit-card-dialog/fff-add-credit-card-dialog.component';
import { FffInvoiceListDrawerBodyComponent } from '../fff-invoice-list-drawer/fff-invoice-list-drawer-body.component';
import { FffInvoiceListDrawerFooterComponent } from '../fff-invoice-list-drawer/fff-invoice-list-drawer-footer.component';
import { FffInvoiceListDrawerService } from '../fff-invoice-list-drawer/fff-invoice-list-drawer.service';
import { FffInvoicePaymentFailedDialogComponent } from '../fff-invoice-payment-drawer/components/fff-invoice-payment-failed-dialog/fff-invoice-payment-failed-dialog.component';
import { FffInvoicePaymentDrawerComponent } from '../fff-invoice-payment-drawer/fff-invoice-payment-drawer.component';

interface InvoicesData {
  invoices?: any[];
  pagination?: any;
}
interface ITableState {
  data?: any[];
  total: number;
  loading: boolean;
  current: number;
}

@Component({
  selector: 'fff-invoice-list',
  templateUrl: './fff-invoice-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffInvoiceListComponent implements OnInit, OnDestroy {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  columnsGrid: any = {
    desktop: '1fr 1fr 1fr 1fr 1fr 1fr',
    mobile: '1fr 1fr',
  };
  private readonly DEFAULT_FIELDS = [
    { title: 'fffInvoiceHistory.invoice', field: 'invoiceNumber' },
    { title: 'fffInvoiceHistory.invoiceDate', field: 'invoiceDate' },
    { title: 'fffInvoiceHistory.invoiceDueDate', field: 'dueDate' },
    { title: 'fffInvoiceHistory.status', field: 'invoiceStatus' },
    { title: 'fffInvoiceHistory.po', field: 'poNumber' },
    {
      title: 'fffInvoiceHistory.manufacturer',
      field: 'manufacturer',
      hidden: false,
      sortable: true,
    },
    { title: 'fffInvoiceHistory.actions.actions', field: 'actions' },
    { title: 'fffInvoiceHistory.amount', field: 'grandTotal', hidden: true },
  ];
  fields: any[] = [];
  selectedFilters: FffTagItem[] = [];

  private DEFAULT_PAGE_NUMBER = 0;
  private FIELD_PAGE = 'page';

  filters: InvoiceFilters = {};
  currentPage: number = this.DEFAULT_PAGE_NUMBER;
  currentSort: number = 0;

  private unsubscribe$: Subject<void> = new Subject<void>();
  selectedInvoices: any[] = [];
  selectedInvoicesTotalAmount: number = 0;
  invoicesData: InvoicesData = {};
  invoicesTableState: ITableState = {
    data: undefined,
    total: 0,
    loading: false,
    current: 0,
  };

  mobileSize: any = gridBreakpointsLG;

  subs = new Subscription();

  b2bunit: B2BUnitState['B2BUnit'] = {} as B2BUnitState['B2BUnit'];
  loading: boolean = true;
  isPaymentEnabled: boolean = false;
  isSurchargePaymentEnabled: boolean = false;
  hasPaymentColumns: boolean = false;
  disablePaymentActions: boolean = false;

  get hasCcPaymentEligibleItem(): boolean {
    return (
      this.invoicesTableState?.data?.some((i: any) => !!i.ccPaymentEligible) ??
      false
    );
  }

  constructor(
    protected routing: RoutingService,
    private fffInvoiceHistoryService: FffInvoiceHistoryService,
    private drawerService: FffDrawerService,
    private fffInvoiceListDrawerService: FffInvoiceListDrawerService,
    private translationService: TranslationService,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private exportCSVService: ExportCsvFileService,
    private modalService: NgbModal,
    public router: Router,
    private csAgentAuthService: CsAgentAuthService,
    private fffApplicationConfigService: FffApplicationConfigService,
    private fffInvoicePaymentService: FffInvoicePaymentService
  ) {
    if (this.activatedRoute.snapshot.queryParams[this.FIELD_PAGE]) {
      this.currentPage =
        this.activatedRoute.snapshot.queryParams[this.FIELD_PAGE];
    }
  }

  ngOnInit(): void {
    this.fffApplicationConfigService.loadApplicationProperties().subscribe();

    this.subs.add(
      this.fffApplicationConfigService
        .getApplicationProperties()
        .pipe(
          skip(1),
          take(1),
          map(config => ({
            invoicesPaymentEnabled: !!config.invoicesPaymentEnabled,
            invoicesSurchargePaymentEnabled:
              config.invoicesSurchargePaymentEnabled &&
              config.invoicesSurchargePaymentEnabled == 'true'
                ? true
                : false,
          }))
        )
        .subscribe(
          ({ invoicesPaymentEnabled, invoicesSurchargePaymentEnabled }) => {
            this.isPaymentEnabled = invoicesPaymentEnabled;
            this.isSurchargePaymentEnabled = invoicesSurchargePaymentEnabled;
            this.init();
            this.changeDetectorRef.markForCheck();
          }
        )
    );

    this.subs.add(
      this.csAgentAuthService
        .isCustomerSupportAgentLoggedIn()
        .pipe(
          tap(isCustomerSupportAgentLoggedIn => {
            this.disablePaymentActions = !!isCustomerSupportAgentLoggedIn;
            this.changeDetectorRef.markForCheck();
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    if (this.unsubscribe$) {
      this.unsubscribe$.unsubscribe();
    }
    this.subs.unsubscribe();
  }

  init() {
    this.selectedInvoices = [];
    this.selectedInvoicesTotalAmount = 0;
    this.loadInvoices(this.currentPage);
  }

  hasData(invoiceHistory: any): boolean {
    return invoiceHistory?.invoices?.length > 0;
  }

  getTableSizeFromPagination(data: any): number {
    if (data && data.pagination && data.pagination.pageSize) {
      return data.pagination.pageSize;
    }
    return defaultPageSize;
  }

  getTableData(invoiceHistory: any | undefined) {
    if (invoiceHistory && invoiceHistory.invoices) {
      return {
        data: invoiceHistory.invoices,
        total: invoiceHistory.pagination.totalResults,
        loading: false,
        current: invoiceHistory.pagination.currentPage,
      };
    } else {
      return { data: [], total: 0, loading: false, current: 0 };
    }
  }

  setTableState() {
    let state: ITableState = {
      data: undefined,
      total: 0,
      loading: false,
      current: 0,
    };

    if (this.invoicesData && this.invoicesData.invoices) {
      state = {
        data: this.invoicesData.invoices,
        total: this.invoicesData.pagination.totalResults,
        loading: false,
        current: this.invoicesData.pagination.currentPage,
      };
    }

    this.invoicesTableState = cloneDeep(state);
    // this.sortResults();
    this.changeDetectorRef.markForCheck();
  }

  formatFields() {
    this.columnsGrid.desktop = '';
    this.fields = this.DEFAULT_FIELDS.map(item => {
      if (this.isPaymentEnabled && item.field === 'grandTotal') {
        return {
          ...item,
          hidden: false,
        };
      }
      return {
        ...item,
      };
    }).filter(field => !field.hidden);
    // Taking percentiles, 'po', 'manufacturer' - to take 1.6 times of regular column
    let columnSize = 100 / (this.fields.length === 8 ? 9.2 : 7.6);
    this.fields.forEach(item => {
      let gridValue = `${columnSize}fr `;
      if (['manufacturer'].includes(item.field)) {
        gridValue = `${columnSize * (this.hasPaymentColumns ? 1.8 : 1)}fr `;
      }
      if (['poNumber'].includes(item.field)) {
        gridValue = `${columnSize * (this.hasPaymentColumns ? 1.3 : 1)}fr `;
      }
      if (['invoiceStatus'].includes(item.field)) {
        gridValue = `${columnSize * 0.7}fr `;
      }
      if (['grandTotal'].includes(item.field)) {
        gridValue = `${columnSize * 0.7}fr `;
      }
      if (['amount'].includes(item.field)) {
        gridValue = `${columnSize}fr `;
      }
      this.columnsGrid.desktop += gridValue;
    });
  }

  pageEvent(eventChange: any) {
    const nextPage = eventChange.page - 1;
    this.currentPage = nextPage;
    this.loadInvoices(nextPage, this.filters);
  }

  sortResults() {
    this.invoicesTableState.data = (this.invoicesData.invoices || []).sort(
      (a, b) =>
        ('' + a?.materials?.[0]?.manufacturer).localeCompare(
          b?.materials?.[0]?.manufacturer
        ) * this.currentSort
    );
    this.changeDetectorRef.markForCheck();
  }

  handleSortChange(data: any) {
    this.currentSort = data.sort;
    this.sortResults();
  }

  toggleLoading(loading: boolean) {
    this.loading = loading;
    this.changeDetectorRef.markForCheck();
  }

  getFormattedInvoices(res: InvoicesData): InvoicesData {
    const formattedRes = cloneDeep(res);
    formattedRes.invoices?.forEach(invoice => {
      invoice.selected = this.selectedInvoices.some(
        i => i.invoiceNumber === invoice.invoiceNumber
      );
    });
    return formattedRes;
  }

  loadInvoices(pageNumber: number, filters?: any) {
    this.invoicesTableState.loading = true;
    this.toggleLoading(true);
    this.getInvoiceHistoryList(pageNumber, filters).subscribe(
      (res: InvoicesData) => {
        this.invoicesData = this.getFormattedInvoices(res);
        this.hasPaymentColumns =
          this.isPaymentEnabled &&
          this.invoicesData?.invoices?.find(
            invoice => !!invoice?.ccPaymentEligible
          );
        this.formatFields();
        this.setTableState();
        this.toggleLoading(false);
      },
      () => {
        this.toggleLoading(false);
      }
    );
  }

  private getInvoiceHistoryList(
    pageNumber: number,
    filters?: any
  ): Observable<any> {
    return this.fffInvoiceHistoryService
      .getInvoiceHistoryList(pageNumber, filters)
      .pipe(takeUntil(this.unsubscribe$), take(1));
  }

  goToInvoiceDetails(invoiceId: string): void {
    this.routing.go(['/my-account/invoice'], {
      queryParams: { invoiceNumber: invoiceId, page: this.currentPage },
    });
  }

  openInvoiceFilterDrawer(): void {
    if (this.filters) {
      this.fffInvoiceListDrawerService.currentFilters.next(this.filters);
    }

    this.translationService
      .translate('fffInvoiceFilterHistory.title')
      .subscribe(title => {
        this.drawerService.setContent({
          title: title,
          animation: 'SideRTL',
          component: FffInvoiceListDrawerBodyComponent as Component,
          drawerFooter: FffInvoiceListDrawerFooterComponent as Component,
          class: 'face-filter-drawer-content',
        });
        this.drawerService.openDrawer();

        const result$ = this.drawerService.getDrawerResponse.pipe(
          takeUntil(this.unsubscribe$),
          take(1)
        );
        result$.subscribe((filters: any) => {
          if (filters) {
            this.filters = filters;
            this.setFiltersForDisplay(filters);
            this.loadInvoices(this.DEFAULT_PAGE_NUMBER, filters);
            this.changeDetectorRef.detectChanges();
          }
        });
      });
  }

  async exportCSV() {
    this.openExportedDocumentSuccess();
    let r = await this.parseExportInfo();
    let options: ExportFileOptions = {
      fileName: 'invoiceList',
      extension: 'csv',
      type: 'multipart/form-data',
    };
    this.exportCSVService.download(r, ',', options);
  }

  openExportedDocumentSuccess() {
    this.modalService.open(FffExportedDocumentMessageComponent, {
      centered: true,
      backdrop: 'static',
    });
  }

  async parseExportInfo() {
    let invoiceList: any = await this.fffInvoiceHistoryService
      .getAllInvoices()
      .toPromise();

    let data = invoiceList.invoices.map((invoiceItem: any) => {
      return {
        ...invoiceItem,
        dueDate: invoiceItem.dueDate?.split('T')[0],
        invoiceDate: invoiceItem.invoiceDate?.split('T')[0],
      };
    });

    let fileNames: any[] = [];

    this.fields.forEach((f: any) => {
      this.translationService.translate(f.title).subscribe(res => {
        fileNames.push(res);
      });
    });

    let exportFileEntries: string[][] = [];

    //Reserved for titles
    let fileTitles = this.fields.map((e: { field: any }) => {
      return e.field;
    });

    exportFileEntries[0] = fileNames;

    data.forEach((e: any, index: any) => {
      exportFileEntries[index + 1] = [];

      fileTitles.forEach((key: any) => {
        exportFileEntries[index + 1].push(e[key] ? e[key] : ' ');
      });
    });

    return exportFileEntries;
  }

  goToReportsPage() {
    this.router.navigateByUrl('my-account/reports');
  }

  updateGrandTotal() {
    this.selectedInvoicesTotalAmount = 0;

    this.selectedInvoices.forEach((invoice: any) => {
      this.selectedInvoicesTotalAmount += parseFloat(invoice?.grandTotal || 0);
    });
    this.changeDetectorRef.markForCheck();
  }

  onInvoicePaySelectionChange(checked: boolean, invoice: any) {
    if (checked) {
      this.selectedInvoices.push(invoice);
    } else {
      const index = this.selectedInvoices.findIndex(
        (i: any) => i.invoiceNumber === invoice.invoiceNumber
      );
      if (index !== -1) {
        this.selectedInvoices.splice(index, 1);
      }
    }
    this.updateGrandTotal();
  }

  setFiltersForDisplay(data: any) {
    const result = [];
    if (data.dateFrom) {
      result.push({
        id: 'dateFrom',
        displayValue: `From: ${this.formatDate(data.dateFrom)}`,
      });
    }
    if (data.dateTo) {
      result.push({
        id: 'dateTo',
        displayValue: `To: ${this.formatDate(data.dateTo)}`,
      });
    }
    if (data.open) {
      result.push({
        id: 'open',
        displayValue: 'Open',
      });
    }
    if (data.paid) {
      result.push({
        id: 'paid',
        displayValue: 'Paid',
      });
    }
    if (data.ccPaymentEligible) {
      result.push({
        id: 'ccPaymentEligible',
        displayValue: 'Credit Card Eligible',
      });
    }
    // Pushing manufacturers
    if (data.manufacturer) {
      const manufacturers = data.manufacturer.split('|');
      if (manufacturers && manufacturers.length > 0) {
        manufacturers.forEach((manufacturer: any, index: number) => {
          const id = `manufacturer_${index}`;
          result.push({ id, displayValue: manufacturer });
        });
      }
    }
    const filteredResult = result.filter(
      (obj, index, self) => index === self.findIndex(t => t.id === obj.id)
    );
    this.selectedFilters = filteredResult;
    this.changeDetectorRef.detectChanges();
  }
  formatDate(dateValue: any): string {
    const day = String(dateValue.day).padStart(2, '0'); // Pad with leading zero if single digit
    const month = String(dateValue.month).padStart(2, '0'); // Pad with leading zero if single digit
    const year = String(dateValue.year);
    return `${day}/${month}/${year}`;
  }
  removeItem(item: FffTagItem, index: number) {
    this.selectedFilters.splice(index, 1);
    const updatedFilter: any = this.filters;
    if (
      item.id === 'dateTo' ||
      (item.id === 'dateFrom' && updatedFilter[item.id])
    ) {
      updatedFilter[item.id] = '';
    }
    if (
      item.id === 'open' ||
      item.id === 'paid' ||
      item.id === 'ccPaymentEligible'
    ) {
      updatedFilter[item.id] = false;
    }
    if (
      item.id &&
      item.id.includes('manufacturer') &&
      updatedFilter.manufacturer
    ) {
      const updatedManufacturer = this.removeSelectedStatus(
        updatedFilter['manufacturer'],
        item.displayValue
      );
      updatedFilter['manufacturer'] = updatedManufacturer;
    }
    this.fffInvoiceListDrawerService.currentFilters.next(this.filters);
    this.loadInvoices(this.DEFAULT_PAGE_NUMBER, this.filters);
    this.changeDetectorRef.detectChanges();
  }
  removeSelectedStatus(
    appliedStatuses: string,
    statusToRemove: string
  ): string {
    let statuses = appliedStatuses.split('|');

    statuses = statuses.filter((word: any) => word.trim() !== statusToRemove);

    let result = statuses.join('|');

    result = result.replace(/(^,|,$|,{2,})/g, '|');

    return result;
  }

  clearAllFilters() {
    this.selectedFilters = [];
    this.filters = {};
    this.fffInvoiceListDrawerService.currentFilters.next(this.filters);
    this.loadInvoices(this.DEFAULT_PAGE_NUMBER, this.filters);
    this.changeDetectorRef.detectChanges();
  }

  openPaymentDialog(config?: any) {
    this.fffInvoicePaymentService.setSelectedCreditCardOnGoBack(null);
    if (config?.isFromPayBtn) {
      this.fffInvoicePaymentService.setCurrentStep(
        INVOICE_PAYMENT_STEPS.SELECT_CARD
      );
    }
    const modalRef = this.modalService.open(FffInvoicePaymentDrawerComponent, {
      centered: false,
      size: 'xl',
      windowClass: 'fff-modal fff-modal-invoice-payment',
      keyboard: false,
      backdrop: 'static',
    });

    modalRef.componentInstance.invoices = config?.isFromPayBtn
      ? this.selectedInvoices
      : this.fffInvoicePaymentService.getCurrentInvoices();
    modalRef.componentInstance.newCreditCardAdded =
      !!config?.newCreditCardAdded;

    modalRef.closed.subscribe(res => {
      if (res.paymentCompleted) {
        this.fffInvoicePaymentService.clearNewCards();
        this.selectedInvoices = [];
        this.selectedInvoicesTotalAmount = 0;
        this.clearAllFilters();
        return;
      }

      if (res.initiateAddNewCard) {
        this.openAddNewCardDialog();
        return;
      }

      if (res.reset) {
        this.fffInvoicePaymentService.clearNewCards();
        this.selectedInvoices = [];
        this.selectedInvoicesTotalAmount = 0;
        this.filters.ccPaymentEligible = false;
        this.invoicesTableState?.data?.forEach(item => {
          item.selected = false;
        });
        this.changeDetectorRef.markForCheck();
      }

      if (res.errorOccurred) {
        this.openErrorDialog();
      }
    });
  }

  openErrorDialog() {
    const modalRef = this.modalService.open(
      FffInvoicePaymentFailedDialogComponent,
      {
        centered: false,
        size: 'md',
        windowClass: 'fff-modal fff-modal-invoice-payment-failed',
      }
    );

    modalRef.closed.subscribe(res => {
      if (res.useDifferentCard) {
        this.modalService.dismissAll();
        this.fffInvoicePaymentService.setCurrentStep(
          INVOICE_PAYMENT_STEPS.SELECT_CARD
        );
        this.openPaymentDialog();
      }
    });
  }

  openAddNewCardDialog() {
    const modalRef = this.modalService.open(FffAddCreditCardDialogComponent, {
      centered: false,
      size: 'xl',
      windowClass: 'fff-modal fff-modal-invoice-payment',
      keyboard: false,
      backdrop: 'static',
    });
    modalRef.componentInstance.isTransactionFlow = true;

    modalRef.closed.subscribe(res => {
      if (res.initiatePayment) {
        this.modalService.dismissAll();
        this.openPaymentDialog();
      }

      if (res.creditCardAdded) {
        this.modalService.dismissAll();
        this.openPaymentDialog({
          newCreditCardAdded: true,
        });
      }
      if (res.reset) {
        this.fffInvoicePaymentService.clearNewCards();
        this.selectedInvoices = [];
        this.selectedInvoicesTotalAmount = 0;
        this.filters.ccPaymentEligible = false;
        this.invoicesTableState?.data?.forEach(item => {
          item.selected = false;
        });
        this.changeDetectorRef.markForCheck();
      }

      if (res.newCardDetails) {
        this.openPaymentDialog(res.newCardDetails);
      }
    });
  }
  isHiddenBasedOnSurcharge(invoice: any) {
    if (this.isSurchargePaymentEnabled) {
      return false;
    } else {
      if (invoice.surchargeECheckPaymentEligible) {
        return true;
      } else {
        return false;
      }
    }
  }
}
