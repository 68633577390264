import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffChangeAccountDrawerComponent } from '@app/fff-enterprise/fff-header/fff-change-account/fff-change-account-drawer.component';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { CustomerState, SET_CUSTOMER_SPLIT_BILLER } from '@app/reducers';
import { BASE_URL_KEYS } from '@config/content/constants';
import { FffB2bUnit } from '@model/fff-b2b-unit.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { WindowRef } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'fff-profile-switch-account',
  templateUrl: './fff-profile-switch-account.component.html',
})
export class FffProfileSwitchAccountComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() profile!: FffProfile;
  @Output() reloadAccount: EventEmitter<void> = new EventEmitter<void>();
  @Output() isUpdating: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );
  @Output() setResult: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  public outlinedIconTypes = OUTLINED_ICON_TYPE;
  loading: boolean = false;
  billerSelected: boolean = true;
  abwAccountsArray: Array<FffB2bUnit> = [];
  fffChangeAccountDrawer: NgbModalRef | null = null;
  associatedAccounts$: Observable<any> | undefined;
  BASE_URL_KEYS = BASE_URL_KEYS;

  constructor(
    private communicationService: FffCommunicationService,
    private windowRef: WindowRef,
    private storeCustomer: Store<CustomerState>,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.billerSelected = !!this.profile.selected.autoSplitBillerActive;
    this.associatedAccounts$ = this.communicationService
      .getAssociatedAccounts(this.profile.user.uid)
      .pipe(
        map(ac => {
          ac.b2bunits.sort((a: FffB2bUnit, b: FffB2bUnit) =>
            a.accountType.localeCompare(b.accountType)
          );
          return ac;
        })
      );
    this.getABWAccounts();
  }

  getABWAccounts(): void {
    this.abwAccountsArray = this.profile.accounts.filter(account => {
      return /^[ABW]$/.test(account.accountType);
    });
  }

  openChangeAccountDrawer() {
    if (!this.fffChangeAccountDrawer) {
      this.fffChangeAccountDrawer = this.modalService.open(
        FffChangeAccountDrawerComponent,
        {
          modalDialogClass: 'fff-drawer',
          backdrop: 'static',
          windowClass: 'fff-window',
        }
      );
      this.fffChangeAccountDrawer.componentInstance.profile = this.profile;
      this.fffChangeAccountDrawer.result
        .then(result => {
          if (result && result.updated) {
            this.setResult.emit(true);
            this.reloadAccount.emit();
          } else {
            this.setResult.emit(false);
          }
          this.isUpdating.emit(false);
          this.fffChangeAccountDrawer = null;
        })
        .catch(() => {
          this.setResult.emit(false);
          this.isUpdating.emit(false);
          this.fffChangeAccountDrawer = null;
        });
    }
  }

  changeSplitBiller(mode: boolean, userUid: string) {
    this.isUpdating.emit(true);
    this.loading = true;
    this.subscription.add(
      this.communicationService
        .changeSplitBiller(userUid, mode)
        .pipe(take(1))
        .subscribe(
          response => {
            if (response) {
              this.updateB2bUnit(response);
              this.billerSelected = mode;
              this.setResult.emit(true);
              this.reloadAccount.emit();
            } else {
              this.setResult.emit(false);
            }
            this.windowRef.document
              .querySelectorAll('.ie-div-position-customer-chat')
              .forEach(el => el.remove());
            this.isUpdating.emit(false);
            this.loading = false;
          },
          () => {
            this.loading = false;
            this.setResult.emit(false);
            this.isUpdating.emit(false);
          }
        )
    );
  }

  private updateB2bUnit(userResponse: any): void {
    this.storeCustomer.dispatch({
      type: SET_CUSTOMER_SPLIT_BILLER,
      payload: userResponse,
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
