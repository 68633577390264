/// <reference types="@types/google.maps" />
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FffInvoicePaymentService } from '@app/fff-enterprise/fff-common-services/fff-invoice-payment.service';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { InvoiceCreditCard } from '@app/models/fff-invoice.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import {
  ElavonApprovedPayment,
  InvoicePaymentEvents,
} from '@app/models/fff-payment.model';
import { FffGoogleMapService } from '@app/shared/services/fff-google-map.service';
import { ToastService } from '@app/shared/services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WindowRef } from '@spartacus/core';
import { omit } from 'lodash';
import { Subscription, fromEvent, interval } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'fff-add-credit-card-dialog',
  templateUrl: './fff-add-credit-card-dialog.component.html',
  styleUrls: ['./fff-add-credit-card-dialog.component.scss'],
})
export class FffAddCreditCardDialogComponent implements OnInit {
  @Input() titleI18nKey: string =
    'fffInvoicePayment.addNewCard.userNewCreditCard';
  @Input() saveCreditCard: boolean = false;
  @Input() isTransactionFlow: boolean = false;
  @ViewChild('elavonIFrame') elavonIFrame: ElementRef | undefined;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  filledIcons = FILLED_ICON_TYPE;
  private sessionToken: string = '';
  private approvedPaymentInfo: ElavonApprovedPayment | null = null;
  sessionKeyRefreshSubscription!: Subscription;
  regions: any[] = [];
  savedCards: InvoiceCreditCard[] = [];
  isDuplicateCard!: boolean;
  isDebitCard!: boolean;
  saveCardForFutureUseOptions = [
    {
      id: true,
      label: 'Yes',
    },
    {
      id: false,
      label: 'No',
    },
  ];
  showBufferIcon!: boolean;
  saving: boolean = false;
  iframeUrl: any = '';
  isIframeLoaded!: boolean;
  isSuggestionsLoaded!: boolean;
  private loadTimeout: any;

  form = this.fb.group({
    billingAddress: this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      line1: ['', [Validators.required]],
      line2: '',
      town: [{ value: '', disabled: true }, [Validators.required]],
      region: this.fb.control<any>({ value: '', disabled: true }, [
        Validators.required,
      ]),
      postalCode: [{ value: '', disabled: true }, [Validators.required]],
    }),
    savedPayment: this.fb.control<boolean | null>(null, [Validators.required]),
  });
  selectedAddress: string = '';
  predictions: google.maps.places.AutocompletePrediction[] = [];
  private eventsSubscription: Subscription | undefined;

  constructor(
    private activeModalService: NgbActiveModal,
    private fffInvoicePaymentService: FffInvoicePaymentService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private windowRef: WindowRef,
    private toastService: ToastService,
    private sanitizer: DomSanitizer,
    private fffGoogleMapService: FffGoogleMapService
  ) {}

  ngOnInit(): void {
    if (this.saveCreditCard) {
      this.form.controls['savedPayment'].clearValidators();
      this.form.patchValue(
        {
          savedPayment: true,
        },
        { emitEvent: true }
      );
    }
    this.fetchSavedCards();
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.loadRegions();
    this.handleLightBoxEvents();
  }

  fetchSavedCards() {
    this.fffInvoicePaymentService.loadSavedCards();
    this.fffInvoicePaymentService.getSavedCards().subscribe(
      cards => {
        this.savedCards = cards || [];
      },
      () => {}
    );
  }

  loadRegions() {
    this.fffInvoicePaymentService.getRegions().subscribe((res: any) => {
      this.regions = res?.regions || [];
      this.cd.markForCheck();
    });
  }

  closeAndReset() {
    this.activeModalService.close({
      reset: true,
    });
  }

  dismiss() {
    this.activeModalService.dismiss();
  }

  navigateToSelectCard() {
    this.activeModalService.close({
      initiatePayment: true,
    });
  }

  handleLightBoxEvents() {
    if (this.windowRef.nativeWindow) {
      this.eventsSubscription = fromEvent(
        this.windowRef.nativeWindow,
        'message'
      )
        .pipe(
          debounceTime(300),
          map((event: any) => event.data),
          distinctUntilChanged((prev, curr) => prev.type === curr.type), // Ignore duplicate events of the same type
          tap((data: any) => {
            this.processEvent(data);
          })
        )
        .subscribe();
    }
  }

  private processEvent(data: any) {
    if (!data || !data.type) {
      return;
    }

    const now = new Date();
    console.log(`${data.type} at : ${now.toString()}`);

    switch (data.type) {
      case InvoicePaymentEvents.ERROR:
        this.isIframeLoaded = false;
        this.resetPaymentInfo();
        break;
      case InvoicePaymentEvents.DECLINED:
        this.resetPaymentInfo();
        break;
      case InvoicePaymentEvents.CANCELLED:
        this.isIframeLoaded = false;
        this.resetPaymentInfo();
        break;
      case InvoicePaymentEvents.APPROVAL:
        this.approvedPaymentInfo = data.response;
        this.handleSuccessResponse();
        break;
      case InvoicePaymentEvents.FRAME_LOADED:
        this.iframeLoaded();
        break;
      default:
        console.log('Unknown event type');
    }
  }

  resetPaymentInfo() {
    this.approvedPaymentInfo = null;
  }

  loadLightBox() {
    const paymentFields = {
      // Note: Replace below token for testing till microservices are ready
      ssl_txn_auth_token: this.sessionToken,
      ssl_first_name: this.form.value.billingAddress?.firstName || '',
      ssl_last_name: this.form.value.billingAddress?.lastName || '',
      ssl_avs_address: this.form.value.billingAddress?.line1 || '',
      ssl_avs_address2: this.form.value.billingAddress?.line2 || '',
      ssl_city: this.form.value.billingAddress?.town || '',
      ssl_state: this.form.value.billingAddress?.region?.isocode || '',
      ssl_avs_zip: this.form.value.billingAddress?.postalCode || '',
    };

    const encryptedToken = btoa(JSON.stringify(paymentFields));
    /**
     * NOTE: Use below url for local development
     * `https://127.0.0.1:4200/payments/invoice?site=biosupply&token=${encryptedToken}`
     */
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${location.origin}/payments/invoice?token=${encryptedToken}`
    );
    return;
  }

  private getSaveAddressPayload() {
    const payload = omit(
      {
        ...this.form.getRawValue(),
      },
      ['savedPayment']
    );

    if (!this.approvedPaymentInfo) {
      return payload;
    }

    const expiryDate = this.approvedPaymentInfo.ssl_exp_date;
    return {
      ...payload,
      cardToken: this.approvedPaymentInfo.ssl_token,
      expiryMonth: expiryDate.slice(0, 2),
      expiryYear: expiryDate.slice(2),
      cardType: {
        code: this.approvedPaymentInfo.ssl_card_short_description,
      },
      ps2000Data: this.approvedPaymentInfo.ssl_ps2000_data,
    };
  }

  showSuccessMessage() {
    this.toastService.showSuccess(
      'fffInvoicePayment.addNewCard.creditCardAddedConfirmation',
      {
        delay: 3000,
      }
    );
  }

  appendCardAndOpenPaymentDialog(config: { oneTimeUse: boolean }) {
    if (!this.approvedPaymentInfo) {
      return;
    }

    if (!this.isTransactionFlow) {
      this.activeModalService.close({
        creditCardAdded: true,
        cardToken: this.approvedPaymentInfo.ssl_token,
      });
      return;
    }

    if (!config.oneTimeUse) {
      this.showSuccessMessage();
    }
    const payload = {
      newCardDetails: this.getSaveAddressPayload(),
    };
    this.fffInvoicePaymentService.setNewCard({
      ...payload.newCardDetails,
      oneTimeUse: config.oneTimeUse,
    });
    this.activeModalService.close({
      creditCardAdded: true,
    });
  }

  handleSuccessResponse() {
    this.isDuplicateCard = false;
    this.isDebitCard = false;
    if (
      this.approvedPaymentInfo &&
      this.approvedPaymentInfo?.surchargeAllowed === 'N'
    ) {
      this.isDebitCard = true;
      this.editCard();
    } else if (this.isCardAlreadySaved(this.approvedPaymentInfo)) {
      this.isDuplicateCard = true;
      this.editCard();
    } else {
      if (this.form.getRawValue().savedPayment) {
        this.saveCard();
      } else {
        this.appendCardAndOpenPaymentDialog({
          oneTimeUse: true,
        });
      }
    }
  }
  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  isCardAlreadySaved(newCard: any): boolean {
    if (!this.savedCards || this.savedCards.length === 0) {
      return false;
    } else {
      for (let savedCard of this.savedCards) {
        if (savedCard && savedCard?.cardToken == newCard?.ssl_token) {
          return true;
        }
      }
    }
    return false;
  }

  initLightBox() {
    this.approvedPaymentInfo = null;
    this.fffInvoicePaymentService
      .getSessionToken(this.form.getRawValue())
      .subscribe((res: any) => {
        this.sessionToken = res?.sessionToken || '';
        this.loadLightBox();
      });
  }

  toggleSaving(saving: boolean) {
    this.saving = saving;
    this.cd.markForCheck();
  }

  initSaveCard() {
    this.showBufferIcon = true;
    this.isDuplicateCard = false;
    this.isDebitCard = false;
    this.form.disable();
    this.initLightBox();
    //refreshing the session token after 15 minutes
    this.sessionKeyRefreshSubscription = interval(15 * 60 * 1000)
      .pipe(switchMap(async () => this.initLightBox()))
      .subscribe();
    this.cd.markForCheck();
  }

  editCard() {
    this.showBufferIcon = false;
    this.iframeUrl = null;
    this.isIframeLoaded = false;
    this.form.enable();
    this.form.get('billingAddress.town')?.disable();
    this.form.get('billingAddress.region')?.disable();
    this.form.get('billingAddress.postalCode')?.disable();
    this.cd.markForCheck();
  }

  saveCard() {
    this.toggleSaving(true);
    const payload = this.getSaveAddressPayload();
    this.fffInvoicePaymentService.savePaymentDetails(payload).subscribe(
      () => {
        this.toggleSaving(false);
        this.appendCardAndOpenPaymentDialog({
          oneTimeUse: false,
        });
      },
      () => {
        this.toggleSaving(false);
      }
    );
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const address = inputElement.value.trim();
    if (address.trim() !== '') {
      this.fffGoogleMapService
        .getPlacePredictions(address)
        .subscribe(predictions => {
          this.predictions = predictions;
        });
    } else {
      this.predictions = [];
    }
    this.isSuggestionsLoaded = true;
  }

  selectPlace(prediction: google.maps.places.AutocompletePrediction) {
    this.predictions = [];
    this.isSuggestionsLoaded = false;
    this.fffGoogleMapService
      .getPlaceDetails(prediction.place_id)
      .subscribe(place => {
        let address1 = '';
        let postcode = '';

        for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
          const componentType = component.types[0];

          switch (componentType) {
            case 'street_number': {
              address1 = `${component.long_name} ${address1}`;
              break;
            }

            case 'route': {
              address1 += component.long_name;
              break;
            }

            case 'postal_code': {
              postcode = `${component.long_name}${postcode}`;
              break;
            }

            case 'locality': {
              this.form
                .get('billingAddress.town')
                ?.setValue(component.long_name);
              break;
            }
            case 'administrative_area_level_1': {
              if (this.regions && this.regions.length > 0) {
                const selectedRegion = this.regions.filter(
                  (region: any) => region.isocodeShort == component.short_name
                );
                if (selectedRegion && selectedRegion.length > 0) {
                  this.form
                    .get('billingAddress.region')
                    ?.setValue(selectedRegion[0]);
                }
              }
              break;
            }
          }
        }
        this.form.get('billingAddress.line1')?.setValue(address1);
        this.form.get('billingAddress.postalCode')?.setValue(postcode);
      });
  }

  clearAddress() {
    this.form.get('billingAddress.line1')?.setValue('');
    this.form.get('billingAddress.line2')?.setValue('');
    this.form.get('billingAddress.town')?.setValue('');
    this.form.get('billingAddress.region')?.setValue('');
    this.form.get('billingAddress.postalCode')?.setValue('');
    this.predictions = [];
  }

  iframeLoaded() {
    this.isIframeLoaded = true;
    setTimeout(() => {
      this.showBufferIcon = false;
    }, 2000);
  }
}
