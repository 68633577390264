<div *ngIf="successfulInvoices && successfulInvoices.length > 0" class="mb-4">
  <h6 class="fff-invoice-payment-content-block__title mt-0">
    {{ 'fffInvoicePayment.successfulTransactions' | cxTranslate }}
  </h6>
  <table class="invoice-confirmation-entries-table w-100">
    <thead>
      <tr class="rowForPrint">
        <th class="pl-4 w-20">
          {{ 'fffInvoiceHistory.transactionNumber' | cxTranslate }}
        </th>
        <th class="w-10">
          {{ 'fffInvoiceHistory.status' | cxTranslate }}
        </th>
        <th class="w-15">
          {{ 'fffInvoiceHistory.invoices' | cxTranslate }}
        </th>
        <th class="w-25">
          {{ 'fffInvoiceHistory.manufacturer' | cxTranslate }}
        </th>
        <th class="w-15">
          {{ 'fffInvoiceHistory.surchargeAmount' | cxTranslate }}
        </th>
        <th class="w-15">
          {{ 'fffInvoiceHistory.transactionAmount' | cxTranslate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let data of successfulInvoices">
        <tr class="align-top invoice-border-bottom rowForPrint">
          <td class="pl-4 w-20">
            {{ data.transactionId | fffTrimTransactionId }}
          </td>
          <td class="w-10">
            <cx-icon
              class="invoiceSuccessIcon"
              [type]="outlinedIconTypes.ICON_CHECK"
            ></cx-icon>
            <div class="payment-status">
              {{ 'fffInvoicePayment.approved' | cxTranslate }}
            </div>
          </td>
          <td class="w-15">{{ data.invoiceNumber }}</td>
          <td class="w-25">{{ data.primaryManufacturer }}</td>

          <td class="w-15">
            {{ (data.surchargeAmt ? data.surchargeAmt : '0') | currency }}
          </td>
          <td class="w-15">{{ data.amount | currency }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div
    class="fff-table-footer d-flex"
    *ngIf="totalDiscoutForSuccessInvoices > 0"
  >
    <div class="fff-table-footer-cell ml-auto body-text-demi d-flex">
      <div class="totalAmntForPrint">
        {{ 'fffInvoicePayment.discountSubTotal' | cxTranslate }}
      </div>
      <div class="totalAmntForPrint">
        ({{ totalDiscoutForSuccessInvoices | currency }})
      </div>
    </div>
  </div>
  <div class="fff-table-footer d-flex">
    <div class="fff-table-footer-cell ml-auto body-text-demi d-flex">
      <div class="totalAmntForPrint">
        {{ 'fffInvoicePayment.total' | cxTranslate }}
      </div>
      <div class="totalAmntForPrint">
        {{ totalAmountForSuccess | currency }}
      </div>
    </div>
  </div>
</div>
<div class="mt-3" *ngIf="failureInvoices && failureInvoices.length > 0">
  <h6 class="fff-invoice-payment-content-block__title mt-0">
    {{ 'fffInvoicePayment.failedTransactions' | cxTranslate }}
  </h6>
  <table class="invoice-confirmation-entries-table w-100">
    <thead>
      <tr class="rowForPrint">
        <th class="pl-4 w-20">
          {{ 'fffInvoiceHistory.transactionNumber' | cxTranslate }}
        </th>

        <th class="w-10">
          {{ 'fffInvoiceHistory.status' | cxTranslate }}
        </th>
        <th class="w-15">
          {{ 'fffInvoiceHistory.invoices' | cxTranslate }}
        </th>
        <th class="w-25">
          {{ 'fffInvoiceHistory.manufacturer' | cxTranslate }}
        </th>
        <th class="w-15">
          {{ 'fffInvoiceHistory.surchargeAmount' | cxTranslate }}
        </th>
        <th class="w-15">
          {{ 'fffInvoiceHistory.transactionAmount' | cxTranslate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let data of failureInvoices">
        <tr class="align-top invoice-border-bottom rowForPrint">
          <td class="pl-4 w-20">-</td>
          <td class="w-10">
            <cx-icon
              class="invoiceFailedIcon"
              [type]="outlinedIconTypes.ICON_X"
            ></cx-icon>
            <div class="payment-status">
              {{ 'fffInvoicePayment.declined' | cxTranslate }}
            </div>
          </td>
          <td class="w-15">{{ data.invoiceNumber }}</td>
          <td class="w-25">{{ data.primaryManufacturer }}</td>
          <td class="w-15">
            ${{ data.surchargeAmt ? data.surchargeAmt : '0' }}
          </td>
          <td class="w-15">
            ${{
              calculateFailureTransactionAmnt(
                data.surchargeAmt,
                data.grandTotal
              ) | number : '1.2-2'
            }}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div
    class="fff-table-footer d-flex"
    *ngIf="totalDiscountForFailureInvoices > 0"
  >
    <div class="fff-table-footer-cell ml-auto body-text-demi d-flex">
      <div class="totalAmntForPrint">
        {{ 'fffInvoicePayment.discountSubTotal' | cxTranslate }}
      </div>
      <div class="totalAmntForPrint">
        ({{ totalDiscountForFailureInvoices | currency }})
      </div>
    </div>
  </div>
  <div class="fff-table-footer d-flex">
    <div class="fff-table-footer-cell ml-auto body-text-demi d-flex">
      <div class="totalAmntForPrint">
        {{ 'fffInvoicePayment.total' | cxTranslate }}
      </div>
      <div class="totalAmntForPrint">
        {{ totalAmountForFailure | currency }}
      </div>
    </div>
  </div>
</div>
