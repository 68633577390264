import { Component, Input, OnInit } from '@angular/core';
import { STOCK_STATUS } from '@app/fff-config/content/constants';
import { FFFOrderEntry } from '@app/models/fff-cart-data.model';
import { FFFCommonFunctions } from '@app/shared/fff-common-functions';
import { FffGtmEventService } from '@app/spartacus/features/tracking/gmt/event';
import { FffProfile } from '@model/fff-profile.model';
import { Store } from '@ngrx/store';
import {
  CartItemComponent,
  CartItemContextSource,
} from '@spartacus/cart/base/components';
import { ActiveCartFacade, CartItemContext } from '@spartacus/cart/base/root';
import { MediaContainer } from '@spartacus/storefront';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { UPDATED_CART, UpdatedCartState } from 'src/app/reducers';
import { getProductImage } from 'src/assets/utils/fff-product-utils';

@Component({
  selector: 'fff-cart-item',
  templateUrl: './fff-cart-item.component.html',
  providers: [
    CartItemContextSource,
    { provide: CartItemContext, useExisting: CartItemContextSource },
  ],
})
export class FffCartItemComponent extends CartItemComponent implements OnInit {
  @Input() profile: FffProfile | undefined | null;
  @Input() priceData: any;
  @Input() allocationsForCart: any;
  @Input()
  isUnavailableItem!: boolean;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  imageObject: any;
  existWarning: boolean = false;
  STOCK_STATUS = STOCK_STATUS;
  itemDetails!: any;

  deleted: boolean = false;
  stock: any;
  stockAndAlloc: any;
  stockData$: Observable<any> = new BehaviorSubject<any>({});
  isOfVaccineTypeI = FFFCommonFunctions.isOfVaccineType;

  constructor(
    cartItemContextSource: CartItemContextSource,
    private fffGtmEventService: FffGtmEventService,
    private updatedCartStore: Store<UpdatedCartState>,
    private activeCartService: ActiveCartFacade
  ) {
    super(cartItemContextSource);
    this.existWarning = this.showWarning();
  }

  ngOnInit() {
    this.itemDetails = JSON.parse(JSON.stringify(this.item));
    this.stockData$ = of(
      this.allocationsForCart.find(
        (allocation: any) => allocation.productCode === this.item.product?.code
      )
    );
  }

  getImagePrimary(item: any) {
    return item.product.images?.PRIMARY;
  }

  getStockStatus(item: any) {
    return item.sapStockLevelStatus;
  }

  getVariant(item: any) {
    return item.product.baseOptions[0]?.selected?.variantOptionQualifiers;
  }

  getHideStockIndicator(item: any) {
    return item.product.hideStockIndicator;
  }

  getReadOnly(item: any, options: any, readonly: any) {
    return !item.updateable || readonly || options.isSaveForLater;
  }

  getData(imagesProduct: any) {
    return getProductImage(this.item.product?.images) as MediaContainer;
  }

  showWarning() {
    // TODO: add logic to determine if there should be warning message or not.
    return false;
  }

  remove(e: FFFOrderEntry) {
    this.deleted = true;

    this.activeCartService
      .getEntries()
      .pipe(take(1))
      .subscribe(
        entries => {
          if (entries) {
            const entriesFound = entries.filter(
              (entry: any) =>
                entry.accountType === e.accountType &&
                entry.product.code === e.product?.code &&
                entry.backOrder === e.backOrder &&
                entry.warehouse === e.warehouse
            );
            if (entriesFound.length > 0) {
              this.activeCartService.removeEntry(entriesFound[0]!);
              this.updatedCartStore.dispatch({
                type: UPDATED_CART,
                payload: {},
              });
              this.fffGtmEventService.removeEntrySuccessEvent(entriesFound[0]);
            }
          }
        },
        () => {}
      );
  }

  getStockInfo(stockData: any): any {
    return stockData?.result?.results[0];
  }
}
