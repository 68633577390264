import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FffInteractiveAllocationsEntry } from '@app/models/fff-allocations.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FFFCustomValidators } from '@app/shared/validators/fff-custom-validators';
import { FffUpdateInteractiveAllocationsService } from '../fff-update-interactive-allocations.service';

@Component({
  selector: 'fff-update-interactive-allocations-body',
  templateUrl: './fff-update-interactive-allocations-body.component.html',
})
export class FffUpdateInteractiveAllocationsBodyComponent implements OnInit {
  activeIdsHistory: string[] = [];
  outlinedIconTypes = OUTLINED_ICON_TYPE;

  updateAllocationsGroup: UntypedFormGroup | undefined;
  interactiveAllocationsList: FffInteractiveAllocationsEntry[] = [];

  @Input() data: any = {};

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private fffUpdateInteractiveAllocationsService: FffUpdateInteractiveAllocationsService
  ) {}

  ngOnInit() {
    if (this.data) {
      this.getAllDataList(this.data?.interactiveAllocationsList);

      this.createFormData(this.interactiveAllocationsList);

      this.updateAllocationsGroup
        ?.get('searchFilter')
        ?.get('accountFilter')
        ?.valueChanges.subscribe((newValue: string) => {
          let initialDataList: FffInteractiveAllocationsEntry[] =
            this.data?.interactiveAllocationsList;
          if (newValue) {
            const filterList = initialDataList.filter(
              item =>
                item.account.toLowerCase().includes(newValue.toLowerCase()) ||
                item.accountName.toLowerCase().includes(newValue.toLowerCase())
            );
            this.getAllDataList(filterList);
          } else {
            this.getAllDataList(initialDataList);
          }
        });

      this.updateAllocationsGroup?.valueChanges.subscribe(value => {
        this.fffUpdateInteractiveAllocationsService.updateAllocationsGroupForm$.next(
          this.updateAllocationsGroup
        );
        let total = 0;
        let hasItems = false;
        for (const key in value) {
          const element = value[key];
          if (element.deltaQty) {
            hasItems = true;
            total += parseInt(element.deltaQty);
          }
        }
        this.fffUpdateInteractiveAllocationsService.amountReallocated$.next(
          hasItems ? total : undefined
        );
        this.fffUpdateInteractiveAllocationsService.updateAllocationsGroup$.next(
          value
        );
      });
    }
  }

  private getAllDataList(
    interactiveAllocationsList: FffInteractiveAllocationsEntry[]
  ): void {
    this.interactiveAllocationsList = interactiveAllocationsList;
    this.interactiveAllocationsList.forEach((item, index) => {
      this.activeIdsHistory.push(`oa-panel-${index}`);
      item.expand = true;
    });
  }

  private createFormData(
    interactiveAllocationsList: FffInteractiveAllocationsEntry[]
  ): void {
    const group: any = {
      searchFilter: this.formBuilder.group({
        accountFilter: [''],
      }),
    };
    for (let i = 0; i < interactiveAllocationsList.length; i++) {
      const key = interactiveAllocationsList[i].account!;
      group[key] = this.formBuilder.group({
        currentAllocation: [
          { value: interactiveAllocationsList[i].remainingQty, disabled: true },
        ],
        deltaQty: [],
        productCode: [interactiveAllocationsList[i].productCode],
        account: [interactiveAllocationsList[i].account],
        allocationLevel: [interactiveAllocationsList[i].allocLevel],
      });

      group[key]
        .get('deltaQty')
        .setValidators([FFFCustomValidators.quantityValidator]);
    }

    this.updateAllocationsGroup = new UntypedFormGroup(group);
  }

  onKeydown(e: any): void {
    if ([69, 106, 107, 110, 111, 187, 188, 190, 229].includes(e.keyCode)) {
      e.preventDefault();
    }
  }

  changeValue(event: any, indexKey: string): void {
    if (event?.target?.value) {
      const currentAllocation = this.updateAllocationsGroup
        ?.get(indexKey)
        ?.get('currentAllocation')?.value;
      const currentValue = parseInt(event?.target?.value);
      if (currentValue < currentAllocation * -1 || currentValue === 0)
        this.updateAllocationsGroup
          ?.get(indexKey)
          ?.get('deltaQty')
          ?.setErrors({ invalidQuantity: true });
    }
  }

  openSectionHistory(id: number, interactiveAllocation: any): void {
    const panelId = `oa-panel-${id}`;
    interactiveAllocation.expand = !interactiveAllocation.expand;

    const index = this.activeIdsHistory.indexOf(panelId);
    if (index != -1) {
      this.activeIdsHistory.splice(index, 1);
    } else {
      this.activeIdsHistory.push(panelId);
    }
  }
}
