import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FffProductService } from '@app/fff-enterprise/fff-common-services/fff-product.service';
import {
  AuthService,
  ImageGroup,
  Product,
  isNotNullable,
} from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  combineLatest,
  of,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'fff-product-images',
  templateUrl: './fff-product-images.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffProductImagesComponent implements OnInit, OnDestroy {
  protected mainMediaContainer = new BehaviorSubject<any>(null);
  product: any;
  isLoggedIn: boolean = false;
  showAsSoldOutIfExcluded: boolean = false;

  product$: Observable<any> = this.currentProductService.getProduct().pipe(
    filter(isNotNullable),
    distinctUntilChanged(),
    switchMap(product => {
      if (!product?.code) {
        return EMPTY;
      }
      return this.fffProductService.getPricesData(product.code).pipe(
        map(pricesData => ({
          ...product,
          pricesData,
        }))
      );
    }),
    tap((p: any) => {
      this.product = p;
      this.showAsSoldOutIfExcluded =
        p?.pricesData?.prices?.some((p: any) => p.showAsSoldOutIfExcluded) ||
        false;
      this.mainMediaContainer.next(p.images?.PRIMARY ? p.images.PRIMARY : {});
    })
  );

  thumbs$: Observable<any[]> = this.product$.pipe(
    map((p: Product) => this.createThumbs(p))
  );

  mainImage$ = combineLatest([this.product$, this.mainMediaContainer]).pipe(
    map(([, container]) => container)
  );

  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  get isProductSoldOut(): boolean {
    return !!(
      (this.product?.soldOut ||
        (this.product?.showAsSoldOutIfExcluded &&
          this.showAsSoldOutIfExcluded)) &&
      this.isLoggedIn
    );
  }

  constructor(
    protected currentProductService: CurrentProductService,
    private authService: AuthService,
    private fffProductService: FffProductService
  ) {}

  ngOnInit(): void {
    this.authService
      .isUserLoggedIn()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  openImage(item: any): void {
    this.mainMediaContainer.next(item);
  }

  isActive(thumbnail: ImageGroup): Observable<boolean> {
    return this.mainMediaContainer.pipe(
      filter(Boolean),
      map((container: any) => {
        return (
          container.zoom &&
          container.zoom.url &&
          thumbnail.zoom &&
          thumbnail.zoom.url &&
          container.zoom.url === thumbnail.zoom.url
        );
      })
    );
  }

  private createThumbs(product: Product): Observable<any>[] {
    if (
      !product.images ||
      !product.images.GALLERY ||
      (Array.isArray(product.images.GALLERY) &&
        product.images.GALLERY.length < 2)
    ) {
      return [];
    }
    return (<any[]>product.images.GALLERY).map(c => of({ container: c }));
  }
}
