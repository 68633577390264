<div class="text-center mb-3">
  <h4 class="modal-title confirmation-title">
    {{ 'fffInvoicePayment.confirmationView.title' | cxTranslate }}
  </h4>
  <div>{{ 'fffInvoicePayment.confirmationView.summary' | cxTranslate }}</div>
</div>

<div class="row mb-5">
  <div class="col-5">
    <div class="">
      <h6 class="fff-invoice-payment-content-block__title">
        {{ 'fffInvoicePayment.confirmationView.confirmation' | cxTranslate }}
      </h6>
    </div>
    <div>
      {{
        'fffInvoicePayment.confirmationView.paymentDate'
          | cxTranslate : { paymentDate: (paymentDate | date : 'MM/dd/yyyy') }
      }}
    </div>
  </div>
  <div class="col">
    <cx-icon
      class="icon-confirmation"
      [type]="outlinedIconTypes.ICON_CHECK"
    ></cx-icon>
  </div>
  <div class="notPrintable col-5 text-right">
    <a
      class="p-0 ml-auto text-primary d-inline-flex"
      (click)="printConfirmation()"
    >
      <cx-icon
        [type]="filledIconTypes.ICON_PRINTER_FILL"
        class="mr-1 icon-sm"
      ></cx-icon>
      {{ 'fffInvoicePayment.confirmationView.printConfirmation' | cxTranslate }}
    </a>
  </div>
</div>
