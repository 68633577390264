<ng-container>
  <ng-container *ngIf="cart$ | async as cart">
    <ng-container *ngIf="groupedEntries$ | async as groupedEntries">
      <!-- <h2 class="cx-total d-none">
        {{ 'orderCost.yourShoppingCart' | cxTranslate: { items: entries.length } }}
      </h2> -->

      <ng-container *ngFor="let group of entryGroups">
        <ng-container
          [ngTemplateOutlet]="groupedEntriesTemplate"
          [ngTemplateOutletContext]="{
          cart,
          entries: groupedEntries[group],
          group
        }"
        ></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loaderTemplate>
  <fff-cart-loader [isCartItem]="true"></fff-cart-loader>
</ng-template>

<ng-template
  #groupedEntriesTemplate
  let-cart="cart"
  let-entries="entries"
  let-group="group"
>
  <div
    *ngIf="cart && cart.totalItems && cart.totalItems > 0; else loaderTemplate"
    class="cart-details-wrapper"
  >
    <!-- <ng-container *cxFeatureLevel="'4.2'">
      <cx-cart-validation-warnings></cx-cart-validation-warnings>
    </ng-container> -->
    <fff-prebook-checkout-recommendation
      [stageId]="group"
    ></fff-prebook-checkout-recommendation>
    <fff-cart-item-list
      *ngIf="entries && entries.length > 0"
      [items]="entries"
      [cartIsLoading]="!(cartLoaded$ | async)"
      [promotionLocation]="promotionLocation"
      [prices]="prices"
      [stock]="stock"
      [isPrebook]="true"
    ></fff-cart-item-list>
  </div>
</ng-template>
