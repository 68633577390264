<!-- Loader for PLP page -->
<ng-container *ngIf="isPLPPage; else PDPPage">
  <div class="loader-animation button"></div>
  <div class="product-badge loader-animation link" *ngIf="!product">
    <div></div>
  </div>
  <div class="product-badge link" *ngIf="product">
    <h3>
      <a class="name" [routerLink]="product?.url">{{ product?.name }}</a>
    </h3>
  </div>

  <div class="row pl-md-3">
    <div class="col-12 p-1 p-md-0 col-md-5">
      <div class="row pl-3 mr-0 item-info-container">
        <div class="row w-100 img-container">
          <div class="image col-6 col-lg-4" *ngIf="product">
            <cx-media
              [container]="imageObject"
              [alt]="product?.summary!"
              format="product"
            ></cx-media>
          </div>
          <div
            class="image col-6 col-lg-4 loader-animation"
            *ngIf="!product"
          ></div>
          <div class="col-lg-8 col-6" *ngIf="!product">
            <div class="product-detail loader-animation"></div>
            <div class="product-detail loader-animation"></div>
            <div class="product-detail loader-animation"></div>
          </div>
          <div class="col-lg-8 col-6" *ngIf="product">
            <div class="product-detail">{{ product.manufacturer }}</div>
            <div class="product-detail">
              {{ 'fffProduct.lblNdc' | cxTranslate }} {{ product.ndc }}
            </div>
            <div class="product-detail">
              {{ 'fffProduct.lblSku' | cxTranslate }} {{ product.code }}
            </div>
          </div>
        </div>
        <div class="row pl-2 loader-animation button"></div>
      </div>
    </div>
    <div class="d-flex price-row pt-2 pb-2 pl-3 pr-3 loader-animation"></div>
    <div class="col-12 col-md-3 p-3 p-md-0 product-actions">
      <div class="btn-md product-detail loader-animation"></div>
      <div class="btn-md w-100 loader-animation button full"></div>
      <div class="btn-md w-100 loader-animation button full"></div>
    </div>
  </div>
  <div class="row mb-4">
    <div
      class="col-12 pr-md-0 pl-md-2 mt-md-3 loader-animation"
      *ngIf="!product"
    ></div>
  </div>
</ng-container>

<!-- Loader for PDP page -->
<ng-template #PDPPage>
  <div class="col-12 d-flex flex-wrap mt-4 p-0">
    <div class="col-6 pl-0 ml-3 button loader-animation"></div>
  </div>
  <div class="d-flex price-row pt-2 pb-2 pl-3 loader-animation full"></div>
  <div class="d-flex pt-2 pb-2 pl-3 pr-3 product-detail loader-animation"></div>
  <div class="col-12 col-md-3 p-3 p-md-0 product-actions">
    <div class="btn-md w-100 loader-animation button full"></div>
    <div class="btn-md w-100 loader-animation button full"></div>
  </div>
</ng-template>
