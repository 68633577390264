import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { STOCK_STATUS } from '@app/fff-config/content/constants';
import { PRODUCT_STATUS, ProductStockState } from '@app/reducers';
import { BASE_URL_KEYS } from '@config/content/constants';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'fff-product-stock-status',
  templateUrl: './fff-product-stock-status.component.html',
})
export class FffProductStockStatusComponent {
  @Input() pCode!: string | undefined;
  @Input() product: any;
  @Input() stock: any;

  totalStock: number | undefined = undefined;
  hasBatchStock: boolean = false;
  rapidCommitIsSuperCat$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  nonStock: Array<string> = [
    'CIS028838',
    'CAR029512',
    'CAR029534',
    'FLR027638',
  ];
  BASE_URL_KEYS = BASE_URL_KEYS;

  constructor(
    private store: Store<ProductStockState>,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.rapidCommitIsSuperCat();
    this.product = { ...this.product };
    this.product.isOnDemand = this.product.isOnDemand ?? false;
    if (this.stock) {
      this.totalStock = this.stock.custToMatInv?.results[0].overallStock;
    }
    this.changeDetectorRef.detectChanges();

    if (this.product && this.product.dropShip) {
      this.dispatchProductStatus(STOCK_STATUS.DROP_SHIP);
    } else if (this.product && this.product.assayIndicator && this.stock) {
      this.assayProduct();
    } else if (this.totalStock) {
      if (this.totalStock > 0) {
        this.dispatchProductStatus(STOCK_STATUS.IN_STOCK);
      } else {
        this.dispatchProductStatus(
          this.product.onDemand
            ? STOCK_STATUS.ON_DEMAND
            : STOCK_STATUS.IN_TRANSIT
        );
      }
    }
  }

  assayProduct(): void {
    this.stock.custToMatInv.results.forEach((batch: any) => {
      if (batch?.batchStock > 0) {
        this.hasBatchStock = true;
      }
    });
    if (this.hasBatchStock) {
      this.dispatchProductStatus(STOCK_STATUS.IN_STOCK);
    }
  }

  private dispatchProductStatus(status: string): void {
    this.store.dispatch({
      type: PRODUCT_STATUS,
      payload: { code: this.product.code, status: status },
    });
  }

  rapidCommitIsSuperCat(): void {
    this.product.categories?.forEach((category: any) => {
      if (category.code === 'RAPIDCOMMIT') {
        this.rapidCommitIsSuperCat$.next(true);
      }
    });
  }
}
