import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FffCheckoutService } from '@app/fff-enterprise/fff-common-services/fff-checkout.service';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { FFFCustomValidators } from '@app/shared/validators/fff-custom-validators';
import { BASE_URL_KEYS } from '@config/content/constants';
import { FffOrderFacade } from '@enterprise/fff-common-services/fff-order-facade';
import { User } from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable } from 'rxjs';

@Component({
  selector: 'fff-checkout-billing-info',
  templateUrl: './fff-checkout-billing-info.component.html',
})
export class FffCheckoutBillingInfoComponent implements OnInit {
  @Input() poRules: any = {};
  @Input() invoiceApprover = false;
  user$ = this.userProfile.get();
  contactMethods$ = this.fffOrderFacade.getContactMethods();
  isDuplicate$: Observable<any> | undefined;
  filledIconTypes = FILLED_ICON_TYPE;

  maxLength: any;
  startValues: any;
  regex: any;
  regexNoSpecial = new RegExp(`^[A-Za-z0-9- ]+$`);
  checkoutLoginForm: UntypedFormGroup | undefined;
  obligatoryPo = true;
  BASE_URL_KEYS = BASE_URL_KEYS;
  static noPoValidation = "PO VALIDATION DOESN'T EXIST";
  fffEamil: string = '@fffenterprises.com';

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected comunicationService: FffCommunicationService,
    public cdRef: ChangeDetectorRef,
    private fffCheckoutService: FffCheckoutService,
    protected userProfile: UserProfileFacade,
    private fffOrderFacade: FffOrderFacade
  ) {}

  ngOnInit(): void {
    this.maxLength =
      this.poRules && this.poRules.maxCharacters
        ? this.poRules.maxCharacters
        : 20;
    this.startValues =
      this.poRules && this.poRules.startsWith
        ? this.poRules.startsWith.split(',')
        : [];
    this.regex = new RegExp(`^(${this.startValues.join('|')})`);

    let validators = [
      Validators.maxLength(this.maxLength),
      Validators.pattern(this.regexNoSpecial),
    ];
    validators.push(Validators.pattern(this.regex), Validators.required);

    this.checkoutLoginForm = this.formBuilder.group({
      additionalEmailConf: [''],
      poNumber: ['', validators],
      referenceName: [''],
      contactMethod: [''],
    });

    this.fffCheckoutService.checkoutForm.next(this.checkoutLoginForm);

    this.fffCheckoutService.hasErrors.subscribe(hasErrors => {
      if (hasErrors) {
        this.checkoutLoginForm?.markAsTouched();
      }
      this.cdRef.detectChanges();
    });

    this.checkoutLoginForm?.valueChanges.subscribe(() => {
      this.fffCheckoutService.checkoutForm.next(this.checkoutLoginForm);
    });
  }

  checkPoNumber(user: User): void {
    const poNumber = this.checkoutLoginForm?.get('poNumber')?.value;
    if (poNumber) {
      this.isDuplicate$ = this.comunicationService.getPoNumber(user, poNumber);
    }
  }

  checkEmail(): void {
    const emailInputValue = this.checkoutLoginForm?.get(
      'additionalEmailConf'
    )?.value;

    if (emailInputValue && this.checkoutLoginForm) {
      if (
        emailInputValue[0].length == 1 &&
        !this.regexNoSpecial.test(emailInputValue[0])
      ) {
        this.checkoutLoginForm?.get('additionalEmailConf')?.setValue('');
        return;
      }

      const emails = emailInputValue.toString().split(',');
      emails.some((email: any) => {
        const validator = CustomFormValidators.emailValidator({
          value: email.trim(),
        } as AbstractControl);
        if (validator) {
          this.checkoutLoginForm
            ?.get('additionalEmailConf')
            ?.setErrors({ fffInvalidEmail: true });
          return true;
        }
        return false;
      });
      const emailsExceedsCountValidator =
        FFFCustomValidators.emailsExceedsCountValidator({
          value: emails,
        } as AbstractControl);
      if (emailsExceedsCountValidator) {
        this.checkoutLoginForm
          ?.get('additionalEmailConf')
          ?.setErrors(emailsExceedsCountValidator);
      }
    }
  }
  checkInvoiceApproverEmail(): void {
    const emailInputValue = this.checkoutLoginForm?.get('referenceName')?.value;
    if (emailInputValue && this.checkoutLoginForm) {
      const emails = emailInputValue.replace(' ', '').split(',');
      emails.some((email: any) => {
        const validator = CustomFormValidators.emailValidator({
          value: email,
        } as AbstractControl);
        if (validator) {
          this.checkoutLoginForm?.get('referenceName')?.setErrors(validator);
          return true;
        }
        return false;
      });
    }
  }

  formatStartingPOs(str: string) {
    return str
      .split(',')
      .join(', ')
      .replace(/(,\s)(?!.*,\s)/, ' or ');
  }
}
