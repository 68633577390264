<div class="d-flex justify-content-center" *ngIf="!price">
  <cx-icon class="cx-icon" [type]="outlinedIconTypes.ICON_CLOCK"></cx-icon
  >&nbsp;{{ 'fffCart.loading' | cxTranslate }}
</div>

<ng-container *ngIf="!isLoading; else loading">
  <ng-container *ngIf="price">
    <ng-container *ngFor="let p of price; let i = index">
      <ng-container *ngIf="isPriceAllowed(p)">
        <div
          class="d-flex pt-2 pb-2 px-3 mx-3 border-bottom"
          [ngClass]="{
            'price-row': i === 0
          }"
          *ngIf="
            isValidPriceData(p, p.price.basicUOM) || isValidAltPriceData(p)
          "
        >
          <div class="w-100">
            <div>
              <ng-container *ngIf="p.accountType">
                {{ getB2BLabel(p.accountType) | cxTranslate }}
              </ng-container>
            </div>
            <div class="d-flex flex-wrap">
              <p
                *ngIf="isValidPriceData(p, p.price.basicUOM)"
                class="body-text-xl mb-0 mr-3 vl-price"
              >
                <b>
                  ${{
                    updateTierPricing(p)?.scaleDiscAmt ||
                      p.price.priceMap[p.price.basicUOM].value
                      | number : '1.2-2'
                  }}/{{ p.price.basicUOM }}</b
                >
              </p>
              <p
                *ngIf="isValidAltPriceData(p)"
                class="body-text-xl mb-0 eu-price"
              >
                <ng-container
                  *ngIf="
                    !isOfVaccineTypeI(product);
                    else vaccineAltPriceTemplate2
                  "
                >
                  <b>
                    ${{
                      getAltPriceI(p.price.priceMap, product?.altUnit)?.value
                        | number : '1.2-2'
                    }}/
                    {{
                      getAltPriceI(p.price.priceMap, product?.altUnit)?.altUOM
                    }}</b
                  >
                </ng-container>
                <ng-template #vaccineAltPriceTemplate2>
                  <b>
                    ${{
                      getVaccineAltPriceI(p)?.altDiscPrice | number : '1.2-2'
                    }}/ {{ getVaccineAltPriceI(p)?.altUOM }}</b
                  >
                </ng-template>
              </p>
              <p
                *ngIf="
                  mduExists(p) &&
                  isBasePriceAvailable(p) &&
                  !isValidAltPriceData(p)
                "
                class="body-text-xl mb-0 eu-price"
              >
                <ng-container
                  *ngIf="
                    !isOfVaccineTypeI(product);
                    else vaccineAltPriceTemplate
                  "
                >
                  <b>
                    ${{ p.price.basicDiscountPrice | number : '1.2-2' }}/
                    {{ p.price.originalBasicUom }}</b
                  >
                </ng-container>
                <ng-template #vaccineAltPriceTemplate>
                  <b>
                    ${{ p.price.basicDiscountPrice | number : '1.2-2' }}/
                    {{ getVaccineAltPriceI(p)?.altUOM }}</b
                  >
                </ng-template>
              </p>
            </div>
            <div
              class="fed-tax body-text-sm"
              *ngIf="
                p.price.priceMap[p.price.basicUOM]?.tax > 0 ||
                getAltPriceI(p.price.priceMap, product?.altUnit)?.tax > 0
              "
            >
              {{ 'fffProduct.lblFedexTax' | cxTranslate }}
            </div>
          </div>

          <div
            class="input-price d-flex align-items-center flex-column justify-content-center"
          >
            <div
              class="qty body-text-sm"
              *ngIf="!(prebookFormEnabled && product && product.notOrderable)"
            >
              {{ 'fffProduct.lblQty' | cxTranslate }}
            </div>
            <div class="input-item position-relative">
              <fff-tooltip
                *ngIf="isInvalidQty(p)"
                [text]="
                  tooltipMessage !== '' && tooltipMessage.includes('qty')
                    ? (tooltipMessage
                      | cxTranslate : { mdu: p.price.mdu | number : '1.0-0' })
                    : (tooltipMessage
                      | cxTranslate
                        : {
                            min: p.price.priceMap[p.price.basicUOM].min,
                            uom: p.price.basicUOM
                          })
                "
                class="warn-tooltip"
              ></fff-tooltip>

              <fff-tooltip
                *ngIf="
                  (differentSeason() ||
                    rsvWithActivePrebookInCart() ||
                    rsvWithPrebookCart()) &&
                  (p.quantity || product.quantity || isSeasonTooltipVisible)
                "
                (closed)="isSeasonTooltipVisible = false"
                text="{{ 'fffProduct.differentFlu' | cxTranslate }}"
                class="warn-tooltip fluseason-tooltip-font-size"
              ></fff-tooltip>

              <fff-tooltip
                *ngIf="p.accountType === allocationMessage"
                text="{{ 'fffProduct.exceedsAlloc' | cxTranslate }}"
                class="warn-tooltip"
              ></fff-tooltip>

              <fff-tooltip
                *ngIf="p.accountType === batchQtyValidation"
                text="{{
                  'fffProduct.exceedMaximumQtyAvailable'
                    | cxTranslate
                      : { max: batchStockAvailable | number : '1.0-0' }
                }}"
                class="warn-tooltip"
              ></fff-tooltip>

              <fff-tooltip
                *ngIf="i === 0 && isCdsLicenseBlock()"
                text="{{ 'fffProduct.cdsLicenseInvalid' | cxTranslate }}"
                class="warn-tooltip"
              ></fff-tooltip>
              <fff-tooltip
                *ngIf="i === 0 && isSomQuestionnaireInvalid()"
                text="{{ 'fffProduct.somQuestionnaireExpired' | cxTranslate }}"
                class="warn-tooltip"
              ></fff-tooltip>
              <ng-container *ngIf="!prebookFormEnabled">
                <input
                  class="text-center"
                  [(ngModel)]="p.quantity"
                  (ngModelChange)="
                    entryQuantityChange($event, p);
                    checkAllocation(p);
                    checkBatchAvailable(p);
                    updateTierPricing(p)
                  "
                  maxlength="4"
                  type="text"
                  id="qtyItem"
                  [disabled]="
                    !allocation ||
                    !!product.notOrderable ||
                    !product.purchasable ||
                    !p.price.priceMap[p.price.basicUOM].value ||
                    p.price.priceMap[p.price.basicUOM].value <= 0 ||
                    isControlledSubstance() ||
                    isAccountBlocked(p) ||
                    isProductSoldOut() ||
                    isBlockQty()
                  "
                  ngbTooltip="{{
                    isInvalidBatchOrStrength(product)
                      ? ('fffProduct.quantityNotAllowed'
                        | cxTranslate
                          : {
                              reason: isStrengthNotSelected
                                ? ('fffProduct.lblStrength' | cxTranslate)
                                : ('fffProduct.lblBatch' | cxTranslate)
                            })
                      : ''
                  }}"
                  triggers="manual"
                  placement="bottom"
                  #tooltip="ngbTooltip"
                  (click)="toggleTooltip(tooltip, $event)"
                />
              </ng-container>
              <ng-container *ngIf="prebookFormEnabled">
                <input
                  *ngIf="
                    product && !product.notOrderable;
                    else notOrderableTemplate
                  "
                  class="text-center"
                  [(ngModel)]="product.quantity"
                  (ngModelChange)="
                    product.quantity > 0 &&
                    product.addToCartNotice &&
                    product.addToCartNotice !== ''
                      ? showAddToCartNotice()
                      : prebookEntryQuantityChange(product.quantity, p)
                  "
                  (mouseout)="showAddToCartNoticeComponent(product.quantity, p)"
                  (blur)="showAddToCartNoticeComponent(product.quantity, p)"
                  tabindex="0"
                  type="text"
                  id="qtyItem"
                  maxlength="4"
                  fffAllowOnlyNumbers
                  [disabled]="
                    !allocation ||
                    !!product.notOrderable ||
                    !product.purchasable ||
                    !p.price.priceMap[p.price.basicUOM].value ||
                    p.price.priceMap[p.price.basicUOM].value <= 0 ||
                    isControlledSubstance() ||
                    isAccountBlocked(p) ||
                    isProductSoldOut() ||
                    isBlockQty()
                  "
                  (keypress)="validatePrebookEntryQuantity($event)"
                  ngbTooltip="{{
                    isInvalidBatchOrStrength(product)
                      ? ('fffProduct.quantityNotAllowed'
                        | cxTranslate
                          : {
                              reason: isStrengthNotSelected
                                ? ('fffProduct.lblStrength' | cxTranslate)
                                : ('fffProduct.lblBatch' | cxTranslate)
                            })
                      : ''
                  }}"
                  triggers="manual"
                  #tooltip="ngbTooltip"
                  (click)="toggleTooltip(tooltip, $event)"
                />
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <fff-content-loader
    containerClassName="height-large"
    bodyClassName="d-flex price-row pt-2 pb-2 pl-3 pr-3"
  >
  </fff-content-loader>
</ng-template>

<ng-template #notOrderableTemplate>
  <button
    class="btn-primary btn-sm learnMoreBtn"
    (click)="showProductNotOrderableComponent()"
  >
    <span>{{ 'fffProduct.learnMore' | cxTranslate }}</span>
  </button>
</ng-template>
