<div *ngIf="currentTime <= orderCutOffTime">
  <div *ngIf="!isOverThreshold()">
    <div class="text-brand-primary-80 body-text-xl mb-1 font-weight-bold">
      {{ 'fffShippingSelector.quantity' | cxTranslate }}: {{ item.quantity }}
      {{ item.uom }}
    </div>

    <div class="d-flex">
      <ng-container *ngTemplateOutlet="uomTemplate"></ng-container>
    </div>

    <div
      class="font-size-body-sm"
      *ngIf="!item.product.dropShip && cart && !cart.rapidCommit"
    >
      <ng-container *ngIf="item.overweightPastCutoff">
        {{ 'fffShippingSelector.overweightPastCutoff' | cxTranslate }}
      </ng-container>
      <!-- <ng-template #notOverweight>
            <ng-container *ngIf="!item.product.onDemand">
              {{'fffShippingSelector.shippingCutOff' | cxTranslate}}
              {{'fffShippingSelector.shipToday' | cxTranslate}}
            </ng-container>
          </ng-template> -->
    </div>

    <fff-shipping-dates
      [cart]="cart"
      [baseShipDate]="baseShipDate"
      [baseArrivalDate]="baseArrivalDate"
      [item]="item"
    ></fff-shipping-dates>
  </div>

  <div *ngIf="isOverThreshold()">
    <div class="text-brand-primary-80 body-text-xl mt-1">
      {{ 'fffShippingSelector.quantity' | cxTranslate }}: {{ item.quantity }}/{{
        item.uom
      }}
      <ng-container *ngIf="!isOfVaccineTypeI(item); else vaccineUomTemplate">
        {{
          item?.unitConversionFactor && item?.altUom
            ? '(1 ' +
              (item.uom | lowercase) +
              ' = ' +
              item.unitConversionFactor +
              ' ' +
              (item.altUom | lowercase) +
              ')'
            : ''
        }}
      </ng-container>
    </div>

    <div class="d-flex">
      <p class="text-brand-primary-80 body-text-xl mb-1">
        <b>{{ item.basePrice?.formattedValue }}/{{ item.uom | lowercase }}</b>
      </p>
      &nbsp; &nbsp;
      <ng-container *ngIf="item?.altDiscPrice && item?.altUom">
        <p class="body-text-xl">
          <b>${{ item.altDiscPrice }}/{{ item?.altUom | lowercase }}</b>
        </p>
      </ng-container>
    </div>

    <div
      *ngFor="
        let pb of splitQuantitiesByShippingWindow(
          item.quantity,
          item.product.productPreBookConfiguration.shippingWindows
        )
      "
    >
      <div class="text-grey-90 body-text-sm mt-1">
        <b
          >{{ 'fffShippingSelector.quantity' | cxTranslate }}:
          {{ pb.quantityCalculated }}/{{ item.uom }}
          {{
            item.product.basicUoM
              ? '(1&nbsp;' +
                (item.uom | lowercase) +
                '&nbsp;=&nbsp;' +
                item.product.basicUoM +
                'eu)'
              : ''
          }}</b
        >
      </div>

      <fff-shipping-dates
        [cart]="cart"
        [baseShipDate]="pb.shippingWindowStartDate"
        [baseArrivalDate]="pb.shippingWindowEndDate"
        [item]="item"
      ></fff-shipping-dates>
    </div>
  </div>

  <div *ngIf="isDeliveryWindowVisible()">
    <a (click)="viewDates(item.entryNumber)" class="btn-link">{{
      'fffShippingSelector.view' | cxTranslate
    }}</a>
  </div>
</div>

<div *ngIf="currentTime > orderCutOffTime">
  <div *ngIf="!isOverThreshold()">
    <div class="text-brand-primary-80 body-text-xl mb-1 font-weight-bold">
      {{ 'fffShippingSelector.quantity' | cxTranslate }}: {{ item.quantity }}
      {{ item.uom }}
    </div>

    <div class="d-flex">
      <ng-container *ngTemplateOutlet="uomTemplate"></ng-container>
    </div>

    <div
      class="font-size-body-sm"
      *ngIf="!item.product.dropShip && cart && !cart.rapidCommit"
    >
      <ng-container *ngIf="item.overweightPastCutoff; else notOverweight">
        {{ 'fffShippingSelector.overweightPastCutoff' | cxTranslate }}
      </ng-container>
      <ng-template #notOverweight>
        <ng-container *ngIf="!item.product.onDemand">
          {{ 'fffShippingSelector.shippingCutOff' | cxTranslate }}
        </ng-container>
      </ng-template>
    </div>

    <fff-shipping-dates
      [cart]="cart"
      [baseShipDate]="baseShipDate"
      [baseArrivalDate]="baseArrivalDate"
      [item]="item"
    ></fff-shipping-dates>
  </div>

  <div *ngIf="isOverThreshold()">
    <div class="text-brand-primary-80 body-text-xl mt-1">
      {{ 'fffShippingSelector.quantity' | cxTranslate }}: {{ item.quantity }}/{{
        item.uom
      }}

      <ng-container *ngTemplateOutlet="uomTemplate"></ng-container>
    </div>

    <div class="d-flex">
      <p class="text-brand-primary-80 body-text-xl mb-1">
        <b>{{ item.basePrice?.formattedValue }}/{{ item.uom | lowercase }}</b>
      </p>
      &nbsp; &nbsp;
      <ng-container *ngIf="item?.altDiscPrice && item?.altUom">
        <p class="body-text-xl">
          <b>${{ item.altDiscPrice }}/{{ item?.altUom | lowercase }}</b>
        </p>
      </ng-container>
    </div>

    <div
      *ngFor="
        let pb of splitQuantitiesByShippingWindow(
          item.quantity,
          item.product.productPreBookConfiguration.shippingWindows
        )
      "
    >
      <div class="text-grey-90 body-text-sm mt-1">
        <b
          >{{ 'fffShippingSelector.quantity' | cxTranslate }}:
          {{ pb.quantityCalculated }}
          {{
            item.product.basicUoM
              ? '(1&nbsp;' +
                (item.uom | lowercase) +
                '&nbsp;=&nbsp;' +
                item.product.basicUoM +
                'eu)'
              : ''
          }}</b
        >
      </div>

      <fff-shipping-dates
        [cart]="cart"
        [baseShipDate]="pb.shippingWindowStartDate"
        [baseArrivalDate]="pb.shippingWindowEndDate"
        [item]="item"
      ></fff-shipping-dates>
    </div>
  </div>

  <div *ngIf="isDeliveryWindowVisible()">
    <a (click)="viewDates(item.entryNumber)" class="btn-link">
      {{ 'fffShippingSelector.view' | cxTranslate }}
    </a>
  </div>
</div>

<div *ngIf="canDisplayShipTodayCheckbox(item)">
  <input
    [(ngModel)]="item.sameDayShipping"
    class="checkbox checkbox-md"
    type="checkbox"
    value=""
    [id]="'flexCheckChecked' + item.entryNumber"
    (ngModelChange)="onSameDayCheckboxCheck($event, item)"
    [disabled]="loadingOptions"
  />
  <label
    [ngClass]="{ 'disabled-label': loadingOptions }"
    class="checkbox-label d-flex align-items-center shipping-option-margin"
    [for]="'flexCheckChecked' + item.entryNumber"
  >
    {{ 'fffShippingSelector.shipTodayBox' | cxTranslate }}
  </label>
</div>

<div *ngIf="canDisplayShippingOptionsDropdown(item)">
  <div
    class="dropdown mt-3 w-100 mb-3"
    [ngClass]="{
      showt: openDropdown,
      removet: !openDropdown,
      'dropdown--loading': loadingOptions
    }"
  >
    <div
      *ngIf="isDeliveryWindowVisible()"
      class="d-flex myc body-text cursor-pointer shipping-dropdown border-4 rounded"
      (click)="toggleDropdown()"
      [attr.id]="'dropdownMenu2' + item.entryNumber"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <b class="dropdown-title ml-2">
        <div class="text-brand-primary-80 mb-0">
          {{ 'fffShippingSelector.expeditedOptions' | cxTranslate }}
        </div>
      </b>
      <div class="text-brand-primary-80 mb-0 d-flex justify-content-center">
        <cx-icon
          class="cursor-pointer"
          [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"
        >
        </cx-icon>
      </div>
    </div>

    <div
      class="dropdown-options mt-n1 p-1 w-100 body-text-sm dropdown-menu"
      [attr.aria-labelledby]="'dropdownMenu2' + item.entryNumber"
    >
      <div class="m-2" *ngIf="loadingOptions">
        <fff-line-loader></fff-line-loader>
      </div>

      <div class="m-2" *ngIf="deliveryOptions.length === 0 && !loadingOptions">
        {{ 'fffShippingSelector.noExpedited' | cxTranslate }}
      </div>

      <div *ngFor="let delivery of deliveryOptions; let i = index" class="p-1">
        <div class="d-flex align-items-center">
          <input
            [checked]="deliveryOptions.length === 1 || check(delivery, item, i)"
            [id]="'radio-' + item.entryNumber + '-sm' + i"
            class="radio radio-sm"
            [name]="'radio-group-sm' + item.entryNumber"
            type="radio"
            (click)="changeCheckStatus(delivery, item, i)"
            [ngClass]="{
              'pe-none': check(delivery, item, i)
            }"
          />
          <label
            [for]="'radio-' + item.entryNumber + '-sm' + i"
            [ngClass]="{
              'pe-none': check(delivery, item, i)
            }"
            class="radio-label d-flex align-items-center w-80"
          >
            {{ delivery.shippingDescShipERP }}
          </label>
          <span>${{ delivery.internalCost | number : '1.2-2' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <fff-drawer #Drawer> </fff-drawer> -->

<ng-template #vaccineUomTemplate> ({{ item.quantity * 10 }} DS) </ng-template>

<ng-template #uomTemplate>
  <div>
    <ng-container *ngIf="!isOfVaccineTypeI(item); else vaccineUomTemplate">
      {{
        item?.unitConversionFactor && item?.altUom
          ? '1 ' +
            (item.uom | lowercase) +
            ' = ' +
            item.unitConversionFactor +
            ' ' +
            (item.altUom | lowercase)
          : ''
      }}
    </ng-container>

    <ng-container *ngIf="item?.altUnitConversion">
      <div class="volume body-text-sm d-inline-block">
        <span
          *ngIf="
            item?.unitConversionFactor &&
            item?.altUom &&
            !isOfVaccineTypeI(item)
          "
        >
          ,&nbsp;
        </span>
        {{ item?.altUnitConversion }}
      </div>
    </ng-container>
  </div>
</ng-template>
