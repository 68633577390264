import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FFFActiveCartService } from '@app/fff-enterprise/fff-custom-cart/fff-active-cart-service';
import { MultiCartService } from '@spartacus/cart/base/core';
import { OrderEntry } from '@spartacus/cart/base/root';
import { RoutingService } from '@spartacus/core';
// import { BaseSiteService, MultiCartService, OrderEntry, RoutingService } from '@spartacus/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { CUSTOM_ICON_TYPE } from 'src/app/models/fff-custom-icons.model';

@Component({
  selector: 'fff-cart-complete-page',
  templateUrl: './fff-cart-complete-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffCartCompletePageComponent implements OnInit, OnDestroy {
  customIconTypes = CUSTOM_ICON_TYPE;

  private unsubscribe$: Subject<void> = new Subject<void>();
  activeCart$ = this.activeCartService.getActive();
  entries$ = this.activeCartService
    .getEntries()
    .pipe(takeUntil(this.unsubscribe$));
  isStableCart$ = this.activeCartService
    .isStable()
    .pipe(takeUntil(this.unsubscribe$));

  cartData$: Observable<any> = this.getCartData();

  constructor(
    private fffCommunicationService: FffCommunicationService,
    private activeCartService: FFFActiveCartService,
    private multiCartService: MultiCartService,
    protected routingService: RoutingService
  ) {}

  ngOnInit(): void {
    combineLatest([this.isStableCart$, this.activeCart$])
      .pipe(
        debounceTime(300),
        filter(([stable, cart]) => stable && !!cart?.code),
        map(([, cart]) => cart),
        take(1),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((cart: any) => {
        this.activeCartService
          .getUpdatedCartATP(cart.code!)
          .pipe(take(1), takeUntil(this.unsubscribe$))
          .subscribe(cart =>
            this.multiCartService.loadCart({
              cartId: cart.code,
              userId: 'current',
              extraData: { active: true },
            })
          );
      });
  }
  getEligibleItemsCount(items: any) {
    return items.filter((item: any) => item.product.isExcluded).length;
  }
  protected getCartData(): Observable<any> {
    const priceData$ = this.activeCart$
      .pipe(
        switchMap(cart => {
          if (cart && cart.code) {
            return this.fffCommunicationService.getCartPrices(cart.code);
          } else {
            return of({});
          }
        })
      )
      .pipe(take(1));

    const stockData$ = this.entries$
      .pipe(
        switchMap((entries: OrderEntry[]) => {
          if (entries.length > 0) {
            const productCodes = [
              ...new Set(entries.map(entry => entry.product?.code)),
            ];
            const observables = productCodes.map(p =>
              this.fffCommunicationService
                .getStockAndAlloc(p)
                .pipe(
                  map(resultData => {
                    return { productCode: p, result: resultData };
                  })
                )
                .pipe(take(1))
                .pipe(takeUntil(this.unsubscribe$))
            );
            return combineLatest(observables);
          } else {
            return of([]);
          }
        })
      )
      .pipe(take(1));

    return combineLatest([priceData$, stockData$]).pipe(
      map(([priceData, stockData]) => {
        return {
          priceData: priceData,
          stockData: stockData,
        };
      })
    );
  }

  goToHomePage(): void {
    this.routingService.go('/');
  }

  ngOnDestroy(): void {
    if (this.unsubscribe$) {
      this.unsubscribe$.unsubscribe();
    }
    this.activeCartService.clearAfterStableActions();
  }
}
