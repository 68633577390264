import { Component } from '@angular/core';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { environment } from '@env/environment';
import { map, take } from 'rxjs/operators';
import { RegisterService } from '../fff-register.service';

@Component({
  selector: 'fff-login-message-box',
  templateUrl: './fff-login-message-box.component.html',
})
export class FffLoginMessageBoxComponent {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  filledIconTypes = FILLED_ICON_TYPE;
  showMessage$ = this.userAccountService.isLoggedInMsg$;
  isClosed = false;
  baseUrl: string = environment.occBaseUrl;
  messageListData$ = this.registerService
    .getAfterLoginMessage()
    .pipe(map(items => items?.primaryMessages[0] ?? {}));

  constructor(
    private userAccountService: FffUserAccountService,
    private registerService: RegisterService
  ) {}

  close(msgCode: string) {
    this.registerService
      .popOutMessage(msgCode)
      .pipe(take(1))
      .subscribe(() => {
        this.userAccountService.isLoggedInMsg$.next(false);
        this.isClosed = true;
      });
  }
}
