<div class="container pl-lg-0 pr-lg-0 px-3">
  <ng-container *ngIf="displayShippingBanner$ | async as displayShippingBanner">
    <ng-container *ngIf="displayShippingBanner">
      <div class="warning-header p-4 my-4" *ngIf="showWarning">
        <div class="d-flex flex-wrap">
          <div class="col-lg-9 col-12 d-flex flex-wrap order-1 order-lg-0 px-0">
            <div class="col-2 m-auto px-0">
              <cx-icon
                class="cx-icon icon-xl accent-color info-icon"
                [type]="outlinedIconTypes.ICON_EXCLAMATION_TRIANGLE"
              >
              </cx-icon>
            </div>
            <div class="col-10">
              <p class="mb-0">
                <span class="title body-text-demi">
                  {{ 'fffCartHeader.header' | cxTranslate }}
                </span>
              </p>
              <p class="mb-0" *ngIf="message$ | async as message">
                <span [innerHTML]="message"></span>
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-12 px-0">
            <cx-icon
              class="cx-icon cursor-pointer close"
              (click)="showWarning = false"
              [type]="outlinedIconTypes.ICON_X"
            >
            </cx-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
