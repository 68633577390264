<ng-container>
  <div
    class="container"
    [ngClass]="isFromMultiOrder ? 'multi-order-total-container' : 'container'"
    *ngIf="order$ | async as order"
  >
    <div
      [ngClass]="
        isFromMultiOrder
          ? 'multi-order-total-description'
          : 'confirmation-container-bottom p-4 mb-3'
      "
      *ngIf="
        getOrderType(order) === orderTypes.standarOrder ||
        getOrderType(order) === orderTypes.orderRequest ||
        getOrderType(order) === orderTypes.recurringOrder ||
        isFromMultiOrder
      "
    >
      <div
        class="row justify-content-end"
        *ngIf="orderTotals$ | async as orderTotals"
      >
        <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5">
          <div class="col-12 px-0" *ngIf="order">
            <div class="d-flex flex-wrap summary-row">
              <div class="col-6 px-0 summary-label">
                {{ 'orderCost.subtotal' | cxTranslate }}
              </div>
              <div class="col-6 px-0 summary-amount text-right">
                {{ orderTotals.subTotal }}
              </div>
            </div>

            <div
              class="d-flex flex-wrap summary-row"
              *ngIf="'ORDER_REQUEST' !== orderTotals.type"
            >
              <div class="col-6 px-0 summary-label">
                {{
                  (order.deliveryCost?.formattedValue
                    ? 'orderCost.shipping'
                    : 'orderCost.estimatedShipping'
                  ) | cxTranslate
                }}
              </div>
              <div class="col-6 px-0 summary-amount text-right">
                <div
                  class="free-delivery"
                  *ngIf="
                    orderTotals.prebookCart &&
                      orderTotals.deliveryCost === '$0.00';
                    else noFreeDeliveryMsg
                  "
                >
                  <span class="free-delivery-text">
                    {{ 'orderCost.freeDeliveryText' | cxTranslate }}</span
                  >
                  <span class="free-delivery-subtext">
                    {{ 'orderCost.freeDeliveryPaid' | cxTranslate }}</span
                  >
                </div>
                <ng-template #noFreeDeliveryMsg>
                  <span
                    [ngClass]="{
                      'shipping-cost': orderTotals?.deliveryCost !== '$0.00'
                    }"
                  >
                    {{
                      orderTotals.deliveryCost
                        ? orderTotals.deliveryCost
                        : ('orderCost.toBeDetermined' | cxTranslate)
                    }}
                  </span>
                </ng-template>
              </div>
            </div>

            <div
              class="d-flex flex-wrap summary-row justify-content-between"
              *ngIf="orderTotals.totalTax"
            >
              <div class="px-0 summary-label fet-tax-label">
                {{ 'orderCost.salesTax' | cxTranslate }}
                <fff-fet-info-tooltip></fff-fet-info-tooltip>
              </div>
              <div class="col-4 px-0 summary-amount text-right">
                {{ orderTotals.totalTax }}
              </div>
            </div>

            <div class="d-flex flex-wrap summary-row pb-4">
              <div class="col-6 px-0 summary-label summary-total">
                {{ 'orderCost.total' | cxTranslate }}
              </div>
              <div class="col-6 px-0 summary-amount summary-total text-right">
                {{ orderTotals.totalPrice }}
              </div>
            </div>

            <ng-container *ngIf="orderTotals.totalDiscounts">
              <div class="d-flex flex-wrap summary-row">
                <div class="col-6 px-0 summary-label">
                  {{ 'orderCost.discountConfirmation' | cxTranslate }}
                </div>
                <div class="col-6 px-0 summary-amount text-right">
                  -{{ orderTotals.totalDiscounts }}
                </div>
              </div>
            </ng-container>

            <div
              class="col-12 px-0"
              *ngIf="getOrderType(order) === orderTypes.recurringOrder"
            >
              <button class="btn btn-secondary btn-xs ml-auto">
                {{
                  'fffCheckoutOrderConfirmation.cancelRecurringOrder'
                    | cxTranslate
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isFromMultiOrder" class="questions text-center pt-3 pb-5">
    <span
      [innerHTML]="'fffCheckoutOrderConfirmation.questions' | cxTranslate"
    ></span>
    <div
      class="order-explain"
      [innerHTML]="
        'fffCheckoutOrderConfirmation.orderFooterExplain' | cxTranslate
      "
    ></div>
  </div>
</ng-container>
