<div class="container" *ngIf="order$ | async as orderData">
  <ng-container>
    <div
      class="error-header p-4 my-4"
      *ngIf="hasCsosProducts$ | async as hasCsosProducts"
    >
      <div class="row">
        <div class="col-md-9 col-sm-12 d-flex flex-wrap">
          <div class="col-2 m-auto">
            <cx-icon
              class="cx-icon icon-lg"
              [type]="outlinedIconTypes.ICON_EXCLAMATION_TRIANGLE"
            ></cx-icon>
          </div>
          <div class="col-10">
            <p class="mb-0">
              <span class="title body-text-demi">{{
                'fffCheckoutOrderConfirmation.errorHeaderTitle' | cxTranslate
              }}</span>
            </p>
            <p class="mb-0">
              <span>{{
                'fffCheckoutOrderConfirmation.errorHeaderText' | cxTranslate
              }}</span>
            </p>
          </div>
        </div>

        <div class="col-md-3 col-sm-12">
          <button
            class="btn btn-primary btn-xs col-12"
            [disabled]="isAsmEnabled"
            (click)="goToOrderHistoryListPage()"
          >
            {{ 'fffCheckoutOrderConfirmation.signOrderButton' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>

    <div
      class="header-details p-4"
      *ngIf="orderB2BData$ | async as b2bOrderData"
    >
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <p class="mb-0">
            <span class="body-text-demi">{{
              'fffCheckout.accountNumber' | cxTranslate
            }}</span>
            <span> {{ b2bOrderData.accountNumber }} </span>
          </p>
          <p class="mb-0">
            <span class="body-text-demi">{{
              'fffCheckout.company' | cxTranslate
            }}</span>
            <span> {{ b2bOrderData.accountName }}</span>
          </p>
          <p class="mb-0">
            <span class="body-text-demi">{{
              'fffCheckout.emailAddress' | cxTranslate
            }}</span>
            <span> {{ b2bOrderData.emailAddress }}</span>
          </p>
        </div>
        <div class="col-md-6 col-sm-12">
          <p class="mb-0">
            <span class="body-text-demi">{{
              'fffCheckout.shipToAddress' | cxTranslate
            }}</span>
            <span> {{ b2bOrderData.shipToAddress }}</span>
          </p>
          <p class="mb-0">
            <span class="body-text-demi">{{
              'fffCheckout.deliveryTerms' | cxTranslate
            }}</span>
            <span> {{ b2bOrderData.deliveryTerms }}</span>
          </p>
          <p class="mb-0">
            <span class="body-text-demi">{{
              'fffCheckout.paymentTerms' | cxTranslate
            }}</span>
            <span> {{ b2bOrderData.paymentTermsDesc }}</span>
          </p>
        </div>
      </div>

      <div *ngIf="getOrderType(orderData) == orderTypes.recurringOrder">
        <!--
      <div class="d-flex flex-wrap summary-row">
        <div class="col-12 px-0 summary-divider"></div>
      </div>

      <div class="row pt-4">
        <div class="col-md-6 col-sm-12">
          <p class="mb-0">
            <span class="body-text-demi">{{'fffCheckoutOrderConfirmation.recurringOrder.orderPlacedBy' |
              cxTranslate}}</span>
            <span> {{ orderData.orders[0].order.user.name }} </span>
          </p>
          <p class="mb-0">
            <span class="body-text-demi">{{'fffCheckoutOrderConfirmation.recurringOrder.frequencyOfOrder' |
              cxTranslate}}</span>
            <span> Every 14th day in a month</span>
          </p>
        </div>
        <div class="col-md-6 col-sm-12">
          <p class="mb-0">
            <span class="body-text-demi">{{'fffCheckoutOrderConfirmation.recurringOrder.orderStartDate' |
              cxTranslate}}</span>
            <span> 03/14/2022</span>
          </p>
          <p class="mb-0">
            <span class="body-text-demi">{{'fffCheckoutOrderConfirmation.recurringOrder.nextOrderDate' |
              cxTranslate}}</span>
            <span> 04/14/-2022</span>
          </p>
        </div>
      </div>
      --></div>
    </div>
  </ng-container>
</div>
