<div [ngClass]="customClass">
  <div
    *ngIf="!isMobile && !showOnlyPaginator"
    class="th tableHeader"
    [style.grid-template-columns]="
      isMobile ? columnsGrid.mobile : columnsGrid.desktop
    "
  >
    <ng-container *ngFor="let f of fields">
      <div
        class="pr-2"
        [ngClass]="{
          'cursor-pointer th-cell--sortable': !!f?.sortable,
          'th-cell--desc': f?.sort === 1,
          'th-cell--asc': f?.sort === -1
        }"
        (click)="onSort(f)"
      >
        {{ f.title | cxTranslate }}

        <cx-icon *ngIf="!!f?.sortable" [type]="iconType.CARET_DOWN"></cx-icon>
      </div>
    </ng-container>
  </div>

  <div
    class="td-container"
    [ngClass]="{ loading: data.loading }"
    *ngIf="!showOnlyPaginator"
  >
    <ng-container *ngIf="!hasTemplate">
      <div
        class="td tableDataRow"
        [style.grid-template-columns]="
          isMobile ? columnsGrid.mobile : columnsGrid.desktop
        "
        *ngFor="let r of data.data"
      >
        <ng-container *ngFor="let f of fields">
          <div class="cell">
            <div *ngIf="isMobile">{{ f.title | cxTranslate }}</div>
            <div>
              {{ r[f.field] }}
              <span
                *ngIf="
                  f.field === 'allocatedQty' ||
                  f.field === 'remainingQty' ||
                  f.field === 'yearlyAllocatedQty' ||
                  f.field === 'yearlyRemainingQty'
                "
                >{{ r['allocatedUOM'] }}</span
              >
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="hasTemplate">
      <ng-container *ngFor="let r of data.data">
        <ng-container
          *ngTemplateOutlet="itemTemplate; context: { item: r }"
        ></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="isInvoiceHistoryPage" class="processingFeeInfoText mt-2">
    <span class="icon">* </span>
    <span>{{ 'fffInvoiceHistory.processingFeeInfoText' | cxTranslate }}</span>
  </div>

  <div
    class="paginator-showing d-flex justify-content-center"
    *ngIf="hasPaginator"
  >
    {{ 'fffPriceList.showing' | cxTranslate }}
    <ng-container *ngIf="data.data; else noDataToDisplay">
      {{ data.data.length > 0 ? data.current * size + 1 : data.data.length }} -
      {{
        data.data.length > 0
          ? existsMore(1)
            ? data.current * size + size
            : data.total
          : data.data.length
      }}
    </ng-container>
    <ng-template #noDataToDisplay> 0 - 0 </ng-template>
    {{ 'fffPriceList.of' | cxTranslate }} {{ data.total }}
  </div>

  <div class="paginator d-flex justify-content-center" *ngIf="hasPaginator">
    <button
      class="start"
      (click)="getData(data.current - 1)"
      [disabled]="data.current < 1"
    >
      &#60;
    </button>
    <button
      *ngIf="!existsMore(1) && data.current > 1"
      (click)="getData(data.current - 2)"
    >
      {{ data.current - 1 }}
    </button>
    <button *ngIf="data.current > 0" (click)="getData(data.current - 1)">
      {{ data.current }}
    </button>
    <button class="selected">{{ data.current + 1 }}</button>
    <button *ngIf="existsMore(1)" (click)="getData(data.current + 1)">
      {{ data.current + 2 }}
    </button>
    <button
      *ngIf="data.current === 0 && existsMore(2)"
      (click)="getData(data.current + 2)"
    >
      {{ data.current + 3 }}
    </button>
    <button
      class="end"
      (click)="getData(data.current + 1)"
      [disabled]="!existsMore(1)"
    >
      &#62;
    </button>
  </div>
</div>
