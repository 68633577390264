import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmpty',
})
export class FFFIsEmptyObjectPipe implements PipeTransform {
  transform(obj: object): boolean {
    return Object.keys(obj).length === 0;
  }
}
