<ng-container *ngIf="!isClosed">
  <ng-container *ngIf="messageListData$ | async as message">
    <div
      *ngIf="!(message | isEmpty)"
      class="login-message-box"
      [ngClass]="[
        message?.modalSize?.toLowerCase(),
        message?.backgroundColor?.toLowerCase()
      ]"
    >
      <div class="close-icon">
        <a href="javascript:void(0)" (click)="close(message?.code)">
          <cx-icon
            class="cx-icon icon-lg"
            [type]="outlinedIconTypes.ICON_X"
          ></cx-icon>
        </a>
      </div>
      <div
        class="login-message-box__icon"
        [ngStyle]="{ 'border-color': message?.backgroundColor }"
        [ngClass]="message?.backgroundColor?.toLowerCase()"
      >
        <div
          class="cx-icon icon-lg"
          [ngClass]="message?.icon?.toLowerCase()"
        ></div>
      </div>
      <div class="login-message-box__cntnr">
        <h5>{{ message?.title }}</h5>
        <p class="pb-0 mb-0">{{ message?.messageText }}</p>
        <div class="d-flex justify-content-center" *ngIf="message?.image">
          <div class="mt-2">
            <img
              class="w-100"
              [src]="
                baseUrl +
                (message?.modalSize?.toLowerCase() === 'short'
                  ? message?.image?.mobile?.url
                  : message?.image?.widescreen?.url)
              "
            />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
