<div class="checkout-header p-4">
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <ng-container *ngIf="b2bUnitData$ | async as b2bUnitData">
        <p class="mb-0">
          <span class="body-text-demi">{{
            'fffCheckout.company' | cxTranslate
          }}</span
          >&nbsp;
          <span>{{ b2bUnitData.name }}</span>
        </p>
        <p class="mb-0">
          <span class="body-text-demi">{{
            'fffCheckout.accountNumber' | cxTranslate
          }}</span
          >&nbsp;
          <span>{{ b2bUnitData.uid }}</span>
        </p>
      </ng-container>
      <ng-container *ngIf="userData$ | async as userData">
        <p class="mb-0">
          <span class="body-text-demi">{{
            'fffCheckout.emailAddress' | cxTranslate
          }}</span
          >&nbsp;
          <span>{{ userData.uid }}</span>
        </p>
      </ng-container>
    </div>
    <div class="col-md-6 col-sm-12">
      <ng-container *ngIf="b2bUnitData$ | async as b2bUnitData">
        <p class="mb-0">
          <span class="body-text-demi">{{
            'fffCheckout.shipToAddress' | cxTranslate
          }}</span
          >&nbsp;
          <span>{{ getShippingAddress(b2bUnitData) }}</span>
        </p>
        <p class="mb-0">
          <span class="body-text-demi">{{
            'fffCheckout.deliveryTerms' | cxTranslate
          }}</span
          >&nbsp;
          <span>{{ b2bUnitData.deliveryTermsDesc }}</span>
        </p>
        <p class="mb-0">
          <span class="body-text-demi">{{
            'fffCheckout.paymentTerms' | cxTranslate
          }}</span
          >&nbsp;
          <span>{{ b2bUnitData.paymentTermsDesc }}</span>
        </p>
      </ng-container>
    </div>
  </div>
</div>
