import { Component, Input, OnInit } from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { ENTRY_PO, EntryPoState } from '@app/reducers';
import { BASE_URL_KEYS } from '@config/content/constants';
import { FFFCart } from '@model/fff-cart-data.model';
import { FffProfile } from '@model/fff-profile.model';
import { Store } from '@ngrx/store';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CmsComponentData } from '@spartacus/storefront';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fff-checkout-cart-summary',
  templateUrl: './fff-checkout-cart-summary.component.html',
})
export class FffCheckoutCartSummaryComponent implements OnInit {
  @Input() entries: any;
  @Input() poNumber: any;
  @Input() profile: FffProfile | undefined;
  @Input() poRules: any = {};

  BASE_URL_KEYS = BASE_URL_KEYS;
  constructor(
    public fffActiveCartService: ActiveCartFacade,
    public component: CmsComponentData<any>,
    private communicationService: FffCommunicationService,
    private storeEntryPo: Store<EntryPoState>
  ) {}

  shippingCostList: any = [];
  currentCart: FFFCart | undefined;

  ngOnInit() {
    this.fffActiveCartService
      .getActive()
      .pipe(take(1))
      .subscribe(res => {
        this.currentCart = res;
        this.communicationService.resetShippingCosts(this.currentCart.code);
      });

    this.storeEntryPo.dispatch({
      type: ENTRY_PO,
      payload: { key: 0, action: 'CLEAR', data: {} },
    });
  }

  getDiscount(item: any): number {
    if (item.discountValue) {
      return item.discountValue;
    }
    return 0;
  }

  getTax(item: any): number {
    if (item.taxValue) {
      return item.quantity * item.taxValue;
    }
    return 0;
  }

  updateShipping(evt: any) {
    let shippingCostItem = this.shippingCostList.find(
      (elem: any) => elem.entryNumber === evt.entryNumber
    );

    if (shippingCostItem) {
      shippingCostItem.price = evt.price;
      return;
    }

    this.shippingCostList.push(evt);
  }

  getShippingCost(product: any) {
    let shippingCostItem = this.shippingCostList.find(
      (elem: any) => elem.entryNumber === product
    );
    return shippingCostItem ? +shippingCostItem.price.split(['$'])[1] : 0;
  }
}
