<div class="modal-header">
  <h4 class="modal-title text-primary">
    {{ titleI18nKey | cxTranslate }}
  </h4>

  <cx-icon
    *ngIf="isTransactionFlow"
    class="text-reset cx-icon-navigation"
    (click)="navigateToSelectCard()"
    [type]="outlinedIconTypes.ICON_ARROW_LEFT"
    ngbAutofocus
  ></cx-icon>
  <cx-icon
    class="text-reset cx-icon-close"
    (click)="closeAndReset()"
    [type]="outlinedIconTypes.ICON_X"
  ></cx-icon>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-6" [formGroup]="form">
      <h6 class="text-primary mb-4 body-text-demi-xl">
        {{ 'fffInvoicePayment.addNewCard.billingAddress' | cxTranslate }}
      </h6>

      <div formGroupName="billingAddress">
        <div class="row row-cols-2">
          <div class="col">
            <div class="form-group">
              <label for="input-firstName">{{
                'fffInvoicePayment.addNewCard.fields.firstName.label'
                  | cxTranslate
              }}</label>
              <input
                type="text"
                class="form-control"
                id="input-firstName"
                formControlName="firstName"
                name="firstName"
              />
              <cx-form-errors
                [control]="form.get('billingAddress.firstName')"
              ></cx-form-errors>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="input-lastName">{{
                'fffInvoicePayment.addNewCard.fields.lastName.label'
                  | cxTranslate
              }}</label>
              <input
                type="text"
                class="form-control"
                id="input-lastName"
                formControlName="lastName"
                name="lastName"
              />
              <cx-form-errors
                [control]="form.get('billingAddress.lastName')"
              ></cx-form-errors>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="input-line1"
            >{{
              'fffInvoicePayment.addNewCard.fields.line1.label' | cxTranslate
            }}
            <input
              type="text"
              class="form-control addressInput"
              id="input-line1"
              formControlName="line1"
              name="line1"
              (input)="onInput($event)"
            />
            <div class="search-icon addressSearch">
              <cx-icon
                class="cx-icon icon-sm"
                [type]="outlinedIconTypes.ICON_SEARCH"
              ></cx-icon>
            </div>
            <div
              *ngIf="form.get('billingAddress.line1')"
              class="reset resetAddress"
            >
              <cx-icon
                class="cx-icon icon-sm"
                [ngClass]="{
                  'pointer-link': !isIframeLoaded && !showBufferIcon
                }"
                [type]="outlinedIconTypes.ICON_X_CIRCLE"
                (click)="
                  !isIframeLoaded && !showBufferIcon ? clearAddress() : ''
                "
              ></cx-icon>
            </div>
            <ul *ngIf="predictions.length > 0" class="address-prediction-list">
              <li
                class="address-prediction-item d-flex"
                *ngFor="let prediction of predictions"
                (click)="selectPlace(prediction)"
              >
                <div class="locationIcon">
                  <cx-icon
                    class="cx-icon icon-sm"
                    [type]="filledIcons.ICON_GEO_ALT_FILL"
                  ></cx-icon>
                </div>
                <div>{{ prediction.description }}</div>
              </li>
            </ul>
            <cx-form-errors
              [control]="form.get('billingAddress.line1')"
            ></cx-form-errors>
          </label>
        </div>

        <div class="form-group">
          <label for="input-line2">{{
            'fffInvoicePayment.addNewCard.fields.line2.label' | cxTranslate
          }}</label>
          <input
            type="text"
            class="form-control"
            id="input-line2"
            formControlName="line2"
            name="line2"
          />
          <cx-form-errors
            [control]="form.get('billingAddress.line2')"
          ></cx-form-errors>
        </div>

        <div class="row row-cols-3">
          <div class="col">
            <div class="form-group">
              <label for="input-town">{{
                'fffInvoicePayment.addNewCard.fields.town.label' | cxTranslate
              }}</label>
              <input
                type="text"
                class="form-control"
                id="input-town"
                formControlName="town"
                name="town"
              />
              <cx-form-errors
                [control]="form.get('billingAddress.town')"
              ></cx-form-errors>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="input-region">{{
                'fffInvoicePayment.addNewCard.fields.region.label' | cxTranslate
              }}</label>
              <ng-select
                labelForId="nr-field-type"
                [items]="regions"
                [clearable]="false"
                [searchable]="false"
                bindLabel="isocodeShort"
                formControlName="region"
              ></ng-select>
              <cx-form-errors
                [control]="form.get('billingAddress.region')"
              ></cx-form-errors>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="input-postalCode">{{
                'fffInvoicePayment.addNewCard.fields.postalCode.label'
                  | cxTranslate
              }}</label>
              <input
                type="text"
                class="form-control"
                id="input-postalCode"
                formControlName="postalCode"
                name="postalCode"
                fffAllowOnlyNumbers
              />
              <cx-form-errors
                [control]="form.get('billingAddress.postalCode')"
              ></cx-form-errors>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group" *ngIf="isTransactionFlow">
            <label for="input-savedPayment">{{
              'fffInvoicePayment.addNewCard.fields.savedPayment.label'
                | cxTranslate
            }}</label>

            <fff-radio-list-control
              formControlName="savedPayment"
              [items]="saveCardForFutureUseOptions"
            ></fff-radio-list-control>
            <cx-form-errors
              [control]="form.get('savedPayment')"
            ></cx-form-errors>
          </div>
        </div>
        <div class="col-auto spinner-btn">
          <button
            *ngIf="!isIframeLoaded || showBufferIcon"
            type="button"
            class="btn btn-primary btn-action"
            [disabled]="
              form.invalid ||
              showBufferIcon ||
              isSuggestionsLoaded ||
              (predictions && predictions.length > 0)
            "
            (click)="initSaveCard()"
          >
            {{ 'common.continue' | cxTranslate }}
            <div *ngIf="showBufferIcon" class="cx-spinner button-loader">
              <cx-spinner></cx-spinner>
            </div>
          </button>
          <button
            *ngIf="isIframeLoaded && !showBufferIcon"
            type="button"
            class="btn btn-secondary btn-action"
            [disabled]="form.invalid"
            (click)="editCard()"
          >
            {{ 'common.edit' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-6">
      <h6 class="text-primary mb-4 body-text-demi-xl ml-5 pl-3">
        {{ 'fffInvoicePayment.addNewCard.creditCardInformation' | cxTranslate }}
      </h6>
      <ng-container
        *ngIf="
          !isDuplicateCard && !isDebitCard;
          else duplicateCardOrDebitCardTemplete
        "
      >
        <div
          *ngIf="!iframeUrl || !isIframeLoaded"
          class="fff-elavon-placeholder"
          id="fff-evalon-add-to-card"
        >
          <div class="secure-payment"></div>
        </div>
      </ng-container>

      <iframe
        [ngClass]="{
          hiddenIframe: !isIframeLoaded,
          visibleIframe: isIframeLoaded
        }"
        class="fff-elavon-iframe"
        *ngIf="iframeUrl"
        [src]="iframeUrl"
        #elavonIFrame
      ></iframe>
    </div>
  </div>
</div>
<ng-template #duplicateCardOrDebitCardTemplete>
  <div
    *ngIf="isDuplicateCard"
    class="fff-elavon-placeholder"
    id="fff-evalon-add-to-card"
  >
    <div class="duplicateCardText">
      {{ 'fffInvoicePayment.addNewCard.cardExistsMessage' | cxTranslate }}
    </div>
  </div>
  <div
    *ngIf="isDebitCard"
    class="fff-elavon-placeholder"
    id="fff-evalon-add-to-card"
  >
    <div class="debitCardErrorMessage">
      {{ 'fffInvoicePayment.addNewCard.invalidCardMessage' | cxTranslate }}
    </div>
  </div>
</ng-template>
