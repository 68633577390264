import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { BASE_URL_KEYS } from '@config/content/constants';
import { FFFCart, FFFOrderEntry } from '@model/fff-cart-data.model';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';

@Component({
  selector: 'fff-cart-summary',
  templateUrl: './fff-cart-summary.component.html',
})
export class FffCartSummaryComponent implements OnInit, OnChanges {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  filledIconTypes = FILLED_ICON_TYPE;
  BASE_URL_KEYS = BASE_URL_KEYS;
  @Input() cart: FFFCart | undefined;
  @Input() entries: FFFOrderEntry[] | undefined;
  existsAutoSplitBillerEntry: boolean = false;
  ngOnInit() {
    this.checkAutoSplitBillerItem(this.entries);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.checkAutoSplitBillerItem(this.entries);
  }

  checkAutoSplitBillerItem(entries: FFFOrderEntry[] | undefined) {
    if (!entries) {
      this.existsAutoSplitBillerEntry = false;
      return;
    }

    entries.some(entry => {
      if (entry && entry.accountType === 'AUTO') {
        return (this.existsAutoSplitBillerEntry = true);
      }
      return (this.existsAutoSplitBillerEntry = false);
    });
  }
}
