<ng-container>
  <div
    class="container px-0 cx-page-slot prebook-cart-complete-page"
    *ngIf="entries$ | async as entries"
  >
    <ng-container *ngIf="isStableCart$ | async; else loadingTemplate">
      <ng-container *ngIf="entries && entries.length > 0; else emptyCart">
        <div class="cx-page-slot TopContent">
          <h3 class="h3 body-text-link-xl cart-header-title">
            {{ orderSummaryTitle }}
          </h3>
        </div>

        <div class="cx-page-slot TopContent">
          <fff-cart-header-message></fff-cart-header-message>
        </div>

        <div class="cx-page-slot CenterLeftContentSlot">
          <ng-container *ngIf="cartData$ | async as data; else loadingTemplate">
            <fff-prebook-cart-details
              [prices]="data.priceData"
              [stock]="data.stockData"
            ></fff-prebook-cart-details>
          </ng-container>
        </div>

        <div class="cx-page-slot CenterRightContentSlot">
          <fff-cart-totals></fff-cart-totals>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #emptyCart>
      <div class="cx-page-slot TopContent">
        <h3 class="h3 body-text-link-xl cart-header-title">
          {{ 'fffCartHeader.title' | cxTranslate : { items: 0 } }}
        </h3>
      </div>

      <div class="cx-page-slot EmptyCartMiddleContent">
        <fff-empty-cart class="col-12"></fff-empty-cart>
      </div>
    </ng-template>

    <ng-template #loadingTemplate>
      <fff-cart-header-message></fff-cart-header-message>
      <fff-cart-loader></fff-cart-loader>
    </ng-template>
  </div>
</ng-container>
