import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { PRODUCT_ADMIN_SET, ProductAdminSet } from '@app/reducers';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';


@Component({
  selector: 'fff-product-admin-set-y-connector',
  templateUrl: './fff-product-admin-set-y-connector.component.html',
})
export class FffProductAdminSetYConnectorComponent implements OnInit, OnDestroy {

  @Input() product: any | undefined;
  private subscription:Subscription = new Subscription();
  @Input() itemAddedToCart!: EventEmitter<boolean>;
  constructor(public cd: ChangeDetectorRef, private storeAdminSet: Store<ProductAdminSet>) { }

  isYconnectorChecked = false;
  isAdminChecked = false;

  ngOnInit(){
    this.isYconnectorChecked = false;
    this.isAdminChecked = false;
    this.subscription.add(this.itemAddedToCart.subscribe((addedToCart) => {
      console.log(addedToCart);
      if(addedToCart){
        this.isYconnectorChecked = false;
        this.isAdminChecked = false;
        this.disptachProductAdminSet();
      }
    }));
  }

  checkCheckbox(type: string, evt: any){

    let isChecked = evt.target.checked;
    if(type === 'yconnector'){
      this.isYconnectorChecked = isChecked;
    }

    if(type === 'adminSet'){
      this.isAdminChecked = isChecked;
    }
    this.cd.detectChanges();

   this.disptachProductAdminSet();
  }

  disptachProductAdminSet(){
    this.storeAdminSet.dispatch({
      type: PRODUCT_ADMIN_SET,
      payload: {
        key: this.product.code,
        data: {
          yConnector: this.isYconnectorChecked,
          adminSet: this.isAdminChecked,
        },
      },
    });
  }

  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
}
