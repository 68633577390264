import { Component } from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'fff-checkout-header',
  templateUrl: './fff-checkout-header.component.html',
})
export class FffCheckoutHeaderComponent {
  userData$: Observable<any> = this.userAccountDetails.get();
  b2bUnitData$: Observable<any> = this.userData$.pipe(
    switchMap(user => {
      return this.communicationService.getCurrentAccount(user);
    })
  );

  constructor(
    private userAccountDetails: UserAccountFacade,
    private communicationService: FffCommunicationService
  ) {}

  getShippingAddress(b2bUnitData: any): string {
    if (b2bUnitData.addresses) {
      const shippingAddress = b2bUnitData.addresses.filter(
        (address: any) => address.shippingAddress
      );
      return shippingAddress.length > 0
        ? shippingAddress[0].formattedAddress
        : '';
    }
    return '';
  }
}
