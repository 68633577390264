<div class="entry d-flex">
  <div>
    <cx-media
      [container]="imageObject"
      class="media"
      format="thumbnail"
      role="presentation"
    >
    </cx-media>
  </div>
  <div class="w-100">
    <p class="entry-title w-75 text-brand-primary-80">
      <b>{{ entry.product.name }}</b>
    </p>
    <p>{{ entry.product.manufacturer }}</p>
    <p>{{ 'fffProduct.lblNdc' | cxTranslate }} {{ entry.product?.ndc }}</p>
    <p>{{ 'fffProduct.lblSku' | cxTranslate }} {{ entry.product?.code }}</p>
    <p *ngIf="entry?.strength">
      {{ 'fffProduct.actualStrength' | cxTranslate }}: {{ entry.strength }}
    </p>
    <p *ngIf="entry?.batch">
      {{ 'fffProduct.lblBatch' | cxTranslate }}: {{ entry?.batch }}
      <ng-container *ngIf="entry?.expirationDate"
        >| {{ 'fffProduct.exp' | cxTranslate }}:
        {{
          parseDateI(entry?.expirationDate) | cxDate : 'MM/dd/yyyy'
        }}</ng-container
      >
    </p>
    <p>
      {{ 'fffQuickOrder.qty' | cxTranslate }} {{ entry.quantity }}
      {{ entry.uom }}
    </p>
    <ng-container *ngIf="isOfVaccineTypeI(entry)">
      <ng-container [ngTemplateOutlet]="vaccineUomTemplate"></ng-container>
    </ng-container>
    <p>
      {{ 'fffQuickOrder.account' | cxTranslate }}
      {{
        'AUTO' === entry.accountType
          ? ('fffProduct.lblAccountAutoSplitBiller' | cxTranslate)
          : getAccountTypeLabel(entry.accountType)
      }}
    </p>
    <p *ngIf="entry?.warehouse">
      {{ 'fffProduct.lblWarehouse' | cxTranslate }}: {{ entry?.warehouse }}
    </p>
  </div>
  <div class="d-flex" style="flex-direction: column">
    <div class="h-50">
      <p class="entry-title mb-3">
        <b class="text-brand-primary-80">{{
          entry.totalPrice.formattedValue
        }}</b>
      </p>
      <p class="cart-unit-price">
        {{ entry.basePrice.formattedValue }}/{{ entry.uom }}
      </p>
      <p
        *ngIf="isValidDiscPrice(entry) && isValidAltUom(entry)"
        class="cart-unit-price mb-0 eu-price"
      >
        <b>${{ entry.altDiscPrice | number : '1.2-2' }}/{{ entry.altUom }}</b>
      </p>

      <div class="fed-tax body-text-sm" *ngIf="entry.taxValue > 0">
        {{ 'fffProduct.lblFedexTax' | cxTranslate }}
      </div>
    </div>
    <div
      *ngIf="showActions"
      class="h-50 d-flex"
      style="align-items: flex-end; justify-content: flex-end"
    >
      <a
        class="body-text-sm remove-from-cart"
        (click)="remove(entry)"
        href="javascript:void(0);"
      >
        {{ 'fffQuickOrder.remove' | cxTranslate }}
      </a>
    </div>
  </div>
</div>

<hr class="separator-entries" />

<ng-template #vaccineUomTemplate>
  <p>1 {{ entry.uom }} = 10 DS</p>
</ng-template>
