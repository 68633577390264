<fff-checkout-header></fff-checkout-header>

<ng-container>
  <p class="col-12 px-auto px-lg-0 body-text-xl title">
    {{ 'fffCheckout.billingInfo' | cxTranslate }}
  </p>
  <fff-checkout-billing-info
    *ngIf="poRules"
    [poRules]="poRules"
    [invoiceApprover]="invoiceApprover"
  ></fff-checkout-billing-info>

  <p class="col-12 px-auto px-lg-0 body-text-xl title">
    {{ 'fffCheckout.cartSummary' | cxTranslate }}
  </p>
  <ng-container *ngIf="cart$ | async as cart">
    <ng-container *ngIf="profile$ | async as profile">
      <ng-container *ngIf="cartEntries$ | async as cartEntries">
        <fff-checkout-cart-summary
          [profile]="profile"
          [entries]="cartEntries"
          [poNumber]="poNumber"
          [poRules]="poRules"
        ></fff-checkout-cart-summary>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
