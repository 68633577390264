<ng-container *ngIf="productData$ | async as productData; else loading">
  <ng-container
    class="plp container"
    *ngIf="
      productData?.stockData?.results &&
        productData?.pricesData &&
        !this.isProductAPIFailed &&
        (isLoggedIn$ | async);
      else loginOrDataUnavailableSection
    "
  >
    <fff-product-badge-info
      [product]="product"
      [pricesData]="productData.pricesData"
      [isSplitBillerAccount]="!!b2bunit?.autoSplitBillerActive"
    ></fff-product-badge-info>

    <div class="product-badge">
      <ng-container *ngIf="!prebookFormEnabled">
        <a class="name" routerLink="{{ product?.url }}">
          {{ product?.name }}
        </a>
      </ng-container>
      <ng-container *ngIf="prebookFormEnabled">
        <div class="name">{{ product?.name }}</div>
      </ng-container>
    </div>

    <div class="row pl-md-3">
      <div
        class="col-12 p-1 p-md-0"
        *ngIf="product?.name"
        [ngClass]="{
      'col-md-4': !prebookFormEnabled,
      'col-md-5': prebookFormEnabled,
    }"
      >
        <!-- Image and below section-->
        <fff-product-item-info
          [product]="product"
          [pricesData]="productData.pricesData"
          [itemAddedToCart]="itemAddedToCart"
        ></fff-product-item-info>
        <fff-product-list-actual-strength
          class="col-12 px-0 cart-actual-strength"
          [product]="product"
          [stockData]="productData.stockData"
        >
        </fff-product-list-actual-strength>
      </div>

      <div
        class="col-12 split-billing p-md-0"
        [ngClass]="{
        'col-md-5': !prebookFormEnabled,
        'col-md-7': prebookFormEnabled,
      }"
      >
        <fff-split-billing
          [allocation]="getStockInfo(productData.stockData)"
          [product]="product"
          [isSplitBillerAccount]="!!b2bunit?.autoSplitBillerActive"
          [pricesData]="productData.pricesData"
          [prebookFormEnabled]="prebookFormEnabled"
        ></fff-split-billing>
      </div>

      <div
        *ngIf="!prebookFormEnabled"
        class="col-12 col-md-3 p-3 p-md-0 product-actions"
      >
        <!-- Allocation component -->
        <div *ngIf="getStockInfo(productData.stockData); else noStockData">
          <ng-container>
            <fff-product-allocation
              class="mb-3"
              [product]="product"
              [allocation]="getStockInfo(productData.stockData)"
              [priceData]="productData"
            >
            </fff-product-allocation>
          </ng-container>
        </div>
        <ng-template #noStockData>
          <h5 *ngIf="!product.hideStockIndicator" class="body-text-xl mb-3">
            <b *ngIf="product.onDemand" class="on-demand">{{
              'fffProduct.lblOnDemand' | cxTranslate
            }}</b>
            <ng-container *ngIf="!product.onDemand">
              <b class="in-transit">
                {{ 'fffProduct.lblInTransit' | cxTranslate }}
              </b>
              <div class="outOfStock-note">
                <small>
                  {{ 'fffProduct.lblOutOfStockNote' | cxTranslate }}
                </small>
              </div>
            </ng-container>
          </h5>
        </ng-template>

        <fff-tooltip
          *ngIf="tooltipText !== ''"
          [alwaysVisible]="false"
          [isTranslatableLabel]="true"
          [text]="tooltipText"
        ></fff-tooltip>

        <div *ngIf="!product.hideStockIndicator && !product?.notOrderable">
          <fff-product-stock-status
            [pCode]="product.code"
            [product]="product"
            [stock]="getStockInfo(productData.stockData)"
          ></fff-product-stock-status>
        </div>
        <fff-add-to-cart
          class="w-100"
          (cartErrorEventEmitter)="cartErrorEventEmitter($event)"
          [product]="product"
          [stockAndAlloc]="getStockInfo(productData.stockData)"
          [b2bunit]="b2bunit"
          [pricesData]="productData.pricesData"
          [isPLPPage]="true"
          (itemAddedToCart)="handleAddToCartEvent()"
        ></fff-add-to-cart>
        <fff-add-to-favorites
          class="w-100"
          *ngIf="!product?.notOrderable"
          [product]="product"
          [isPLPPage]="true"
        ></fff-add-to-favorites>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12 pr-md-0 pl-md-2 mt-md-3">
        <fff-product-details
          [product]="product"
          [allocation]="getStockInfo(productData.stockData)"
          [ndcFields]="ndcFields"
        >
        </fff-product-details>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loginOrDataUnavailableSection>
  <div class="row product-item-holder">
    <div class="col-2 product-img pl-md-3">
      <cx-media
        [container]="imageObject"
        [alt]="product?.summary!"
        format="product"
      ></cx-media>
    </div>
    <div class="col-10 pl-3 pr-0">
      <div class="row details-holder">
        <div [ngClass]="this.deviceService.isDesktop() ? 'col-6' : 'col-12'">
          <h3>
            <a class="name" [routerLink]="product?.url">{{ product?.name }}</a>
          </h3>
          <p class="manufacturer">{{ product.manufacturer }}</p>
          <p>{{ 'fffProduct.lblNdc' | cxTranslate }} {{ product.ndc }}</p>
          <p>{{ 'fffProduct.lblSku' | cxTranslate }} {{ product.code }}</p>
        </div>
        <div
          class="col-6"
          [ngClass]="{ loginDialog: !(isLoggedIn$ | async) }"
          *ngIf="this.deviceService.isDesktop()"
        >
          <ng-container *ngIf="!(isLoggedIn$ | async); else unableToLoadInfo">
            <ng-container *ngTemplateOutlet="loginDialog"></ng-container>
          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="this.deviceService.isDesktop()">
        <ng-container *ngTemplateOutlet="productDetail"></ng-container>
      </ng-container>
    </div>
    <div
      class="col-11"
      [ngClass]="{ loginDialog: !(isLoggedIn$ | async) }"
      *ngIf="this.deviceService.isMobile() || this.deviceService.isTablet()"
    >
      <ng-container *ngIf="!(isLoggedIn$ | async); else unableToLoadInfo">
        <ng-container *ngTemplateOutlet="loginDialog"></ng-container>
      </ng-container>
    </div>
    <ng-container
      *ngIf="this.deviceService.isMobile() || this.deviceService.isTablet()"
    >
      <ng-container *ngTemplateOutlet="productDetail"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #productDetail>
  <div class="col-12 p-0 mb-4 mt-2">
    <div class="col-12 pl-2 pr-1 pl-md-0 pr-md-0 mt-2">
      <fff-product-details [product]="product" [ndcFields]="ndcFields">
      </fff-product-details>
    </div>
  </div>
</ng-template>

<ng-template #loginDialog>
  <div class="login-request">
    <a routerLink="/login" routerLinkActive="active">
      {{ 'fffLogin.login' | cxTranslate }}
    </a>
    {{ 'fffProduct.lblProductDesc' | cxTranslate }}
  </div>
  <p>
    {{ 'fffProduct.lblNoAccount' | cxTranslate }} <br />
    <a routerLink="/register-account" routerLinkActive="active">
      {{ 'fffProduct.lblRegisterNow' | cxTranslate }}
    </a>
  </p>
</ng-template>

<ng-template #loading>
  <div *ngIf="!product">
    <fff-products-loader [isPLPPage]="true"></fff-products-loader>
  </div>
  <div *ngIf="product">
    <fff-products-loader
      [isPLPPage]="true"
      [product]="product"
    ></fff-products-loader>
    <ng-container *ngTemplateOutlet="productDetail"></ng-container>
  </div>
</ng-template>

<ng-template #unableToLoadInfo>
  <div class="unableToLoadInfo">
    <div>
      <cx-icon
        class="cx-icon"
        [type]="outlinedIconTypes.ICON_INFO_CIRCLE"
      ></cx-icon>
    </div>
    <div>{{ 'fffProduct.unableToLoad' | cxTranslate }}</div>
    <div>{{ 'fffProduct.pleaseTryLater' | cxTranslate }}</div>
  </div>
</ng-template>
