<div class="container register-form">
  <div
    class="row register-container col-xs-12 col-md-8 d-flex justify-content-center align-items-center m-auto px-0"
    *ngIf="!(isLoading$ | async); else loading"
  >
    <div
      class="col-md-12 d-flex justify-content-center align-items-center register-header register-background-content"
    >
      <div>
        <h3 class="h3">{{ 'fffRegister.header' | cxTranslate }}</h3>
      </div>
    </div>

    <div class="col-md-12 register-content">
      <form (ngSubmit)="submitForm()" [formGroup]="registerForm">
        <div class="col-lg-10 col-md-12 m-auto d-flex flex-wrap">
          <div class="col-12">
            <span class="text-center">
              <p class="need-account" (click)="downloadAccountForm()">
                {{ 'fffRegister.needAccount' | cxTranslate }}
              </p>
            </span>
          </div>

          <div class="col-12">
            <label
              class="actions"
              [innerHTML]="'fffRegister.actions' | cxTranslate"
            ></label>
          </div>

          <div class="col-12">
            <span class="text-center">
              <p class="need-account">
                {{ 'fffRegister.bioSupplyLogin' | cxTranslate }}
              </p>
            </span>
          </div>

          <div class="col-12 form-group">
            <label class="label-content">
              <span [innerHTML]="'fffRegister.accNumber' | cxTranslate"></span>
              <input
                (blur)="validateFFFAccount()"
                id="accountNumber"
                aria-required="true"
                autocomplete="off"
                class="form-control form-control-lg text-uppercase"
                type="text"
                name="accountNumber"
                formControlName="fffAccountNumber"
                maxlength="11"
              />
              <cx-form-errors
                [control]="$any(registerForm).get('fffAccountNumber')"
              ></cx-form-errors>
            </label>
          </div>

          <div class="col-12 px-0 d-flex flex-wrap">
            <div class="col-sm-12 col-md-6 form-group">
              <label>
                <span class="">{{
                  'fffRegister.register.firstName' | cxTranslate
                }}</span>
                <input
                  id="firstName"
                  aria-required="true"
                  class="form-control form-control-lg"
                  type="text"
                  name="firstName"
                  placeholder="{{
                    'fffRegister.register.placeholder.firstName' | cxTranslate
                  }}"
                  formControlName="firstName"
                />
                <cx-form-errors
                  [control]="$any(registerForm).get('firstName')"
                ></cx-form-errors>
              </label>
            </div>
            <div class="col-sm-12 col-md-6 form-group">
              <label>
                <span class="">{{
                  'fffRegister.register.lastName' | cxTranslate
                }}</span>
                <input
                  id="lastName"
                  aria-required="true"
                  class="form-control form-control-lg"
                  type="text"
                  name="lastName"
                  placeholder="{{
                    'fffRegister.register.placeholder.lastName' | cxTranslate
                  }}"
                  formControlName="lastName"
                />
                <cx-form-errors
                  [control]="$any(registerForm).get('lastName')"
                ></cx-form-errors>
              </label>
            </div>
          </div>

          <div class="col-12 form-group">
            <label>
              <span class="">{{
                'fffRegister.register.emailAddress' | cxTranslate
              }}</span>
              <input
                id="email"
                aria-required="true"
                class="form-control form-control-lg"
                type="email"
                name="email"
                placeholder="{{
                  'fffRegister.register.placeholder.emailAddress' | cxTranslate
                }}"
                formControlName="email"
              />
              <cx-form-errors
                [control]="$any(registerForm).get('email')"
              ></cx-form-errors>
            </label>
          </div>

          <div class="col-12 form-group">
            <label>
              <span class="">{{
                'fffRegister.register.phone' | cxTranslate
              }}</span>
              <input
                id="phoneNumber"
                aria-required="true"
                class="form-control form-control-lg"
                type="text"
                name="phoneNumber"
                placeholder="{{
                  'fffRegister.register.placeholder.phone' | cxTranslate
                }}"
                formControlName="phoneNumber"
              />
              <cx-form-errors
                [control]="$any(registerForm).get('phoneNumber')"
              ></cx-form-errors>
            </label>
          </div>

          <div class="col-12 px-0 d-flex flex-wrap">
            <div class="col-sm-12 col-md-6 form-group">
              <span class="prefix-icon" (click)="enablePassword()">
                <cx-icon
                  *ngIf="!showNewPassword"
                  class="cx-icon icon-md pe-auto"
                  [type]="filledIconTypes.ICON_EYE_SLASH_FILL"
                >
                </cx-icon>
                <cx-icon
                  *ngIf="showNewPassword"
                  class="cx-icon icon-md pe-auto"
                  [type]="filledIconTypes.ICON_EYE_FILL"
                >
                </cx-icon>
              </span>

              <label>
                <span>{{
                  'fffRegister.register.password.label' | cxTranslate
                }}</span>
                <input
                  id="password"
                  aria-required="true"
                  class="form-control form-control-lg"
                  [type]="showNewPassword ? 'text' : 'password'"
                  name="password"
                  placeholder="{{
                    'fffRegister.register.password.placeholder' | cxTranslate
                  }}"
                  formControlName="password"
                />
                <cx-form-errors
                  [control]="$any(registerForm).get('password')"
                ></cx-form-errors>
              </label>
            </div>
            <div class="col-sm-12 col-md-6 form-group">
              <span class="prefix-icon" (click)="enablePasswordConfirmation()">
                <cx-icon
                  *ngIf="!showNewPasswordConfirmation"
                  class="cx-icon icon-md pe-auto"
                  [type]="filledIconTypes.ICON_EYE_SLASH_FILL"
                >
                </cx-icon>
                <cx-icon
                  *ngIf="showNewPasswordConfirmation"
                  class="cx-icon icon-md pe-auto"
                  [type]="filledIconTypes.ICON_EYE_FILL"
                >
                </cx-icon>
              </span>

              <label>
                <span>{{
                  'fffRegister.register.confirmPassword.label' | cxTranslate
                }}</span>
                <input
                  id="confirmPassword"
                  aria-required="true"
                  class="form-control form-control-lg"
                  [type]="showNewPasswordConfirmation ? 'text' : 'password'"
                  name="confirmPassword"
                  placeholder="{{
                    'fffRegister.register.confirmPassword.placeholder'
                      | cxTranslate
                  }}"
                  formControlName="passwordConf"
                />
                <cx-form-errors
                  [control]="$any(registerForm).get('passwordConf')"
                ></cx-form-errors>
              </label>
            </div>
          </div>

          <div class="col-12 form-group">
            <label
              class="password-requirements password-rule"
              [innerHTML]="'fffRegister.reset.passwordRules' | cxTranslate"
            ></label>
          </div>
          <cx-form-errors
            class="pt-4 pl-3"
            [control]="$any(registerForm)"
          ></cx-form-errors>
          <div class="form-actions col-12 py-0">
            <button
              type="submit"
              class="btn btn-primary btn-md"
              [disabled]="
                registerForm.disabled ||
                registerForm.pristine ||
                registerForm.invalid
              "
            >
              {{ 'fffRegister.createAccount' | cxTranslate }}
            </button>
          </div>

          <div class="col-12 text-center login">
            <span>{{ 'fffRegister.alreadyRegistered' | cxTranslate }}</span>
            <a
              class="cursor-pointer text-underline"
              [routerLink]="{ cxRoute: 'login' } | cxUrl"
              >{{ 'fffRegister.loginHere' | cxTranslate }}</a
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #loading>
  <div
    class="row register-container col-xs-12 col-md-8 d-flex justify-content-center align-items-center m-auto px-0"
  >
    <fff-drawer-loader></fff-drawer-loader>
  </div>
</ng-template>
