import { Component, Input } from '@angular/core';
import { FFFCommonFunctions } from '@app/shared/fff-common-functions';
import {
  CartItemComponent,
  CartItemContextSource,
} from '@spartacus/cart/base/components';
import { CartItemContext } from '@spartacus/cart/base/root';
import { MediaContainer } from '@spartacus/storefront';
import { getProductImage } from 'src/assets/utils/fff-product-utils';

@Component({
  selector: 'fff-entry-simple',
  templateUrl: './fff-entry-simple.component.html',
  providers: [
    CartItemContextSource,
    { provide: CartItemContext, useExisting: CartItemContextSource },
  ],
})
export class FffEntrySimpleComponent extends CartItemComponent {
  @Input() accountUid: string | undefined;
  @Input() item: any;
  @Input() b2BUnitSplitBiller: boolean | undefined = false;
  @Input() customerSplitBiller: boolean | undefined = false;
  @Input()
  isUnavailableItem!: boolean;
  parseDataI = FFFCommonFunctions.parseDate;
  getData(imagesProduct: any) {
    return getProductImage(this.item.product?.images) as MediaContainer;
  }

  getAccountTypeLabel(accountType: string): string {
    return `${this.accountUid}${accountType}`;
  }
}
