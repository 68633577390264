import { Component, Input } from '@angular/core';
import { FffInvoicePaymentService } from '@app/fff-enterprise/fff-common-services/fff-invoice-payment.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';

@Component({
  selector: 'fff-invoice-payment-confirmation-entries',
  templateUrl: './fff-invoice-payment-confirmation-entries.component.html',
})
export class FffInvoicePaymentConfirmationEntriesComponent {
  @Input() invoices: any;
  successfulInvoiceData!: any[];
  failureInvoiceData!: any[];
  successfulInvoices!: any[];
  failureInvoices!: any[];
  totalAmountForSuccess: number = 0;
  totalAmountForFailure: number = 0;
  totalDiscoutForSuccessInvoices: number = 0;
  totalDiscountForFailureInvoices: number = 0;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  constructor(private fffInvoicePaymentService: FffInvoicePaymentService) {}

  ngOnInit() {
    this.loadInvoiceResponse();
  }

  loadInvoiceResponse(): void {
    // Fetch successful invoices
    this.fffInvoicePaymentService
      .getSuccessfulInvoices()
      .subscribe((successInvoices: any[]) => {
        this.successfulInvoiceData = successInvoices ?? [];
        this.updateInvoices(true);
        this.fffInvoicePaymentService.setTotalSuccessTransactions(
          this.successfulInvoices.length
        );
      });

    // Fetch failure invoices
    this.fffInvoicePaymentService
      .getFailureInvoices()
      .subscribe((failureInvoices: any[]) => {
        this.failureInvoiceData = failureInvoices ?? [];
        this.updateInvoices(false);
        this.fffInvoicePaymentService.setTotalFailureTransactions(
          this.failureInvoices.length
        );
      });
  }

  private updateInvoices(isFromSuccess: boolean): void {
    if (this.invoices && this.invoices.length > 0) {
      if (isFromSuccess) {
        // Handle successful invoices
        if (
          this.successfulInvoiceData &&
          this.successfulInvoiceData.length > 0
        ) {
          const successfulInvoiceNumbersSet = new Set(
            this.successfulInvoiceData.map(i => i.invoiceNumber)
          );
          const successfulInvoices = this.invoices
            .filter((invoice: any) =>
              successfulInvoiceNumbersSet.has(invoice.invoiceNumber)
            )
            .map((invoice: any) => {
              // Find the corresponding transaction ID
              const transaction = this.successfulInvoiceData.find(
                t => t.invoiceNumber === invoice.invoiceNumber
              );
              return {
                ...invoice,
                transactionId: transaction ? transaction.transactionId : null,
                amount: transaction ? transaction.amount : null,
                surchargeAmt: transaction ? transaction.surchargeAmount : null,
              };
            });
          this.totalDiscoutForSuccessInvoices = successfulInvoices.reduce(
            (sum: any, invoice: any) => {
              let discount = 0;
              const dueDate = new Date(invoice.promptPayDueDate);
              const currentDate = new Date();
              if (
                dueDate &&
                currentDate <= dueDate &&
                invoice.promptPayDiscount
              ) {
                discount = invoice.promptPayDiscount;
              }
              return sum + discount;
            },
            0
          );
          this.successfulInvoices =
            this.mergeInvoicesByTransaction(successfulInvoices);

          this.totalAmountForSuccess = this.successfulInvoices
            .map(v => +v.amount || 0)
            .reduce((prev, curr) => prev + curr, 0);
        } else {
          this.successfulInvoices = [];
          this.totalAmountForSuccess = 0;
          this.totalDiscoutForSuccessInvoices = 0;
        }
      } else {
        // Handle failure invoices
        if (this.failureInvoiceData && this.failureInvoiceData.length > 0) {
          const failureInvoiceNumbersSet = new Set(
            this.failureInvoiceData.map(i => i.invoiceNumber)
          );
          this.failureInvoices = this.invoices
            .filter((invoice: any) =>
              failureInvoiceNumbersSet.has(invoice.invoiceNumber)
            )
            .map((invoice: any) => {
              // Find the corresponding transaction ID
              const transaction = this.failureInvoiceData.find(
                t => t.invoiceNumber === invoice.invoiceNumber
              );
              return {
                ...invoice,
              };
            });
          this.totalDiscountForFailureInvoices = this.failureInvoices.reduce(
            (sum, invoice) => {
              let discount = 0;
              const dueDate = new Date(invoice.promptPayDueDate);
              const currentDate = new Date();
              if (
                dueDate &&
                currentDate <= dueDate &&
                invoice.promptPayDiscount
              ) {
                discount = invoice.promptPayDiscount;
              }
              return sum + discount;
            },
            0
          );
          this.totalAmountForFailure = this.failureInvoices
            .map(v => {
              const surchargeAmt = Number(v.surchargeAmt) || 0;
              const grandTotal = Number(v.grandTotal) || 0;
              return surchargeAmt + grandTotal;
            })
            .reduce((prev, curr) => prev + curr, 0);
        } else {
          this.failureInvoices = [];
          this.totalAmountForFailure = 0;
          this.totalDiscountForFailureInvoices = 0;
        }
      }
    } else {
      // Handle case where there are no invoices
      this.successfulInvoices = [];
      this.failureInvoices = [];
      this.totalAmountForSuccess = 0;
      this.totalAmountForFailure = 0;
      this.totalDiscoutForSuccessInvoices = 0;
      this.totalDiscountForFailureInvoices = 0;
    }
  }
  //This is to merge Invoiecs based on transaction ID
  mergeInvoicesByTransaction(transactions: any) {
    const mergedInvoices: any = {};

    transactions.forEach((transaction: any) => {
      const { transactionId } = transaction;

      // Skip transactions without a valid transactionId
      if (!transactionId) {
        return; // Do nothing for items without a transactionId
      }

      // Check if the transactionId already exists in the mergedTransactions
      if (!mergedInvoices[transactionId]) {
        mergedInvoices[transactionId] = { ...transaction };
      } else {
        // Update invoices and approvalCode
        mergedInvoices[
          transactionId
        ].invoiceNumber += `, ${transaction.invoiceNumber}`;
      }
    });

    // Collect unchanged transactions (those without a transactionId)
    const unchangedTransactions = transactions.filter(
      (transaction: any) => !transaction.transactionId
    );

    // Return merged transactions and unchanged transactions
    return [...Object.values(mergedInvoices), ...unchangedTransactions];
  }

  calculateFailureTransactionAmnt(
    surchargeAmt: string,
    grandTotal: string
  ): number {
    return (Number(surchargeAmt) || 0) + (Number(grandTotal) || 0);
  }
}
