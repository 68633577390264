export const fffInvoiceHistory = {
  fffInvoiceHistory: {
    title: 'Invoices',
    filter: 'Filter',
    invoice: 'Invoice #',
    invoiceDate: 'Invoice Date',
    invoiceDueDate: 'Due Date',
    status: 'Status',
    po: 'PO',
    amount: 'Amount',
    eligibleDiscount: 'Eligible Discount',
    netAmount: 'Net Amount',
    manufacturer: 'Manufacturer',
    exportCsv: 'Export CSV',
    payableByCreditCard: 'Payable by Credit Card',
    actions: {
      actions: 'Actions',
      view: 'View',
      payByCreditCard: 'Pay by Credit Card',
      payInvoice: 'Pay Invoice',
      payInvoices: 'Pay Invoices',
      remove: 'Remove',
    },
    transactionNumber: 'Transaction Number',
    processingFeeInfoText:
      '3% credit card processing fee paid by manufacturer.',
    paymentOptions: 'Payment Options',
    surchargeAmount: 'Surcharge Amount',
    transactionAmount: 'Transaction Amount',
    invoices: 'Invoices',
  },
};

export const fffInvoiceFilterHistory = {
  fffInvoiceFilterHistory: {
    apply: 'Apply',
    title: 'Filter Invoices',
    poPlaceHolder: 'Search By P.O or Invoice #',
    date: 'Date',
    dateFrom: 'From',
    dateTo: 'To',
    status: 'Status',
    open: 'Open',
    paid: 'Paid',
    manufacturers: 'Manufacturers',
    additionalFilters: 'Additional Filters',
    ccPaymentEligible: 'Credit Card Eligible',
  },
};

export const fffInvoiceHistoryDetail = {
  fffInvoiceHistoryDetail: {
    accountName: 'Account Name',
    accountNumber: 'Account Number',
    orderDate: 'Order Date',
    contactName: 'Contact Name',
    status: 'Status',
    paymentTerms: 'Payment Terms',
    invoiceDate: 'Invoice Date',
    po: 'P.O #',
    orderNumber: 'Order Number #',
    actions: {
      downloadPdf: 'Download PDF',
    },
  },
};

export const fffInvoicePayment = {
  fffInvoicePayment: {
    payInvoiceByCreditCard: 'Pay Invoice by Credit Card',

    accountInformation: 'Account Information',
    accountName: 'Account Name',
    accountNumber: 'Account Number',

    contactInformation: 'Contact Information',
    contactName: 'Contact Name',

    summary: 'Summary',
    total: 'Total',
    noOfEntries: 'Number of Invoices',

    paymentMethod: 'Payment Method',

    reviewPayment: 'Review Payment',
    cancel: 'Cancel',
    payInvoices: 'Pay Invoices',
    payInvoice: 'Pay Invoice',
    selectCreditCard: 'Select Credit Card',
    default: 'DEFAULT',
    setDefault: 'SET DEFAULT',
    saved: 'SAVED',
    oneTimeUse: 'ONE TIME USE',
    cardType: 'Card Type',
    cartNumber: 'Card Number',
    expirationDate: 'Expiration Date',
    useNewCard: 'Use New Card',
    invoices: 'Invoices',

    confirmationView: {
      title: 'Thank you for your payment!',
      summary:
        "We've sent you an email with the payment confirmation details in this page.",
      printConfirmation: 'Print Confirmation',
      confirmation: 'Confirmation',
      paymentDate: 'Payment Date, {{ paymentDate }}',
      confirmationText: 'Your payment confirmation number is ',
      confirmationTextForMultiTransaction:
        'Your payment confirmation numbers are ',
    },

    paymentFailed: {
      summary:
        "We apologize, your payment didn't go through, please try again with a different credit card.",
      useDifferentCard: 'Use Different Credit Card',
    },

    addNewCard: {
      userNewCreditCard: 'Use New Credit Card',
      billingAddress: 'Billing Address',
      creditCardInformation: 'Credit Card Information',
      cardExistsMessage:
        'Card already exists, continue to add new card or go back/cancel?',
      invalidCardMessage:
        'Please enter a valid credit card number (debit cards are not eligible for payment).',
      fields: {
        firstName: {
          label: 'First Name *',
        },
        lastName: {
          label: 'Last Name *',
        },
        line1: {
          label: 'Address Line 1 *',
        },
        line2: {
          label: 'Address Line 2',
        },
        town: {
          label: 'City *',
        },
        region: {
          label: 'State *',
        },
        postalCode: {
          label: 'Zip Code *',
        },
        savedPayment: {
          label: 'Save card to wallet for future use *',
        },
        cardToken: {
          label: 'Card Number *',
        },
        expirationDate: {
          label: 'Expiration Date *',
        },
      },
      creditCardAddedConfirmation:
        'Your new credit card has been saved to your wallet for future use.',
    },
    approved: 'APPROVED',
    declined: 'DECLINED',
    grandTotal: 'Grand Total',
    viewTotals: 'View Totals',
    totalTooltipContent:
      'Grand Total includes 3% credit card processing fee of {{processingFee}} for {{noOfInvoicesWithProcessingfee}} invoices.',
    totalTooltipContentForSingleInvoice:
      'Grand Total includes 3% credit card processing fee of {{processingFee}} for {{noOfInvoicesWithProcessingfee}} invoice.',
    processingFeePaidInvoices:
      'Invoices - Manufacturer Paid Credit Card Processing Fee',
    processingFeeNotPaidInvoices:
      'Invoices - 3 % Credit Card Payment Processing Fee',
    subTotal: 'SubTotal',
    invoiceSubTotal: 'Amount Subtotal',
    discountSubTotal: 'Discount Subtotal',
    eligibleDiscountSubTotal: 'Eligibe Discount Subtotal',
    processingFeeLabel: 'Estimated Credit Card Processing Fee 3%',
    grandTotalOfAllInvoices: 'Grand Total - All Invoices ',
    reviewCreditCardPayment: 'Review Credit Card Payment',
    acceptProcessingFeeCondition:
      'I agree to a credit card processing fee of 3%',
    successfulTransactions: 'Invoices - Successful Transactions',
    failedTransactions: 'Invoices - Failed Transactions',
    consentConfirmation: 'You agreed to a credit card processing fee of 3%',
    totalPaidAmount: 'Total Paid Amount',
    numberOfSuccess: 'Number of Successful Transactions',
    numberOfFailure: 'Number of Failed Transactions',
    totalNetAmount: 'Total Net Amount',
  },
};
