import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FffDisplayShippingBannerService } from '@shared/services/fff-display-shipping-banner.service';
import { AuthService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';

@Component({
  selector: 'fff-order-cutoff-time',
  templateUrl: './order-cutoff-time.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderCutoffTimeComponent implements OnInit, OnDestroy {
  private subscription: Subject<void> = new Subject();
  timeLeft$: Observable<any> | undefined;

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  constructor(
    private authService: AuthService,
    private component: CmsComponentData<any>,
    private fffDisplayShippingBannerService: FffDisplayShippingBannerService
  ) {}

  ngOnInit() {
    this.fffDisplayShippingBannerService
      .getShippingBannerIsDisplayed()
      .pipe(take(1))
      .subscribe(v => {
        this.getLoggedinUserCutOffTime();
      });
  }

  getLoggedinUserCutOffTime() {
    this.timeLeft$ =
      this.fffDisplayShippingBannerService.getCountdownInterval();
    this.timeLeft$.pipe(takeUntil(this.subscription));
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
  }
}
