import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {
  AuthService,
  BaseSiteService,
  PageType,
  RoutingService,
  WindowRef,
} from '@spartacus/core';
import { OutletPosition, PageLayoutService } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { FffCommunicationService } from './fff-enterprise/fff-common-services/fff-communication.service';
import { FffSiteContextService } from './fff-enterprise/fff-common-services/fff-site-context.service';
import { FffTrustCookieService } from './fff-enterprise/fff-one-trust-cookie/fff-trust-cookie.service';
import { FffMarketoService } from './fff-enterprise/fff-service-third-party/fff-marketo.service';
import { FffRingCentralChatBotService } from './fff-enterprise/fff-service-third-party/fff-ring-central-chat-bot.service';
import { FFFCart } from './models/fff-cart-data.model';
import { FffProfile } from './models/fff-profile.model';
import { FffUserAccountService } from './shared/services/fff-user-account.service';
import { FffGtmEventService } from './spartacus/features/tracking/gmt/event';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewChecked {
  private unsubscribe$: Subject<void> = new Subject<void>();
  outletPosition = OutletPosition;
  isSimplifiedHeader = false;
  prevTemplate: string = '';
  prevCategory: string = '';
  prevPage: string = '';

  constructor(
    private auth: AuthService,
    private windowRef: WindowRef,
    private marketoService: FffMarketoService,
    private fffCommunicationService: FffCommunicationService,
    private cookieService: FffTrustCookieService,
    private chatBotService: FffRingCentralChatBotService,
    private siteContextService: FffSiteContextService,
    private gtmService: FffGtmEventService,
    protected routingService: RoutingService,
    private cartService: ActiveCartFacade,
    private fffUserAccountService: FffUserAccountService,
    private pageLayoutService: PageLayoutService,
    private baseSiteService: BaseSiteService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getProfile();

    this.siteContextService.isFocusPage$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => (this.isSimplifiedHeader = value));

    this.handleLayoutClasses();

    // Browser back handling
    this.router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((event: any) => {
        if (event?.restoredState) {
          // Close any active dialogs
          this.modalService.dismissAll();
        }
      });
  }

  private getProfile() {
    this.fffUserAccountService.loadUserState();
    this.auth
      .isUserLoggedIn()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userLoggedIn => {
        if (userLoggedIn) {
          this.fffUserAccountService
            .getProfile()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((profile: FffProfile | undefined) => {
              this.getConfigDetails(profile);
              this.fffCommunicationService
                .loadNotifications()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((notifications: any) => {
                  this.fffCommunicationService.setNotifications(notifications);
                });
              this.fffCommunicationService.setApplicationProperties();
            });
        }
      });
  }

  private getConfigDetails(profile: FffProfile | undefined) {
    this.fffCommunicationService
      .getConfigDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(configDetails => {
        this.initiateThirdPartyServices(configDetails, profile);
      });
  }

  initiateThirdPartyServices(
    configDetails: any,
    profile: FffProfile | undefined
  ) {
    //this.googleAnalyticsService.init(data);
    this.marketoService.init(configDetails);
    this.chatBotService.init(configDetails, profile);
    this.gtmService.init(profile);
  }

  ngAfterViewChecked() {
    // One Trust Cookie
    if (
      this.windowRef.document.getElementsByClassName('pointer-link').length ===
      0
    ) {
      this.cookieService.attachFunction();
    }
  }

  goToCartPage(): void {
    this.cartService
      .getActive()
      .pipe(
        filter(cart => !!cart?.code),
        take(1)
      )
      .subscribe(
        (cart: FFFCart) => {
          this.fffCommunicationService.resetShippingCosts(cart.code).subscribe(
            () => this.redirectToCart(!!cart?.prebookCart),
            () => this.redirectToCart(!!cart?.prebookCart)
          );
        },
        () => this.redirectToCart()
      );
  }

  private redirectToCart(prebook: boolean = false): void {
    if (prebook) {
      this.routingService.go('/my-cart', { queryParams: { prebook: true } });
    } else {
      this.routingService.go('/cart');
    }
  }

  private handleLayoutClasses() {
    this.pageLayoutService.templateName$.subscribe(val => {
      if (this.prevTemplate) {
        this.windowRef?.document?.body?.classList?.toggle(this.prevTemplate);
      }

      const bodyTemplateClass = `body-${val}`;
      this.windowRef?.document?.body?.classList?.add(bodyTemplateClass);
      this.prevTemplate = bodyTemplateClass;
    });

    this.pageLayoutService.page$.subscribe(context => {
      if (this.prevPage) {
        this.windowRef?.document?.body?.classList?.toggle(this.prevPage);
      }

      const bodyClass = `body-${context.pageId}`;
      this.windowRef?.document?.body?.classList?.add(bodyClass);
      this.prevPage = bodyClass;
    });

    this.routingService.getRouterState().subscribe(({ state }) => {
      if (!state?.context?.id) {
        return;
      }

      const isVaccineCategory =
        (state.context.type === PageType.CATEGORY_PAGE ||
          state.context.id?.includes('/categories')) &&
        state.queryParams['category']?.startsWith('vaccines');
      let category = isVaccineCategory ? 'vaccines' : 'none';

      if (this.prevCategory) {
        this.windowRef?.document?.body?.classList?.toggle(this.prevCategory);
      }

      const bodyClass = `body-category-${category}`;
      this.windowRef?.document?.body?.classList?.add(bodyClass);
      this.prevCategory = bodyClass;
    });

    this.cartService
      .getActive()
      .pipe(filter(cart => !!cart?.code))
      .subscribe((cart: any) => {
        this.windowRef?.document?.body?.classList?.toggle(
          'body-prebook-cart-active',
          !!cart?.prebookCart
        );
      });

    this.baseSiteService
      .getActive()
      .pipe(take(1))
      .subscribe(site => {
        this.windowRef?.document?.body?.classList?.add(`body-site-${site}`);
      });
  }
}
