<ng-container *ngIf="!!invoicesTableStateWithoutProcessingFee?.data?.length">
  <h6 class="fff-invoice-payment-content-block__title mt-5 fw-bold">
    {{ 'fffInvoicePayment.processingFeePaidInvoices' | cxTranslate }}
  </h6>
  <fff-table
    [hasPaginator]="false"
    [data]="invoicesTableStateWithoutProcessingFee"
    [fields]="fields"
    [hasTemplate]="true"
    [mobileSize]="mobileSize"
    [itemTemplate]="invoiceItem"
    [columnsGrid]="columnsGrid"
    [customClass]="'invoice-payment-table'"
    [size]="1000"
  >
  </fff-table>
  <div
    class="fff-table-footer d-flex"
    id="subTotalOfInvoicesWithoutProcessingFee"
  >
    <div
      class="fff-table-footer-cell ml-auto body-text-demi subtotal rowWithoutFee"
    >
      <span class="mr-auto">{{
        'fffInvoicePayment.invoiceSubTotal' | cxTranslate
      }}</span>
      <span>{{ totalAmountForInvoicesWithoutProcessingFee | currency }}</span>
    </div>
  </div>
  <div class="fff-table-footer d-flex">
    <div
      class="fff-table-footer-cell ml-auto body-text-demi subtotal rowWithoutFee"
    >
      <span class="mr-auto">{{
        'fffInvoicePayment.eligibleDiscountSubTotal' | cxTranslate
      }}</span>
      <span
        >({{ totalDiscountForInvoicesWithoutProcessingFee | currency }})</span
      >
    </div>
  </div>
  <div class="fff-table-footer d-flex">
    <div
      class="fff-table-footer-cell rowWithoutFee ml-auto body-text-demi subtotal subTotalOfInvoicesWithoutProcessingFeeAdded"
    >
      <span class="mr-auto">{{
        'fffInvoicePayment.totalNetAmount' | cxTranslate
      }}</span>
      <span>{{
        totalAmountForInvoicesWithoutProcessingFee -
          totalDiscountForInvoicesWithoutProcessingFee | currency
      }}</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!!invoicesTableStateWithProcessingFee?.data?.length">
  <h6 class="fff-invoice-payment-content-block__title mt-3 fw-bold">
    {{ 'fffInvoicePayment.processingFeeNotPaidInvoices' | cxTranslate }}
  </h6>
  <fff-table
    [hasPaginator]="false"
    [data]="invoicesTableStateWithProcessingFee"
    [fields]="fields"
    [hasTemplate]="true"
    [mobileSize]="mobileSize"
    [itemTemplate]="invoiceItem"
    [columnsGrid]="columnsGrid"
    [customClass]="'invoice-payment-table'"
    [size]="1000"
  >
  </fff-table>
  <div class="fff-table-footer d-flex" id="subTotalOfInvoicesWithProcessingFee">
    <div class="fff-table-footer-cell ml-auto body-text-demi subtotal">
      <span class="mr-auto">{{
        'fffInvoicePayment.invoiceSubTotal' | cxTranslate
      }}</span>
      <span>{{ totalAmountForInvoicesWithProcessingFee | currency }}</span>
    </div>
  </div>
  <div
    class="fff-table-footer d-flex"
    id="discountTotalOfInvoicesWithProcessingFee"
    *ngIf="totalDiscountForInvoicesWithProcessingFee > 0"
  >
    <div class="fff-table-footer-cell ml-auto body-text-demi subtotal">
      <span class="mr-auto">{{
        'fffInvoicePayment.eligibleDiscountSubTotal' | cxTranslate
      }}</span>
      <span>({{ totalDiscountForInvoicesWithProcessingFee | currency }})</span>
    </div>
  </div>
  <div class="fff-table-footer d-flex" id="processingFee">
    <div
      class="fff-table-footer-cell ml-auto body-text-demi processingFeeLabel"
    >
      <span class="mr-auto">{{
        'fffInvoicePayment.processingFeeLabel' | cxTranslate
      }}</span>
      <span>{{ processingFee | currency }}</span>
    </div>
  </div>
  <div
    class="fff-table-footer d-flex"
    id="subTotalOfInvoicesWithProcessingFeeAdded"
  >
    <div
      class="fff-table-footer-cell ml-auto body-text-demi subTotalOfInvoicesWithProcessingFeeAdded"
    >
      <span class="mr-auto">{{
        'fffInvoicePayment.totalNetAmount' | cxTranslate
      }}</span>
      <span>{{
        totalAmountForInvoicesWithProcessingFee +
          processingFee -
          totalDiscountForInvoicesWithProcessingFee | currency
      }}</span>
    </div>
  </div>
</ng-container>

<div class="fff-table-footer d-flex" id="totalSection">
  <div class="fff-table-footer-cell ml-auto body-text-demi grandTotal">
    <span class="mr-auto">{{
      'fffInvoicePayment.grandTotalOfAllInvoices' | cxTranslate
    }}</span>
    <span>{{
      totalAmountForInvoicesWithProcessingFee +
        totalAmountForInvoicesWithoutProcessingFee +
        processingFee -
        totalDiscountForInvoicesWithProcessingFee -
        totalDiscountForInvoicesWithoutProcessingFee | currency
    }}</span>
  </div>
</div>

<ng-template #invoiceItem let-item="item">
  <div
    class="invoice-history-item invoicePaymentTableData td"
    [style.grid-template-columns]="columnsGrid.desktop"
  >
    <div class="px-0 col-6 col-lg-12 order-0">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.invoice' | cxTranslate }}
      </div>
      <div>{{ item.invoiceNumber }}</div>
    </div>

    <div class="px-0 col-12 order-1">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.invoiceDate' | cxTranslate }}
      </div>
      <div>{{ item.invoiceDate | date : 'MM/dd/yyyy' }}</div>
    </div>

    <div class="px-0 col-6 col-lg-12 order-2">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.invoiceDueDate' | cxTranslate }}
      </div>
      <div>{{ item.dueDate | date : 'MM/dd/yyyy' }}</div>
    </div>

    <div class="px-0 col-6 col-lg-12 order-4">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.po' | cxTranslate }}
      </div>
      <div>{{ item.poNumber }}</div>
    </div>

    <div class="px-0 col-6 col-lg-12 order-4">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.manufacturer' | cxTranslate }}
      </div>
      <div>{{ item.primaryManufacturer }}</div>
    </div>

    <div class="px-0 col-12 d-none d-lg-block order-5" *ngIf="isFirstStep">
      <div>
        <div class="fff-orders-action-item">
          <div class="d-inline-flex flex-wrap cursor-pointer">
            <span
              class="action-label"
              (click)="onRemoveInvoice(item.invoiceNumber)"
            >
              {{ 'fffInvoiceHistory.actions.remove' | cxTranslate }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-12 d-flex d-lg-none flex-wrap py-3 order-6"
      *ngIf="isFirstStep"
    >
      <div>
        <div class="fff-orders-action-item">
          <button
            class="col-12 btn btn-tertiary btn-xs"
            (click)="onRemoveInvoice(item.invoiceNumber)"
          >
            {{ 'fffInvoiceHistory.actions.remove' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>

    <div class="px-0 col-6 col-lg-12 order-7">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.amount' | cxTranslate }}
      </div>
      <div>{{ item.grandTotal | currency }}</div>
    </div>
    <div class="px-0 col-6 col-lg-12 order-8">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.eligibleDiscount' | cxTranslate }}
      </div>
      <div>
        {{
          isBeforeDueDate(item.promptPayDueDate) && item.promptPayDiscount
            ? '(' + (item.promptPayDiscount | currency) + ')'
            : '-'
        }}
      </div>
    </div>
    <div class="px-0 col-6 col-lg-12 order-9">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.netAmount' | cxTranslate }}
      </div>
      <div>
        {{
          isBeforeDueDate(item.promptPayDueDate) && item.promptPayDiscount
            ? (item.grandTotal - item.promptPayDiscount | currency)
            : (item.grandTotal | currency)
        }}
      </div>
    </div>
  </div>
</ng-template>
