<div class="userAccounts">
  <div class="changeAccount">
    <h3 *ngIf="profile.user">
      {{ 'fffAccount.fffProfileManagement.hi' | cxTranslate }}
      {{ profile.user.firstName }} # {{ profile.user.orgUnit.uid }}
    </h3>
    <button
      class="changeAccountBtn btn btn-secondary"
      (click)="openChangeAccountDrawer()"
      *ngIf="!(profile.accounts && profile.accounts.length === 1)"
    >
      {{ 'fffAccount.fffProfileManagement.changeAccount' | cxTranslate }}
      <cx-icon
        class="cx-icon"
        id="back-icon"
        [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"
      ></cx-icon>
    </button>
  </div>

  <div
    *ngIf="profile.user && profile.selected && profile.selected.uid"
    class="selectAccount justify-content-between"
  >
    <div class="accounts">
      <div class="associatedAccounts body-tex">
        {{ 'fffAccount.fffProfileManagement.associatedAccounts' | cxTranslate }}
      </div>
      <div
        class="d-flex"
        *ngIf="associatedAccounts$ | async as associatedAccounts"
      >
        <div
          *ngFor="let unit of associatedAccounts.b2bunits"
          class="changeAccountItem"
        >
          <span *ngIf="profile.selected.uid !== unit.uid; else selected">
            #{{ unit.uid }}
          </span>

          <ng-template #selected>
            <span class="selected">#{{ unit.uid }}</span>
          </ng-template>
        </div>
      </div>
    </div>

    <ng-container>
      <div
        class="body-text align-items-center d-flex"
        *ngIf="profile.selected.splitBiller"
      >
        <div class="splitBilling body-tex">
          {{ 'fffAccount.fffProfileManagement.splitBilling' | cxTranslate }}
        </div>
        <button
          [disabled]="loading || billerSelected"
          class="billing-btn btnAuto body-tex"
          [ngClass]="{ selected: billerSelected }"
          (click)="
            changeSplitBiller(true, profile.user ? profile.user.uid : '')
          "
        >
          {{ 'fffAccount.fffProfileManagement.splitBillingAuto' | cxTranslate }}
        </button>
        <button
          [disabled]="loading || !billerSelected"
          class="billing-btn btnManual"
          [ngClass]="{ selected: !billerSelected }"
          (click)="
            changeSplitBiller(false, profile.user ? profile.user.uid : '')
          "
        >
          {{
            'fffAccount.fffProfileManagement.splitBillingManual' | cxTranslate
          }}
        </button>
      </div>
    </ng-container>
  </div>
</div>
