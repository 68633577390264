import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { B2BUnitState } from '@app/reducers';
import { FFFCommonFunctions } from '@app/shared/fff-common-functions';
import { FffProductsService } from '@enterprise/fff-common-services/fff-products.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'fff-product-badge-info',
  templateUrl: './fff-product-badge-info.component.html',
})
export class FffProductBadgeInfoComponent implements OnInit, OnDestroy {
  @Input()
  product!: any;
  @Input() pricesData: any;
  @Input() isSplitBillerAccount: boolean = false;

  hasProvideGXCategory: boolean = false;
  shouldDisplayContractBadge: boolean = false;
  b2bunit: B2BUnitState['B2BUnit'] = {} as B2BUnitState['B2BUnit'];
  subs = new Subscription();

  get showManualOnlyBadge(): boolean {
    return (
      FFFCommonFunctions.isAutoSplitBilling(
        this.b2bunit?.currentB2BUnit?.splitBiller || false,
        this.isSplitBillerAccount,
        this.product
      ) && this.pricesData?.prices?.length < 3
    );
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private fffProductsService: FffProductsService,
    private storeB2b: Store<B2BUnitState>
  ) {}

  ngOnInit(): void {
    this.fffProductsService
      .getIsContractProduct()
      .pipe(
        filter(value => value[0] === this.product.code),
        take(1),
        map(value => value[1])
      )
      .subscribe(value => {
        this.shouldDisplayContractBadge = value;
        this.cdr.markForCheck();
      });
    this.product.categories.find((category: any) => {
      if (category.code === 'PROVIDEGX') this.hasProvideGXCategory = true;
    });

    // this.subs.add(
    //   this.storeCustomer.select((state: any) => {
    //     return state.Customer;
    //   }).subscribe(customerData => {
    //     if (customerData && customerData?.splitBillerAuto) {
    //       this.isSplitBillerAccount = !!customerData?.splitBillerAuto?.user?.splitBiller;
    //       this.cdr.markForCheck();
    //     }
    //   })
    // );

    this.subs.add(
      this.storeB2b
        .select(state => state.B2BUnit)
        .subscribe(b2bUnitId => {
          if (b2bUnitId && b2bUnitId.currentB2BUnit) {
            this.b2bunit = b2bUnitId;
            this.isSplitBillerAccount =
              !!b2bUnitId.currentB2BUnit?.autoSplitBillerActive;
            this.cdr.markForCheck();
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
