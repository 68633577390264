import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FffAllocationService } from '@app/fff-enterprise/fff-common-services/fff-allocation.service';
import { FFFActiveCartService } from '@app/fff-enterprise/fff-custom-cart/fff-active-cart-service';
import { FFFOrderEntry } from '@app/models/fff-cart-data.model';
import { FFFCommonFunctions } from '@app/shared/fff-common-functions';
import { BASE_URL_KEYS } from '@config/content/constants';
import { Store } from '@ngrx/store';
import { OrderEntry } from '@spartacus/cart/base/root';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { ADD_PRODUCT_FOCUSED_CART } from 'src/app/reducers';
import { ProductFocusState } from '../../../reducers';

@Component({
  selector: 'fff-cart-split-billing',
  templateUrl: './fff-cart-split-billing.component.html',
})
export class FffCartSplitBillingComponent implements OnInit {
  @Input() e!: any;
  @Input() user: any;
  @Input() isSplitBillerAccount: boolean = false;
  @Input() allocation: any;
  @Input() priceData: any;
  price: any = null;
  entry!: any;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  BASE_URL_KEYS = BASE_URL_KEYS;
  cart: any = [];
  tooltipMessage = '';
  checkMultiples: boolean = false;
  min = 1;
  basicUOM = '';
  mdu: number | undefined;
  isInvalidLine = false;
  isPrebookCart!: boolean;
  showCartUpdatedIcon!: boolean;
  isOfVaccineTypeI = FFFCommonFunctions.isOfVaccineType;

  constructor(
    private store: Store<ProductFocusState>,
    private changeDetectorRef: ChangeDetectorRef,
    private fffActiveCartService: FFFActiveCartService,
    private fffAllocationService: FffAllocationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams['prebook']) {
      this.isPrebookCart = true;
      this.showCartUpdatedIcon = true;
    }
    this.entry = JSON.parse(JSON.stringify(this.e));
    this.entry.oldQuantity = this.entry.quantity;
    if (!this.entry.discountValue) {
      this.entry.discountValue = 0.0;
    }
    if (!this.entry.taxValue) {
      this.entry.taxValue = 0.0;
    }
    this.calculateMinOrdQty(this.priceData);
    this.fffActiveCartService.getActive().subscribe(res => {
      if (res) {
        res.entries?.forEach((e: FFFOrderEntry) => {
          this.cart.push({
            code: e.product?.code,
            quantity: e.quantity,
            accountType: e.accountType,
          });
        });
      }
    });

    this.store
      .select((state: any) => {
        return state.IncomingCartUpdates;
      })
      .subscribe(c => {
        if (c) {
          Object.keys(c.cartEntriesData).forEach((change: any) => {
            let changedEntry = c.cartEntriesData[change].entry;
            let f = this.cart.find(
              (cartItem: any) =>
                cartItem.code === changedEntry.product.code &&
                cartItem.accountType === changedEntry.accountType
            );
            if (f) {
              f.quantity = +changedEntry.quantity;
            }
          });
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  // Only takes consideration of events with data and if data is different to current
  occursAChange(event: any, oldData: any, newData: any) {
    return event != null && oldData !== newData;
  }

  updatePrices() {
    this.price = null;
    const defaultAccountType = '';
    this.price = [
      {
        accountType: defaultAccountType,
        warehouse: 'p_warehouse',
        price: {
          productCode: this.entry.product.code,
          priceMap: {},
        },
        key: `${defaultAccountType}_${this.entry.product.code}`,
      },
    ];
  }

  isValidPriceData(priceData: any, variant: string): boolean {
    return priceData.price.priceMap[variant];
  }

  entryQuantityChange(quantity: any) {
    let entry: FFFOrderEntry = {
      key: this.entry.accountType + '__' + this.entry.product?.code,
      accountType: this.entry.accountType,
      warehouse: this.entry.warehouse,
      backOrder: this.entry.backOrder,
      product: {
        code: this.entry.product.code,
      },
      entryNumber: this.entry.entryNumber,
      quantity: quantity,
    };

    this.store.dispatch({
      type: ADD_PRODUCT_FOCUSED_CART,
      payload: {
        action: 'UPDATE',
        entry: entry,
      },
    });
    if (this.isPrebookCart && this.entry.oldQuantity !== quantity) {
      this.showCartUpdatedIcon = false;
    } else {
      this.showCartUpdatedIcon = this.isPrebookCart;
    }
  }

  checkAllocation() {
    if (this.entry.quantity > 0 && this.allocation) {
      let allocation = this.allocation;
      let qtyInCart = 0;

      this.cart.forEach((e: any) => {
        if (
          this.entry.product.code === e.code &&
          e.accountType !== this.entry.accountType
        ) {
          qtyInCart += e.quantity;
        }
      });

      const totalQuantity = qtyInCart + +this.entry.quantity;
      const hasMaterial = allocation.material;
      const allocCompareValue = this.fffAllocationService.transformUnits(
        this.fffAllocationService.getAllocationCompareValue(allocation),
        allocation,
        this.entry.uom,
        this.priceData
      );
      let isInvalidLine = !!(
        allocCompareValue !== null &&
        totalQuantity > 0 &&
        allocCompareValue < totalQuantity &&
        hasMaterial
      );
      this.isInvalidLine = isInvalidLine;
      return isInvalidLine;
    }

    return false;
  }

  calculateMinOrdQty(priceData: any) {
    if (Math.round(+priceData.price?.moq) > 1) {
      priceData.price.toAltPrice.results.forEach((altPrice: any) => {
        if (+altPrice.altMinOrdQty === 1) {
          this.min = Math.round(+altPrice.altMinOrdQty);
          this.basicUOM = altPrice.altUOM;
        } else {
          this.min = Math.round(+priceData.price.minOrderQuantity);
          this.mdu = Math.round(+priceData.price.mdu);
        }
      });
    } else if (Math.round(+priceData.price?.minOrderQuantity) === 1) {
      this.min = Math.round(+priceData.price.minOrderQuantity);
    } else if (Math.round(+priceData.price?.minOrderQuantity) > 1) {
      priceData.price.toAltPrice.results.forEach((altPrice: any) => {
        if (Math.round(+altPrice.altMinOrdQty) === 1) {
          this.min = Math.round(+altPrice.altMinOrdQty);
          this.basicUOM = altPrice.altUOM;
        }
      });
    }
  }

  isInvalidQty(entry: any, quantity: number) {
    if (+quantity >= 1) {
      if (this.min > 1 && this.min > +quantity) {
        this.tooltipMessage = 'fffProduct.orderMinimum';
        return true;
      }
      if (this.mdu) {
        if (
          this.mdu > 1 &&
          +this.priceData.price.moq > 1 &&
          +quantity % this.mdu !== 0
        ) {
          this.tooltipMessage = 'fffProduct.qtyMultipleMdu';
          return true;
        }
      }
    }
    this.tooltipMessage = '';
    return false;
  }

  surpassesYearlyAlloc(allocation: any, totalQuantity: number) {
    return (
      +allocation.yearlyAllocatedQty > 0 &&
      totalQuantity > +allocation.yearlyRemainingQty
    );
  }

  surpassesMonthlyAlloc(allocation: any, totalQuantity: number) {
    return (
      +allocation.allocatedQty > 0 && totalQuantity > +allocation.remainingQty
    );
  }

  updateQuantity(event: any, entry: OrderEntry): void {
    if (
      !this.isInvalidLine &&
      !this.isInvalidQty(entry, event?.target?.value) &&
      event?.target?.value &&
      this.entry.oldQuantity !== event.target.value
    ) {
      this.showCartUpdatedIcon = false;
      this.fffActiveCartService.performActionAfterStable = true;
      setTimeout(() => {
        this.fffActiveCartService.updateEntry(
          entry.entryNumber!,
          event.target.value
        );
      }, 300);
    } else {
      this.showCartUpdatedIcon = this.isPrebookCart;
    }
  }
}
