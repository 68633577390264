<ng-container *ngIf="b2bUnitData$ | async as b2bUnitData; else loadingTemplate">
  <div class="container">
    <div class="d-flex justify-content-between top-title flex-wrap">
      <div class="d-flex filterContainer">
        <h3 class="pr-4 mb-0">{{ 'fffOrderHistory.title' | cxTranslate }}</h3>
        <button class="btn btn-secondary btn-xs" (click)="openFilterDrawer()">
          {{ 'fffOrderHistory.filter' | cxTranslate }}
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlinedIconTypes.ICON_FILTER"
          >
          </cx-icon>
        </button>
        <button
          class="btn btn-secondary btn-xs ml-3 mr-3"
          (click)="goToReportsPage()"
        >
          {{ 'fffOrderHistory.exportReport' | cxTranslate }}
        </button>
      </div>

      <!--div class="d-flex btn-container">
        <a class="d-flex align-items-center export-btn disabled" (click)="exportCSV()">
          <span>{{ 'fffInvoiceHistory.exportCsv' | cxTranslate }}</span>
          <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_DOWNLOAD">
          </cx-icon>
        </a>
      </div-->

      <div class="d-flex btn-container mt-3 mt-lg-0">
        <button
          type="button"
          class="btn btn-primary btn-xs"
          style="min-width: 200px"
          [disabled]="!selectedOrders.length"
          (click)="resendBulkConfirmation()"
        >
          {{ 'fffOrderHistory.actions.sendOrderConfirmations' | cxTranslate }}
        </button>
      </div>
    </div>
    <div
      *ngIf="selectedFilters && selectedFilters.length > 0"
      class="filter-tags-list pt-3 d-flex"
    >
      <span
        class="filter-tag-item"
        *ngFor="let item of selectedFilters; let i = index"
      >
        <span>{{ item?.displayValue }}</span>
        <cx-icon
          class="icon-sm calendar-icons"
          [type]="outlinedIconTypes.ICON_X"
          (click)="removeItem(item, i)"
        ></cx-icon>
      </span>
      <span class="clearFiltersText" (click)="clearAllFilters()">{{
        'fffOrderHistory.clear' | cxTranslate
      }}</span>
    </div>

    <hr class="sep-table-title" />

    <div
      *ngIf="
        orderHistoryList$ | async as orderHistoryList;
        else loadingTemplate
      "
    >
      <ng-container *ngIf="hasData(orderHistoryList)">
        <fff-table
          [hasPaginator]="true"
          [data]="getTableData(orderHistoryList)"
          [fields]="fields"
          [hasTemplate]="true"
          [mobileSize]="mobileSize"
          [itemTemplate]="orderItem"
          [columnsGrid]="columnsGrid"
          (pageChange)="getData($event)"
          [customClass]="'user-price-list'"
          [size]="getTableSizeFromPagination(orderHistoryList)"
        >
        </fff-table>
      </ng-container>
    </div>
  </div>

  <ng-template #orderItem let-item="item">
    <div
      class="order-history-item td justify-content-md-center align-items-md-center"
      [style.grid-template-columns]="columnsGrid.desktop"
    >
      <div class="px-0 col-6 col-lg-12 order-0">
        <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
          {{ 'fffOrderHistory.order' | cxTranslate }}
        </div>
        <div class="d-flex fff-orders-action-item">
          <ng-container *ngIf="signOrderResult$ | async as signOrderResult">
            <cx-icon
              class="cx-icon icon-sm px-2 warning cursor-pointer csos-error-tooltip position-relative d-inline-block"
              [type]="filledIconTypes.ICON_EXCLAMATION_TRIANGLE_FILL"
              (click)="showLegysymSignResponse()"
              (mouseenter)="openToolTip()"
              (mouseleave)="closeToolTip()"
              *ngIf="
                signOrderResult &&
                isSameCurrentOrderId(item.code, signOrderResult) &&
                !isSignedOrder(item.code, signOrderResult)
              "
            >
              <fff-tooltip
                class="warn-tooltip message-text position-absolute"
                *ngIf="
                  showTooltip &&
                  signOrderResult &&
                  isSameCurrentOrderId(item.code, signOrderResult) &&
                  !isSignedOrder(item.code, signOrderResult)
                "
                text="{{
                  'fffOrderHistory.legisymMessages.errorSignOrder' | cxTranslate
                }}"
              ></fff-tooltip>
            </cx-icon>
          </ng-container>
          <span
            class="action-label"
            (click)="goToOrderDetails(item.code)"
          >
            {{ item.code }}
          </span>
        </div>
      </div>

      <div class="px-0 col-12 order-2 order-lg-1">
        <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
          {{ 'fffOrderHistory.orderDate' | cxTranslate }}
        </div>
        <div>{{ item.placed | date : 'MM/dd/yyyy' }}</div>
      </div>

      <div class="px-0 col-6 col-lg-12 order-1 order-lg-2">
        <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
          {{ 'fffOrderHistory.status' | cxTranslate }}
        </div>

        <ng-container
          *ngIf="signOrderResult$ | async as signOrderResult; else sapOrderOpen"
        >
          <span
            class="success"
            *ngIf="
              !hasFilter && isSignedOrder(item.code, signOrderResult);
              else sapOrderOpen
            "
          >
            {{ 'fffOrderHistory.signedSuccessfully' | cxTranslate }}
          </span>
        </ng-container>

        <ng-template #sapOrderOpen>
          <ng-container
            *ngIf="item.csosOrderStatus === 'OPEN'; else sapOrderStatusLabel"
          >
            <button
             [disabled]="isAsmEnabled"
              class="btn btn-secondary btn-xs"
              (click)="signOrder(item, b2bUnitData)"
            >
              {{ 'fffOrderHistory.actions.signOrder' | cxTranslate }}
            </button>
          </ng-container>
        </ng-template>

        <ng-template #sapOrderStatusLabel>
          <div>{{ item.sapOrderStatus }}</div>
          <!-- <div *ngIf="!hasShippingInformation(item)">
            {{ item.sapOrderStatus }}
          </div> -->
        </ng-template>
      </div>

      <div class="px-0 col-6 col-lg-12 order-3">
        <div class="d-none d-lg-block">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
            {{ 'fffOrderHistory.po' | cxTranslate }}
          </div>
          <div>{{ item.purchaseOrderNumber }}</div>
        </div>

        <div class="d-block d-lg-none">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
            {{ 'fffOrderHistory.total' | cxTranslate }}
          </div>
          <div>{{ item.total.formattedValue }}</div>
        </div>
      </div>

      <div class="px-0 col-6 col-lg-12 order-4">
        <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
          {{ 'fffOrderHistory.action' | cxTranslate }}
        </div>

        <div class="fff-orders-actions-list fff-order-history-component">
          <div class="fff-orders-action-item">
            <ng-container
              *ngIf="
                signOrderResult$ | async as signOrderResult;
                else standardOrderActions
              "
            >
              <div
                class="inside-group"
                *ngIf="
                  isSignedOrder(item.code, signOrderResult);
                  else standardOrderActions
                "
              >
                <div class="checkbox-input-wrap">
                  <input
                    class="checkbox checkbox-sm"
                    type="checkbox"
                    [attr.id]="'resend-confirmation-checkbox-' + item.code"
                    [(ngModel)]="item.selected"
                    (ngModelChange)="
                      onResendConfirmationSelectionChange($event, item)
                    "
                  />
                  <label
                    class="checkbox-label action-label pb-2 pr-2"
                    [attr.for]="'resend-confirmation-checkbox-' + item.code"
                    >{{
                      'fffOrderHistory.actions.resendConfirmation' | cxTranslate
                    }}
                  </label>
                  <ng-container
                    *ngIf="isSignedOrder(item.code, signOrderResult)"
                  >
                    &nbsp;|
                  </ng-container>
                </div>

                <ng-container *ngIf="isSignedOrder(item.code, signOrderResult)">
                  <div
                    class="action-label mb-0"
                    (click)="print(item.csosOrderId, item.code, b2bUnitData)"
                  >
                    {{ 'fffOrderHistory.actions.print' | cxTranslate }}
                  </div>
                  &nbsp;|
                  <div
                    class="action-label mb-0"
                    (click)="receive(item.csosOrderId, item.code, b2bUnitData)"
                  >
                    {{ 'fffOrderHistory.actions.receiveOrder' | cxTranslate }}
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <ng-template #standardOrderActions>
              <div class="inside-group">
                <div class="checkbox-input-wrap">
                  <input
                    class="checkbox checkbox-sm"
                    type="checkbox"
                    [attr.id]="'resend-confirmation-checkbox-' + item.code"
                    [(ngModel)]="item.selected"
                    (ngModelChange)="
                      onResendConfirmationSelectionChange($event, item)
                    "
                  />
                  <label
                    class="checkbox-label action-label pb-2 pr-2"
                    [attr.for]="'resend-confirmation-checkbox-' + item.code"
                    >{{
                      'fffOrderHistory.actions.resendConfirmation' | cxTranslate
                    }}
                  </label>
                  <ng-container *ngIf="item.csosOrderStatus === 'COMPLETED'"
                    >&nbsp;|
                  </ng-container>
                </div>
                <ng-container *ngIf="item.csosOrderStatus === 'COMPLETED'">
                  <div
                    class="action-label mb-0"
                    (click)="print(item.csosOrderId, item.code, b2bUnitData)"
                  >
                    {{ 'fffOrderHistory.actions.print' | cxTranslate }}
                  </div>
                  &nbsp;|
                  <div
                    class="action-label mb-0"
                    (click)="receive(item.csosOrderId, item.code, b2bUnitData)"
                  >
                    {{ 'fffOrderHistory.actions.receiveOrder' | cxTranslate }}
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </div>
          <div
            class="fff-orders-action-item pb-2"
            *ngIf="hasShippingInformation(item)"
          >
            <div
              class="col-12 px-0 d-flex flex-wrap"
              *ngIf="getShippingInformation(item).length === 1"
            >
              <ng-container
                *ngFor="let shippingData of getShippingInformation(item)"
              >
                <span
                  class="col-12 px-0 action-label shipping-link"
                  (click)="openTrackingTab(shippingData.trackingUrl)"
                  >{{ shippingData.trackingID }}</span
                >
              </ng-container>
            </div>

            <div
              class="col-12 px-0 d-flex flex-wrap"
              *ngIf="getShippingInformation(item).length > 1"
            >
              <span
                class="col-12 px-0 action-label shipping-link"
                (click)="goToOrderDetails(item.code)"
                >{{ 'fffOrderHistory.multipleShipments' | cxTranslate }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-flex d-lg-none flex-wrap py-3 order-6 justify-content-center"
      >
        <button
          class="btn btn-tertiary btn-xs"
          (click)="goToOrderDetails(item.code)"
        >
          {{ 'fffOrderHistory.actions.view' | cxTranslate }}
        </button>
      </div>
    </div>
  </ng-template>

  <div>
    <form
      #form
      id="goExpress222SignOrderForm"
      class="m-auto"
      name="goExpress222SignOrder"
      [action]="legisymDataSO.url"
      [method]="legisymDataSO.method"
    >
      <div>
        <input
          class="col-12"
          type="hidden"
          name="ServiceContract"
          [value]="legisymDataSO.serviceContract"
        />
        <input
          class="col-12"
          type="hidden"
          name="loginToken"
          [value]="legisymDataSO.loginToken"
        />
        <input
          class="col-12"
          type="hidden"
          name="Action"
          [value]="legisymDataSO.action"
        />
        <input
          class="col-12"
          type="hidden"
          name="returnURL"
          [value]="legisymDataSO.returnURL"
        />
        <input
          class="col-12"
          type="hidden"
          name="XmlFile"
          [value]="legisymDataSO.xmlFile"
        />
        <input
          class="col-12"
          type="hidden"
          name="OrderId"
          [value]="legisymDataSO.csosOrderId"
        />
      </div>
    </form>
  </div>

  <div>
    <form
      #form
      id="goExpress222PrintForm"
      class="m-auto"
      name="goExpress222Print"
      [action]="legisymDataPR.url"
      [method]="legisymDataPR.method"
      target="_blank"
    >
      <div>
        <input
          class="col-12"
          type="hidden"
          name="ServiceContract"
          [value]="legisymDataPR.serviceContract"
        />
        <input
          class="col-12"
          type="hidden"
          name="loginToken"
          [value]="legisymDataPR.loginToken"
        />
        <input
          class="col-12"
          type="hidden"
          name="Action"
          [value]="legisymDataPR.action"
        />
        <input
          class="col-12"
          type="hidden"
          name="returnURL"
          [value]="legisymDataPR.returnURL"
        />
        <input
          class="col-12"
          type="hidden"
          id="csos_order_{{ legisymDataPR.csosOrderId }}"
          name="OrderId"
          [value]="legisymDataPR.csosOrderId"
        />
        <input
          class="col-12"
          type="hidden"
          onclick="checkCsosOrderId(legisymDataPR.csosOrderId,'print')"
          class="button"
          value="Print 222 Form"
        />
      </div>
    </form>
  </div>

  <div>
    <form
      #form
      id="goExpress222ReceiveForm"
      class="m-auto"
      name="goExpress222Receive"
      [action]="legisymDataRC.url"
      [method]="legisymDataRC.method"
      target="_blank"
    >
      <div>
        <input
          class="col-12"
          type="hidden"
          name="ServiceContract"
          [value]="legisymDataRC.serviceContract"
        />
        <input
          class="col-12"
          type="hidden"
          name="loginToken"
          [value]="legisymDataRC.loginToken"
        />
        <input
          class="col-12"
          type="hidden"
          name="Action"
          [value]="legisymDataRC.action"
        />
        <input
          class="col-12"
          type="hidden"
          name="returnURL"
          [value]="legisymDataRC.returnURL"
        />
        <input
          class="col-12"
          type="hidden"
          id="csos_order_{{ legisymDataRC.csosOrderId }}"
          name="OrderId"
          [value]="legisymDataRC.csosOrderId"
        />
        <input
          class="col-12"
          type="hidden"
          onclick="checkCsosOrderId('legisymDataRC.csosOrderId', 'receive')"
          class="button"
          value="Receive Order"
        />
      </div>
    </form>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <fff-table-loader [fields]="fields"></fff-table-loader>
</ng-template>
