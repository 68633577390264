import { Component, OnInit } from '@angular/core';
import { OrderConfirmationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { AsmEnablerService } from '@spartacus/asm/root';
import { RoutingService } from '@spartacus/core';
import { Observable, Subject, of } from 'rxjs';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';

@Component({
  selector: 'fff-order-confirmation-overview',
  templateUrl: './fff-order-confirmation-overview.component.html',
})
export class FffOrderConfirmationOverviewComponent implements OnInit {
  outlinedIconTypes = OUTLINED_ICON_TYPE;

  orderTypes = {
    standarOrder: 'STANDAR_ORDER',
    orderRequest: 'ORDER_REQUEST',
    splitBillingOrder: 'SPLIT_BILLING_ORDER',
    semiSplitBillingOrder: 'SEMI_SPLIT_BILLING_ORDER',
    recurringOrder: 'RECURRING_ORDER',
  };

  orderB2BData$: Observable<any> = new Subject<any>();
  order$: Observable<any> = new Subject<any>();
  hasCsosProducts$: Observable<boolean> = new Subject<boolean>();

  CSOS_PRODUCT_TYPES: string[] = ['C2', 'C2N'];

  isAsmEnabled: boolean = this.asmEnableService.isEnabled();

  constructor(
    protected routingService: RoutingService,
    private store: Store<OrderConfirmationState>,
    private asmEnableService: AsmEnablerService
  ) {}

  ngOnInit(): void {
    this.store
      .select((state: any) => {
        return state.OrderConfirmationState;
      })
      .subscribe(orderConfirmationData => {
        if (orderConfirmationData && orderConfirmationData.data) {
          this.getB2bOrderData(orderConfirmationData.data);
          this.order$ = of(orderConfirmationData.data);
        } else {
          this.order$ = of();
        }
      });
  }

  getB2bOrderData(orderConfirmationData: any): void {
    if (
      (orderConfirmationData.orders &&
        orderConfirmationData.orders.length > 0) ||
      (orderConfirmationData.quotes && orderConfirmationData.quotes.length > 0)
    ) {
      const orderData =
        orderConfirmationData.orders.length > 0
          ? orderConfirmationData.orders[0].order
          : orderConfirmationData.quotes.length > 0
          ? orderConfirmationData.quotes[0].order
          : undefined;

      if (orderData) {
        const b2bOrderData = {
          accountNumber: orderData.unit?.parentOrgUnit?.uid
            ? orderData.unit.parentOrgUnit.uid
            : orderData.unit?.uid,
          accountName: orderData.unit.name,
          emailAddress: orderData.orgCustomer
            ? orderData.orgCustomer.email
            : orderData.user.uid,
          shipToAddress: this.getShipToAddress(orderData),
          deliveryTerms: orderData.unit.deliveryTermsDesc,
          paymentTerms: orderData.unit.paymentTerms,
          paymentTermsDesc: orderData.unit.paymentTermsDesc,
        };
        this.orderB2BData$ = of(b2bOrderData);
      }
    }
    this.hasCsosProducts$ = of(
      this.orderHasCsosProducts(orderConfirmationData)
    );
  }

  orderHasCsosProducts(orderConfirmationData: any): boolean {
    const ordersWithCsosProducts = this.orderListHasCsosProducts(
      orderConfirmationData.orders
    );
    const quotesWithCsosProducts = this.orderListHasCsosProducts(
      orderConfirmationData.quotes
    );
    return ordersWithCsosProducts || quotesWithCsosProducts;
  }

  private orderListHasCsosProducts(orderList: any): boolean {
    let ordersWithCsosProducts = false;
    for (const orderData of orderList) {
      const hasCsosProduct = orderData.order.entries.filter((entry: any) =>
        this.CSOS_PRODUCT_TYPES.includes(entry.product.schdCatType)
      );
      if (hasCsosProduct.length > 0) {
        ordersWithCsosProducts = true;
        break;
      }
    }
    return ordersWithCsosProducts;
  }

  getShipToAddress(orderData: any): string {
    if (orderData.unit.addresses) {
      const shippingAddress = orderData.unit.addresses.filter(
        (address: any) => address.shippingAddress
      );
      return shippingAddress.length > 0
        ? shippingAddress[0].formattedAddress
        : '';
    }
    return '';
  }

  getOrderType(orderData: any): string {
    if (orderData.orders.length > 1 || orderData.quotes.length > 1) {
      return this.orderTypes.splitBillingOrder;
    } else {
      return this.orderTypes.standarOrder;
    }
  }

  goToOrderHistoryListPage(): void {
    this.routingService.go('/my-account/orders');
  }
}
