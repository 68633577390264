<div class="profile-spinner" *ngIf="isUpdating$ | async">
  <cx-spinner class="overlay"></cx-spinner>
</div>
<div class="myAccount" *ngIf="profile$ | async as profile">
  <ng-container *ngIf="profile.user">
    <fff-profile-switch-account
      [profile]="profile"
      (reloadAccount)="reloadAccount()"
      (isUpdating)="setUpdating($event)"
      (setResult)="showMessageResult($event)"
    >
    </fff-profile-switch-account>

    <div class="profileManagement">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3">
            <fff-profile-detail-personal
              [user]="profile.user"
              (reloadAccount)="reloadAccount()"
              (isUpdating)="setUpdating($event)"
              (setResult)="showMessageResult($event)"
            >
            </fff-profile-detail-personal>
            <fff-profile-reports-tile> </fff-profile-reports-tile>
          </div>
          <div class="profile-wallet-section">
            <fff-profile-detail-account
              class="col-xl-6"
              [selected]="profile.selected"
              (reloadAccount)="reloadAccount()"
              (isUpdating)="setUpdating($event)"
              (setResult)="showMessageResult($event)"
            >
            </fff-profile-detail-account>
            <fff-profile-wallet
              [isASMUser]="disableWallet"
              *ngIf="isInvoicePaymentEnabled"
            >
            </fff-profile-wallet>
          </div>

          <fff-profile-detail-csos
            class="col-xl-3"
            [user]="profile.user"
            [selected]="profile.selected"
            (reloadAccount)="reloadAccount()"
            (isUpdating)="setUpdating($event)"
            (setResult)="showMessageResult($event)"
          >
          </fff-profile-detail-csos>
        </div>
      </div>
    </div>
  </ng-container>
</div>
