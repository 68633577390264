import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FffCreditCardMaskPipe } from './fff-credit-card-mask.pipe';
import { FFFIsEmptyObjectPipe } from './fff-is-empty-object.pipe';
import { FffTrimTransactionIdPipePipe } from './fff-trim-transaction-id-pipe.pipe';

@NgModule({
  declarations: [
    FffCreditCardMaskPipe,
    FFFIsEmptyObjectPipe,
    FffTrimTransactionIdPipePipe,
  ],
  imports: [CommonModule],
  exports: [
    FffCreditCardMaskPipe,
    FFFIsEmptyObjectPipe,
    FffTrimTransactionIdPipePipe,
  ],
})
export class FffSharedPipesModule {}
