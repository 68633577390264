<ng-container>
  <div class="container" *ngIf="orderDetail$ | async as orderDetail">
    <div class="header-details p-4">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <ng-container>
            <p class="mb-0">
              <span class="body-text-demi">{{
                'fffCheckout.accountNumber' | cxTranslate
              }}</span
              >&nbsp;
              <span>{{ orderDetail.unit.uid }}</span>
            </p>
            <p class="mb-0">
              <span class="body-text-demi">{{
                'fffCheckout.company' | cxTranslate
              }}</span
              >&nbsp;
              <span>{{ orderDetail.unit.name }}</span>
            </p>
            <p class="mb-0">
              <span class="body-text-demi">{{
                'fffOrderHistoryDetail.orderDate' | cxTranslate
              }}</span
              >&nbsp;
              <span>{{ orderDetail.created | date : 'MM/dd/yyyy' }}</span>
            </p>
            <p class="mb-0">
              <span class="body-text-demi">{{
                'fffCheckout.emailAddress' | cxTranslate
              }}</span
              >&nbsp;
              <span>{{ orderDetail.placedBy }}</span>
            </p>
          </ng-container>
        </div>
        <div class="col-md-6 col-sm-12">
          <ng-container *ngIf="b2bUnitData$ | async as b2bUnitData">
            <p class="mb-0">
              <span class="body-text-demi">{{
                'fffCheckout.shipToAddress' | cxTranslate
              }}</span
              >&nbsp;
              <ng-container *ngIf="orderDetail.deliveryAddress.shippingAddress">
                <span *ngIf="orderDetail.deliveryAddress.line1">{{
                  orderDetail.deliveryAddress.line1
                }}</span>
                <span *ngIf="orderDetail.deliveryAddress.line2">{{
                  orderDetail.deliveryAddress.line2
                }}</span>
                <span *ngIf="orderDetail.deliveryAddress.town"
                  >, {{ orderDetail.deliveryAddress.town }}</span
                >
                <span *ngIf="orderDetail.deliveryAddress?.region?.name"
                  >, {{ orderDetail.deliveryAddress.region.name }}</span
                >
                <span *ngIf="orderDetail.deliveryAddress?.postalCode"
                  >, {{ orderDetail.deliveryAddress.postalCode }}</span
                >
              </ng-container>
            </p>
            <p class="mb-0">
              <span class="body-text-demi">{{
                'fffCheckout.paymentTerms' | cxTranslate
              }}</span
              >&nbsp;
              <span>{{ b2bUnitData.paymentTermsDesc }}</span>
            </p>
            <p class="mb-0">
              <span class="body-text-demi">{{
                'fffOrderHistoryDetail.status' | cxTranslate
              }}</span
              >&nbsp;
              <span *ngIf="!hasShippingInformation(orderDetail)">{{
                orderDetail.statusDisplay
              }}</span>
              <ng-container *ngIf="hasShippingInformation(orderDetail)">
                <span class="col-12 px-0">{{
                  'fffOrderHistory.shipped' | cxTranslate
                }}</span
                >&nbsp;
                <ng-container
                  *ngFor="
                    let shippingData of getShippingInformation(orderDetail)
                  "
                >
                  <span
                    class="col-12 px-0 action-label shipping-link"
                    (click)="openTrackingTab(shippingData.trackingUrl)"
                    >{{ shippingData.trackingID }}</span
                  >&nbsp;
                </ng-container>
              </ng-container>
            </p>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="py-4">
      <div class="summary py-4">
        <div class="d-flex flex-wrap">
          <div class="px-0 col-12">
            <div class="col-12">
              <div class="header text-center">
                {{ 'fffOrderHistoryDetail.title' | cxTranslate
                }}{{ orderDetail.code }}
              </div>
              <div class="subtitle text-center">
                {{ 'fffOrderHistoryDetail.po' | cxTranslate
                }}{{ orderDetail.purchaseOrderNumber }}
              </div>
              <div class="d-flex flex-wrap col-12 py-3">
                <div class="col-4 px-0">
                  {{ orderDetail.totalItems }}
                  {{ 'fffOrderHistoryDetail.items' | cxTranslate }}
                </div>
                <div
                  class="col-8 px-0 text-right"
                  (click)="resendConfirmation(orderDetail.code)"
                >
                  <span
                    class="action-label d-inline-flex"
                    *ngIf="!confirmationSend"
                  >
                    {{
                      'fffOrderHistoryDetail.actions.resendConfirmation'
                        | cxTranslate
                    }}
                    <cx-icon
                      class="cx-icon icon-sm pl-2"
                      [type]="outlinedIconTypes.ICON_ENVELOPE"
                    ></cx-icon>
                  </span>

                  <span
                    class="action-label d-inline-flex"
                    *ngIf="confirmationSend"
                  >
                    {{
                      'fffOrderHistoryDetail.actions.resendConfirmation'
                        | cxTranslate
                    }}
                    <cx-icon
                      class="cx-icon icon-sm pl-2"
                      [type]="outlinedIconTypes.ICON_CHECK_CIRCLE"
                    ></cx-icon>
                  </span>
                </div>
              </div>

              <ng-container *ngFor="let item of orderDetail.entries">
                <div class="d-flex flex-wrap col-12">
                  <div class="item-entry d-flex pt-3 pb-5 col-12 px-0">
                    <div class="col-2 px-0">
                      <a
                        [routerLink]="
                          { cxRoute: 'product', params: item.product } | cxUrl
                        "
                        cxModal="dismiss"
                        tabindex="-1"
                      >
                        <cx-media
                          [container]="getData(item.product)"
                          format="product"
                        ></cx-media>
                      </a>
                    </div>

                    <div class="cx-info col-10">
                      <div class="cx-info-container row">
                        <div class="col-md-4 col-sm-12">
                          <div class="text-left">
                            <p class="name mb-0">{{ item.product?.name }}</p>
                            <p class="mb-0">{{ item.product?.manufacturer }}</p>
                            <p class="mb-0">
                              {{ 'fffProduct.lblNdc' | cxTranslate }}
                              {{ item?.product?.ndc }}
                            </p>
                            <p class="mb-0">
                              {{ 'fffProduct.lblSku' | cxTranslate }}
                              {{ item?.product?.code }}
                            </p>
                            <p class="mb-0">
                              {{
                                'fffOrderHistoryDetail.quantity' | cxTranslate
                              }}
                              {{ item.quantity }} {{ item?.uom }}
                            </p>
                            <p class="mb-0">
                              {{
                                'fffOrderHistoryDetail.warehouse' | cxTranslate
                              }}
                              {{ item.warehouse }}
                            </p>
                            <p class="mb-0" *ngIf="item?.strength">
                              {{ 'fffProduct.actualStrength' | cxTranslate }}:
                              {{ item.strength }}
                            </p>
                            <p class="mb-0" *ngIf="item?.batch">
                              {{ 'fffProduct.lblBatch' | cxTranslate }}:
                              {{ item?.batch }}
                              <ng-container *ngIf="item?.expirationDate"
                                >| {{ 'fffProduct.exp' | cxTranslate }}:
                                {{
                                  parseDate(item?.expirationDate)
                                    | cxDate : 'MM/dd/yyyy'
                                }}</ng-container
                              >
                            </p>
                          </div>
                        </div>

                        <div class="col-md-4 col-sm-12 arrival-date">
                          <div class="text-left">
                            <p class="mb-0">
                              {{
                                'fffOrderHistoryDetail.arrivalDate'
                                  | cxTranslate
                              }}
                            </p>
                            <p class="mb-0" *ngIf="orderDetail.prebook">
                              <ng-container *ngIf="item.estStartDate">
                                {{ item.estStartDate | date : 'MM/dd/yyyy' }} -
                              </ng-container>
                              {{ item.estArrivalDate | date : 'MM/dd/yyyy' }}
                            </p>
                            <p class="mb-0" *ngIf="!orderDetail.prebook">
                              <span
                                *ngIf="item.estArrivalDate !== '0000-00-00'"
                              >
                                {{ item.estArrivalDate | date : 'MM/dd/yyyy' }}
                              </span>

                              <span *ngIf="item?.arrivalDatePushed">
                                <fff-tooltip
                                  #tooltip
                                  text="{{
                                    'fffShippingSelector.arrivalDateTooltipText'
                                      | cxTranslate
                                  }}"
                                  class="warn-tooltip fluseason-tooltip-font-size"
                                >
                                </fff-tooltip>
                                <span
                                  class="cursor-pointer"
                                  (click)="tooltip.isVisible = true"
                                >
                                  <cx-icon
                                    class="cx-icon d-inline"
                                    [type]="outlinedIconTypes.ICON_INFO_CIRCLE"
                                  ></cx-icon>
                                </span>
                              </span>
                            </p>
                          </div>
                        </div>

                        <div class="col-md-4 col-sm-12 px-0">
                          <div
                            class="text-right"
                            *ngIf="!orderDetail.code.startsWith('002', 0)"
                          >
                            <div>
                              <span class="total mb-0" style="display: inline"
                                >${{
                                  item.totalPrice.value | number : '1.2-2'
                                }}</span
                              >
                              <span
                                class="total mb-0"
                                style="display: inline"
                                *ngIf="item?.altDiscPrice && item?.altUom"
                                >/{{ item.uom }}</span
                              >
                            </div>
                            <ng-container
                              *ngIf="item?.altDiscPrice && item?.altUom"
                            >
                              <p class="body-text-xl" style="color: #5f5f5f">
                                <b
                                  >${{ item.altDiscPrice }}/{{
                                    item?.altUom
                                  }}</b
                                >
                              </p>
                            </ng-container>
                            <p class="mb-0" *ngIf="item.discountValue > 0">
                              {{ 'orderCost.discount' | cxTranslate }}
                              (${{ item.discountValue | number : '1.2-2' }})
                            </p>
                            <p class="mb-0" *ngIf="getTax(item) !== 0">
                              {{ 'fffOrderHistoryDetail.feTax' | cxTranslate }}:
                              ${{ item.taxValue | number : '1.2-2' }}
                            </p>
                            <p class="mb-0">
                              {{
                                'fffOrderHistoryDetail.shipping' | cxTranslate
                              }}
                              <span
                                [ngClass]="{
                                  'shipping-cost': item.shippingCost?.value > 0
                                }"
                              >
                                ${{
                                  item.shippingCost
                                    ? item.shippingCost.value
                                    : (0 | number : '1.2-2')
                                }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap justify-content-end">
          <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5">
            <div
              class="col-12 py-3"
              *ngIf="!orderDetail.code.startsWith('002', 0)"
            >
              <div class="d-flex flex-wrap summary-row">
                <div class="col-6 px-0 summary-label">
                  {{ 'orderCost.subtotal' | cxTranslate }}
                </div>
                <div class="col-6 px-0 summary-amount text-right">
                  {{ orderDetail.subTotal?.formattedValue }}
                </div>
              </div>

              <div class="d-flex flex-wrap summary-row">
                <div class="col-6 px-0 summary-label">
                  {{ 'fffOrderHistoryDetail.estimatedShipping' | cxTranslate }}
                </div>
                <div class="col-6 px-0 summary-amount text-right">
                  <div
                    class="free-delivery"
                    *ngIf="
                      orderDetail.prebookCart &&
                        (!orderDetail?.deliveryCost ||
                          orderDetail?.deliveryCost?.value === 0);
                      else noFreeDeliveryMsg
                    "
                  >
                    <span class="free-delivery-text">
                      {{ 'orderCost.freeDeliveryText' | cxTranslate }}</span
                    >
                    <span class="free-delivery-subtext">
                      {{ 'orderCost.freeDeliveryPaid' | cxTranslate }}</span
                    >
                  </div>
                  <ng-template #noFreeDeliveryMsg>
                    <span
                      [ngClass]="{
                        'shipping-cost':
                          (orderDetail?.deliveryCost?.value ?? 0) > 0
                      }"
                    >
                      {{
                        orderDetail.deliveryCost?.formattedValue
                          ? orderDetail.deliveryCost?.formattedValue
                          : ('orderCost.toBeDetermined' | cxTranslate)
                      }}
                    </span>
                  </ng-template>
                </div>
              </div>

              <div
                class="d-flex flex-wrap summary-row"
                *ngIf="orderDetail.totalTax?.value !== 0"
              >
                <div class="col-6 px-0 summary-label fet-tax-label">
                  {{ 'orderCost.salesTax' | cxTranslate
                  }}<fff-fet-info-tooltip></fff-fet-info-tooltip>
                </div>
                <div class="col-6 px-0 summary-amount text-right">
                  {{ orderDetail.totalTax?.formattedValue }}
                </div>
              </div>

              <div class="d-flex flex-wrap summary-row pb-4">
                <div class="col-6 px-0 summary-label summary-total">
                  {{ 'orderCost.total' | cxTranslate }}
                </div>
                <div class="col-6 px-0 summary-amount summary-total text-right">
                  {{ orderDetail.totalPriceWithTax?.formattedValue }}
                </div>
              </div>

              <div
                class="d-flex flex-wrap summary-row"
                *ngIf="orderDetail.totalDiscounts?.value > 0"
              >
                <div class="col-6 px-0 summary-label">
                  {{ 'fffOrderHistoryDetail.fffDiscount' | cxTranslate }}
                </div>
                <div class="col-6 px-0 summary-amount text-right">
                  -{{ orderDetail.totalDiscounts?.formattedValue }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
