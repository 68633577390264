<div class="summary-container" *ngIf="cart$ | async as cart">
  <ng-container>
    <div class="d-flex flex-wrap summary-totals">
      <h3 class="col-12 px-0">{{ 'orderCost.orderSummary' | cxTranslate }}</h3>

      <div class="col-12 px-0" *ngIf="cart">
        <div class="d-flex flex-wrap summary-row">
          <div class="col-6 px-0 summary-label">
            {{ 'orderCost.subtotal' | cxTranslate }}
          </div>
          <div class="col-6 px-0 summary-amount text-right">
            {{ cart.subTotal?.formattedValue }}
          </div>
        </div>

        <div
          class="d-flex flex-wrap summary-row"
          *ngIf="cart?.totalDiscounts?.value! > 0"
        >
          <div class="col-6 px-0 summary-label">
            {{ 'orderCost.discount' | cxTranslate }}
          </div>
          <div class="col-6 px-0 summary-amount text-right">
            ({{ cart.totalDiscounts?.formattedValue }})
          </div>
        </div>

        <div class="d-flex flex-wrap summary-row">
          <div class="col-6 px-0 summary-label">
            {{
              (cart.deliveryCost?.formattedValue
                ? 'orderCost.shipping'
                : 'orderCost.estimatedShipping'
              ) | cxTranslate
            }}
          </div>
          <div class="col-6 px-0 summary-amount text-right">
            <div
              class="free-delivery"
              *ngIf="
                cart.prebookCart &&
                  (!cart?.deliveryCost || cart?.deliveryCost?.value === 0);
                else noFreeDeliveryMsg
              "
            >
              <span class="free-delivery-text">
                {{ 'orderCost.freeDeliveryText' | cxTranslate }}</span
              >
              <span class="free-delivery-subtext free-delivery-ht-height">
                {{ 'orderCost.freeDeliveryPaid' | cxTranslate }}</span
              >
            </div>
            <ng-template #noFreeDeliveryMsg>
              <span
                [ngClass]="{
                  'shipping-cost': (cart?.deliveryCost?.value ?? 0) > 0
                }"
              >
                {{
                  cart.deliveryCost?.value
                    ? '$' + (cart.deliveryCost?.value | number : '1.2-2')
                    : ''
                }}
              </span>
            </ng-template>
          </div>
        </div>

        <div
          class="d-flex flex-wrap summary-row justify-content-between"
          *ngIf="cart.totalTax?.value !== 0"
        >
          <div class="px-0 summary-label fet-tax-label">
            {{ 'orderCost.salesTax' | cxTranslate }}

            <fff-fet-info-tooltip></fff-fet-info-tooltip>
          </div>

          <div class="col-4 px-0 summary-amount text-right">
            {{ cart.totalTax?.formattedValue }}
          </div>
        </div>

        <div class="d-flex flex-wrap summary-row">
          <div class="col-12 px-0 summary-divider"></div>
        </div>

        <div class="d-flex flex-wrap summary-row">
          <div class="col-6 px-0 summary-label summary-total">
            {{ 'orderCost.total' | cxTranslate }}
          </div>
          <div class="col-6 px-0 summary-amount summary-total text-right">
            {{ cart.totalPriceWithTax?.formattedValue }}
          </div>
        </div>
        <ng-container>
          <div
            class="d-flex flex-wrap summary-row"
            *ngIf="cart?.totalDiscounts?.value! > 0"
          >
            <div class="col-12 px-0 summary-label">
              {{ 'orderCost.saved' | cxTranslate }}
              <span class="discount-value">{{
                cart.totalDiscounts?.formattedValue
              }}</span>
              {{ 'orderCost.withFFFDiscount' | cxTranslate }}
            </div>
          </div>
        </ng-container>

        <fff-checkout-recurring-orders></fff-checkout-recurring-orders>

        <div class="summary-row body-text-md">
          <input
            class="checkbox checkbox-md"
            type="checkbox"
            id="termsConditions"
            [checked]="acceptTermsConditions"
            (change)="acceptTermsConditions = !acceptTermsConditions"
          />
          <label class="checkbox-label d-flex mx-0" for="termsConditions">
            <span
              class="body-text"
              [innerHTML]="'fffOrderSummary.termsConditions' | cxTranslate"
            ></span>
          </label>
        </div>
      </div>
    </div>
  </ng-container>
  <div
    class="summary-actions d-flex flex-wrap justify-content-center align-items-center m-auto"
    *ngIf="b2bUnitData$ | async as b2bUnitData"
  >
    <div class="col-12">
      <ng-container
        *ngIf="isUserWithPrepaymentStatus(b2bUnitData); else placeOrderButton"
      >
        <button
          class="btn btn-primary col-12"
          [class.updating]="loading && acceptTermsConditions"
          [disabled]="
            !acceptTermsConditions ||
            invalidForm ||
            loading ||
            (isRecurringOrder && !recurringOrdersForm?.valid)
          "
          (click)="prepaymentOrder(b2bUnitData)"
        >
          {{ placeOrderText | cxTranslate }}
          <cx-icon
            *ngIf="loading"
            class="cx-icon icon-md ml-3"
            [type]="outlinedIconTypes.ICON_ARROW_REPEAT"
          ></cx-icon>
        </button>
      </ng-container>
    </div>

    <ng-template #placeOrderButton>
      <button
        class="btn btn-primary col-12"
        [class.updating]="loading && acceptTermsConditions"
        [disabled]="
          !acceptTermsConditions ||
          invalidForm ||
          cart.isLockedForOrdering ||
          loading ||
          (isRecurringOrder && !recurringOrdersForm?.valid)
        "
        (click)="placeOrder(b2bUnitData)"
      >
        {{ placeOrderText | cxTranslate }}
        <cx-icon
          *ngIf="loading"
          class="cx-icon icon-md ml-3"
          [type]="outlinedIconTypes.ICON_ARROW_REPEAT"
        ></cx-icon>
      </button>
    </ng-template>

    <div
      *ngIf="!cart.isLockedForOrdering"
      class="col-12 text-center summary-customer-support"
    >
      <span>{{ 'fffOrderSummary.supportText' | cxTranslate }}</span>
    </div>
    <div *ngIf="cart.isLockedForOrdering" class="col-12 text-center">
      <div class="mt-3 mb-2">
        <cx-icon
          class="cx-icon"
          [type]="outlinedIconTypes.ICON_INFO_CIRCLE"
        ></cx-icon>
      </div>
      <span class="lockedCartInfoMessage">{{
        'fffOrderSummary.messageTextForLockedcart' | cxTranslate
      }}</span>
    </div>
  </div>
</div>
