import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

export interface FffApplicationProperties {
  mfvPrebookCurrentSeason?: string;
  mfvRespiratoryPrebookFormEnabled: string | boolean;
  invoicesPaymentEnabled?: string | boolean;
  invoicesPaymentManufacturer?: string;
  ecommerceReportingCustomersEnabled?: string;
  invoicesSurchargePaymentEnabled?: string | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FffApplicationConfigService {
  private applicationProperties$ =
    new BehaviorSubject<FffApplicationProperties>({
      mfvRespiratoryPrebookFormEnabled: false,
    });

  constructor(private http: HttpClient, private occ: OccEndpointsService) {}

  getApplicationProperties(
    forceLoad: boolean = false
  ): Observable<FffApplicationProperties> {
    // if (forceLoad || !this.applicationProperties$.getValue()) {
    //   this.loadApplicationProperties().subscribe();
    // }

    return this.applicationProperties$
      .asObservable()
      .pipe(map(value => value as FffApplicationProperties));
  }

  private convertToBoolean(item: any): boolean {
    if (!item) {
      return false;
    }
    return typeof item === 'string' ? item === 'true' : item;
  }

  loadApplicationProperties() {
    let url = this.occ.buildUrl('/applicationProperties');
    return this.http.get<FffApplicationProperties>(url).pipe(
      map(data => ({
        ...data,
        mfvRespiratoryPrebookFormEnabled: this.convertToBoolean(
          data.mfvRespiratoryPrebookFormEnabled
        ),
        invoicesPaymentEnabled: this.convertToBoolean(
          data.invoicesPaymentEnabled
        ),
      })),
      tap(data => {
        this.applicationProperties$.next(data);
      }),
      shareReplay()
    );
  }
}
