import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { BASE_URL_KEYS } from '@config/content/constants';
import { ProductScope, isNotNullable } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';

@Component({
  selector: 'fff-product-info',
  templateUrl: './fff-product-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffProductInfoComponent implements OnDestroy {
  private unsubscribe = new Subject<void>();
  outlinedIconTypes = OUTLINED_ICON_TYPE;

  ndcInformationSectionExpand: boolean = true;
  indicatorsSectionExpand: boolean = true;
  prescribingInfoSectionExpand: boolean = false;
  amountOfFieldsShown = 4;
  product$: Observable<any> = this.currentProductService
    .getProduct(['DEFAULT', ProductScope.ATTRIBUTES])
    .pipe(filter(isNotNullable))
    .pipe(takeUntil(this.unsubscribe));
  BASE_URL_KEYS = BASE_URL_KEYS;
  constructor(protected currentProductService: CurrentProductService) {}
  getNdcFields(classificationData: any): any {
    if (classificationData && classificationData.length > 0) {
      const firstClassification = classificationData.find(
        (classification: any) => classification.code == '5510'
      );
      return firstClassification?.features.slice(0, this.amountOfFieldsShown);
    } else {
      return [];
    }
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
