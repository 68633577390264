import { Component, OnInit } from '@angular/core';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { OrderConfirmationState } from '@app/reducers';
import { FFFCommonFunctions } from '@app/shared/fff-common-functions';
import { BASE_URL_KEYS } from '@config/content/constants';
import { Store } from '@ngrx/store';
import { FffUserAccountService } from '@shared/services/fff-user-account.service';
import { MediaContainer } from '@spartacus/storefront';
import { Observable, Subject, of } from 'rxjs';
import { getProductImage } from 'src/assets/utils/fff-product-utils';

@Component({
  selector: 'fff-order-confirmation-items',
  templateUrl: './fff-order-confirmation-items.component.html',
})
export class FffOrderConfirmationItemsComponent implements OnInit {
  filledIconTypes = FILLED_ICON_TYPE;
  orderTypes = {
    standarOrder: 'STANDAR_ORDER',
    orderRequest: 'ORDER_REQUEST',
    splitBillingOrder: 'SPLIT_BILLING_ORDER',
    semiSplitBillingOrder: 'SEMI_SPLIT_BILLING_ORDER',
    recurringOrder: 'RECURRING_ORDER',
  };

  order$: Observable<any> = new Subject<any>();

  profile$ = this.fffAccountService.getProfile();
  BASE_URL_KEYS = BASE_URL_KEYS;

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  isOfVaccineTypeI = FFFCommonFunctions.isOfVaccineType;

  constructor(
    private store: Store<OrderConfirmationState>,
    private fffAccountService: FffUserAccountService
  ) {}

  ngOnInit(): void {
    this.store
      .select((state: any) => {
        return state.OrderConfirmationState;
      })
      .subscribe(orderConfirmationData => {
        if (orderConfirmationData && orderConfirmationData.data) {
          this.order$ = of(orderConfirmationData.data);
        } else {
          this.order$ = of();
        }
      });
  }

  getArrivalDate(item: any) {
    if (item.estArrivalDate) {
      return item.estArrivalDate;
    }
    return 'TBD';
  }

  isPrebookOrder(orderData: any): boolean {
    return orderData.order?.prebook || orderData.quotes?.prebook;
  }

  calculateEstStartDate(estArrivalDate: any): Date {
    if (estArrivalDate) {
      let estStartDate = new Date(estArrivalDate);
      estStartDate.setHours(0);
      estStartDate.setDate(estStartDate.getDate() - 14);
      return estStartDate;
    }
    return new Date();
  }

  getOrdersData(orderData: any) {
    const ordersData: any[] = [];
    orderData.orders.forEach((order: any) => ordersData.push(order));
    orderData.quotes.forEach((quote: any) => ordersData.push(quote));
    orderData.orderRequests.forEach((orderRequests: any) =>
      ordersData.push(orderRequests)
    );
    return ordersData;
  }

  getData(product: any) {
    return getProductImage(product?.images) as MediaContainer;
  }

  getOrderType(orderData: any): string {
    if (orderData.orders.length > 1 || orderData.quotes.length > 1) {
      return this.orderTypes.splitBillingOrder;
    } else if (orderData.orderRequests.length > 0) {
      return this.orderTypes.orderRequest;
    } else {
      return this.orderTypes.standarOrder;
    }
  }

  getOrderEntries(orderData: any): any[] {
    if (orderData.order) {
      return orderData.order.entries;
    } else if (orderData.quotes) {
      return orderData.quotes.entries;
    } else {
      return [];
    }
  }

  parseDate(date: string) {
    return +date.split('/Date(')[1].split(')/')[0];
  }

  isValidBioStatus(entry: any): boolean {
    return (
      entry?.sapStockLevelStatus !== 'ON_DEMAND' &&
      entry?.sapStockLevelStatus !== 'DROP_SHIP'
    );
  }

  isValidMfvStatus(entry: any): boolean {
    return (
      entry?.sapStockLevelStatus !== 'ON_DEMAND' &&
      entry?.sapStockLevelStatus !== 'DROP_SHIP'
    );
  }

  showEstArrivalDate(entry: any): boolean {
    return this.isValidBioStatus(entry) || this.isValidMfvStatus(entry);
  }
}
