<ng-container *ngIf="user$ | async as user">
  <form *ngIf="checkoutLoginForm" [formGroup]="checkoutLoginForm">
    <div class="row m-0">
      <div class="col-lg-4 col-sm-12 pl-auto pl-lg-0">
        <div
          class="d-flex flex-column"
          *ngIf="isDuplicate$ | async; else newPoNumber"
        >
          <div class="d-flex justify-content-between">
            <p class="mb-0 body-text-lg">
              {{ 'fffCheckout.poNumber' | cxTranslate }}
            </p>
            <p class="mb-0 body-text-xxs">Required</p>
          </div>
          <input
            class="form-control form-control-md warning-input"
            type="text"
            formControlName="poNumber"
            (blur)="checkPoNumber(user)"
          />
          <p class="warningPoNumber body-text-sm d-flex">
            <cx-icon
              class="cx-icon icon-xs pe-auto warning-icon"
              [type]="filledIconTypes.ICON_CIRCLE_FILL"
            >
            </cx-icon>
            {{ 'fffCheckout.poDuplicate' | cxTranslate }}
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12" *ngIf="!invoiceApprover">
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <p class="mb-0 body-text-lg">
              {{ 'fffCheckout.referenceName' | cxTranslate }}
            </p>
            <p class="mb-0 body-text-xxs">
              {{ 'fffCheckout.optional' | cxTranslate }}
            </p>
          </div>
          <input
            class="form-control form-control-md"
            type="text"
            formControlName="referenceName"
            maxlength="20"
          />
        </div>
      </div>
      <div class="col-lg-4 col-sm-12 pr-auto pr-lg-0" *ngIf="invoiceApprover">
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <p class="mb-0 body-text-lg">
              {{ 'fffCheckout.invoiceApprover' | cxTranslate }}
            </p>
            <p class="mb-0 body-text-xxs">
              {{ 'fffCheckout.required' | cxTranslate }}
            </p>
          </div>
          <input
            aria-required="true"
            type="email"
            name="email"
            class="form-control form-control-md"
            (ngModelChange)="checkInvoiceApproverEmail()"
            formControlName="referenceName"
            required
          />
          <cx-form-errors
            aria-live="assertive"
            aria-atomic="true"
            [control]="$any(checkoutLoginForm).get('referenceName')"
          ></cx-form-errors>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12 pr-auto pr-lg-0">
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <p class="mb-0 body-text-lg">
              {{ 'fffCheckout.additionalEmailConf' | cxTranslate }}
            </p>
            <p class="mb-0 body-text-xxs">
              {{ 'fffCheckout.optional' | cxTranslate }}
            </p>
          </div>
          <input
            aria-required="true"
            type="email"
            name="email"
            class="form-control form-control-md"
            (ngModelChange)="checkEmail()"
            formControlName="additionalEmailConf"
          />
          <p class="mb-0 checkout-input-info-text">
            <span>
              {{ 'fffCheckout.additionalEmailsText' | cxTranslate }}
            </span>
          </p>
          <cx-form-errors
            aria-live="assertive"
            aria-atomic="true"
            [control]="$any(checkoutLoginForm).get('additionalEmailConf')"
          ></cx-form-errors>
        </div>
      </div>
    </div>
    <ng-container *ngIf="user.uid!.includes(fffEamil)">
      <div
        class="row mx-0 mt-3"
        *ngIf="contactMethods$ | async as contactMethods"
      >
        <div class="col-lg-4 col-sm-12 pl-auto pl-lg-0">
          <div class="d-flex justify-content-between">
            <p class="mb-0 body-text-lg">
              {{ 'fffCheckout.contactMethod' | cxTranslate }}
            </p>
            <p class="mb-0 body-text-xxs">
              {{ 'fffCheckout.optional' | cxTranslate }}
            </p>
          </div>
          <select
            class="form-control form-control-md form-select"
            formControlName="contactMethod"
          >
            <ng-container *ngFor="let contactMethod of contactMethods">
              <option [value]="contactMethod.code">
                {{ 'fffCheckout.' + contactMethod.code | cxTranslate }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
    </ng-container>
    <ng-template #newPoNumber>
      <div class="d-flex justify-content-between">
        <p class="mb-0 body-text-lg">
          {{ 'fffCheckout.poNumber' | cxTranslate }}
        </p>
        <p *ngIf="!obligatoryPo" class="mb-0 body-text-xxs">
          {{ 'fffCheckout.optional' | cxTranslate }}
        </p>
        <p class="mb-0 body-text-xxs">Required</p>
      </div>
      <input
        class="form-control form-control-md"
        type="text"
        formControlName="poNumber"
        (blur)="checkPoNumber(user)"
      />
      <p class="mb-0 checkout-input-info-text">
        <span *ngIf="poRules.startsWith"
          >{{ 'fffCheckout.legend1' | cxTranslate }} {{ maxLength }}
          {{ 'fffCheckout.legend2' | cxTranslate }}
          {{ formatStartingPOs(poRules.startsWith) }}.</span
        >
        <span *ngIf="!poRules.startsWith"
          >{{ 'fffCheckout.legend3' | cxTranslate }}{{ maxLength }}
          {{ 'fffCheckout.legend4' | cxTranslate }}</span
        >
      </p>

      <cx-form-errors
        aria-live="assertive"
        aria-atomic="true"
        [control]="$any(checkoutLoginForm).get('poNumber')"
      >
      </cx-form-errors>
    </ng-template>
  </form>
</ng-container>
