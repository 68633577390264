import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FFFActiveCartService } from '@app/fff-enterprise/fff-custom-cart/fff-active-cart-service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FffUserAccountService } from '@shared/services/fff-user-account.service';
import { Cart, MultiCartFacade } from '@spartacus/cart/base/root';
import { TranslationService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fff-checkout-rapid-commit-popup',
  template: `
    <div class="text-center modal-body">
      <div class="rc-popup-title mb-2">{{ popupTitle }}</div>
      <div class="message">
        <ng-container>
          {{ popupDescription }}
        </ng-container>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary col-12" (click)="acknowledgeAndProceed()">
        {{ popupAction }}
      </button>
    </div>
  `,
})
export class FFFCheckoutRapidCommitPopupComponent implements OnInit {
  popupTitle!: string;
  popupDescription!: string;
  popupAction!: string;

  constructor(
    private translationService: TranslationService,
    private modalService: NgbActiveModal
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.translationService.translate('fffCheckout.rapidCommitPopup.action'),
      this.translationService.translate(
        'fffCheckout.rapidCommitPopup.description'
      ),
      this.translationService.translate('fffCheckout.rapidCommitPopup.title'),
    ])
      .pipe(take(1))
      .subscribe(([action, description, title]) => {
        this.popupAction = action;
        this.popupDescription = description;
        this.popupTitle = title;
      });
  }

  acknowledgeAndProceed() {
    this.modalService.close({ isRapidCommitAcknowledged: true });
  }
}

@Component({
  selector: 'fff-checkout',
  templateUrl: './fff-checkout.component.html',
})
export class FffCheckoutComponent implements OnInit, OnDestroy {
  cart$: Observable<Cart> = new BehaviorSubject<Cart>({});
  cartEntries$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  loading = true;
  invoiceApprover = false;
  poNumber: any;
  profile$ = this.fffAccountService.getProfile();
  poRules: any;
  // Rapid commit popup visibility variable
  hasRCPopupShown: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected cartService: FFFActiveCartService,
    private multiCartService: MultiCartFacade,
    private changeDetectorRef: ChangeDetectorRef,
    private communicationService: FffCommunicationService,
    private fffAccountService: FffUserAccountService,
    private ngbModal: NgbModal
  ) {}

  ngOnInit(): void {
    this.profile$.subscribe(account => {
      if (account) {
        this.communicationService
          .getPOValidation(account.selected.uid)
          .subscribe((res: any) => {
            if (res) {
              this.poRules = res;
              this.changeDetectorRef.detectChanges();
            }
          });
      }
    });

    this.cart$ = this.cartService
      .getActive()
      .pipe(take(1), takeUntil(this.unsubscribe$));
    this.cart$.subscribe((res: any) => {
      //making sure we're not sending the cart info to the components if we're getting the first empty response
      if (Object.keys(res).length > 0) {
        if (res.rapidCommit && !this.hasRCPopupShown) {
          this.hasRCPopupShown = true;
          const modalRef = this.ngbModal.open(
            FFFCheckoutRapidCommitPopupComponent,
            {
              centered: true,
              size: 'md',
              backdrop: 'static',
              modalDialogClass: 'modal-container',
              keyboard: false,
            }
          );
          modalRef.result.then(data => {
            if (data && data.isRapidCommitAcknowledged) {
              this.hasRCPopupShown = false;
            }
          });
        }

        this.invoiceApprover = res.invoiceApprover;
        this.communicationService
          .resetShippingCosts(res.code)
          .subscribe(res => {});
        this.poNumber = res.purchaseOrderNumber;
        this.changeDetectorRef.detectChanges();

        this.cartService
          .getUpdatedCartATP(res.code!)
          .pipe(take(1), takeUntil(this.unsubscribe$))
          .subscribe(cart => {
            this.multiCartService.loadCart({
              cartId: cart.code,
              userId: 'current',
              extraData: { active: true },
            });
            if (cart.entries && cart.entries.length > 0) {
              this.cartEntries$.next(
                cart.entries.filter((item: any) => item.product.isExcluded)
              );
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.hasRCPopupShown = false;
    if (this.unsubscribe$) {
      this.unsubscribe$.unsubscribe();
    }
  }
}
