export const fffCheckout = {
  fffCheckout: {
    pageTitle: 'Checkout',
    orderRequestPageTitle: 'Order request',
    editCart: 'Edit Cart',
    billingInfo: '1. Billing Information',
    cartSummary: '2. Cart Summary',
    poNumber: 'PO Number',
    referenceName: 'Reference Name',
    invoiceApprover: 'Invoice Approver',
    additionalEmailConf: 'Additional Confirmation Email',
    poInfo: 'Please enter PO number starting with 500',
    optional: 'Optional',
    required: 'Required',
    accountNumber: 'Account Number',
    company: 'Account Name',
    emailAddress: 'Email Address',
    shipToAddress: 'Ship to Address',
    deliveryTerms: 'Delivery Terms',
    paymentTerms: 'Payment Terms',
    fffDiscount: 'FFF Discount',
    federalExciseTax: 'Federal Excise Tax',
    shipping: 'Shipping',
    poDuplicate: 'Duplicate PO Number',
    legend1:
      'Please make sure your PO has only alphanumeric characters with space or dash, no longer than ',
    legend2: ' characters, and starts with ',
    legend3:
      'Please make sure your PO has only alphanumeric characters with space or dash and is no longer than ',
    legend4: ' characters.',
    contactMethod: 'Contact Method',
    CCL: 'Customer Call',
    CEM: 'Customer Email',
    CFX: 'Customer Fax',
    RCL: 'Rep Call',
    REM: 'Rep Email',
    SPS: 'Spreadsheet',
    additionalEmailsText:
      'Enter up to 5 emails, separated by a comma (ex: example1@gmail.com,ex2@gmail.com).',
    rapidCommitPopup: {
      title: 'This is a Rapid Commit Product',
      description:
        'I attest that participation in this Rapid Commit opportunity is necessary to support and maintain patient care, provide inventory levels that are within normal stocking levels, and that I am not purchasing product that will create a stockpile of inventory during a time of market-wide shortage where immediate patient need is of critical.',
      action: 'Acknowledge and Proceed',
    },
  },
};

export const fffCheckoutPlaceOrder = {
  fffCheckoutPlaceOrder: {
    header: 'Error',
    messageWithErrorReference:
      'An error occurred while submitting your order. Please contact Wow! Customer Care at (800) 843-7477 and reference',
    message:
      'An error occurred while submitting your order. Please contact Wow! Customer Care at (800) 843-7477',
    errorCode: 'Error Reference Code: ',
    contact: `If you have questions or concerns, contact customer support using <a class="live-chat cursor-pointer text-underline">Live Chat</a> or by calling (800) 843-7477`,
  },
};

export const fffCheckoutPrepayment = {
  fffCheckoutPrepayment: {
    header: 'Pre-Payment Required',
    message:
      'Please be advised your account requires prepayment prior to your order being processed for shipment. Upon completing your order, please contact the Financial Services Team at (800) 843-7477 Ext. 2362 for further assistance.',
    acknowledgePlaceOrder: 'Acknowledge and Place This Order',
  },
};

export const fffShippingSelector = {
  fffShippingSelector: {
    shipTodayBox: 'Ship Today',
    arrivalDate: 'Est. Arrival Date Range',
    arrivalDateInStock: 'Est. Arrival Date',
    arrivalDateTooltipText:
      'This delivery date is based on days of operation we have stored for you.  Any questions please contact WOW! Customer Care at 800-843-7477.',
    shippingCutOff:
      'Our standard shipping cutoff time for free next day delivery is 12:00 pm PT.',
    shipToday:
      'To ship today, select Ship Today below (shipping charges will apply). ',
    shipNextDay: 'This product will ship the next business day',
    expeditedOptions: 'Expedited Shipping Options',
    quantity: 'Qty',
    totalQty: 'Total Qty Ordered',
    view: 'View Delivery Window / Line Item PO',
    noExpedited: 'No expedited shipping options found',
    loading: 'Loading...',
    overweightPastCutoff:
      'Based on the weight, this order is classified as a freight order. We are past the freight cut-off time, please note that this order will ship on the following business day. If you have any questions please contact Wow! Customer Care at (800) 843-7477.',
  },
};

export const fffShipDrawer = {
  fffShipDrawer: {
    poNumber: 'PO Number',
    poNumberExplain: 'Please enter PO number starting with 500',
    boxes: 'Boxes',
  },
};

export const fffRecurringOrders = {
  fffRecurringOrders: {
    title: 'Recurring Order',
    scheduledShipments: 'Select Your Scheduled Shipments',
    isRecurringOrder: 'Recurring Order',
    orderStartDate: 'Order Start Date',
    frequency: 'Frequency',
    weekly: 'Weekly',
    monthly: 'Monthly',
    frequencyWeeklyOn: 'On',
    frequencyWeeklyWeeks: 'Every # of Weeks',
    frequencyMonthlyOnDay: 'On Day #',
    infoText: 'The same PO Number will be used for each recurring order.',
    invalidCart:
      'You cannot save this as a recurring order as it contains assay products, batch selections or expedited shipping options.',
    days: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
    },
  },
};

export const fffRecurringOrdersPopUp = {
  fffRecurringOrdersPopUp: {
    titleOk: 'Recurring Order',
    titleError: 'Recurring Order',
    message: 'Your recurring order has been created successfully',
  },
};
