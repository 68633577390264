<div class="overview-container">
  <div
    *ngIf="isSuggestedQtyAvailable && !hideSuggestion"
    class="flex-grow-1 p-2 season-info-data"
  >
    <div class="overview-item-label">
      {{ suggestedQtyLabel }}
    </div>
    <div class="overview-item-value">
      <ng-container *ngIf="isBreakupVisible">
        <a
          href="javascript:void(0)"
          class="text-decoration-none text-reset d-inline-flex align-items-center"
          (click)="toggleBreakup()"
        >
          {{ suggestedQty }} bx ({{ suggestedTotalDoses }} ds)
          <cx-icon
            class="cx-icon icon-sm pl-2 cx-icon-info"
            [type]="filledIconTypes.ICON_INFO_CIRCLE_FILL"
          ></cx-icon>
        </a>
      </ng-container>
      <ng-container *ngIf="!isBreakupVisible">
        {{ suggestedQty }} bx ({{ suggestedTotalDoses }} ds)
      </ng-container>
    </div>
  </div>
  <div class="flex-grow-1 p-2 season-info-data">
    <div class="overview-item-label">{{ labelTodaysOrder }}</div>
    <div class="overview-item-value">
      {{ todaysQuantity }} bx ({{ todaysTotalDoses }} ds)
    </div>
  </div>
  <div class="flex-grow-1 p-2 season-info-data">
    <div class="overview-item-label">{{ labelTotal }}</div>
    <div
      [class.orange-label]="!isQtySufficient"
      [class.green-label]="isQtySufficient"
    >
      {{ totalQuantity }} bx ({{ totalDosesForCurrentYear }} ds)
    </div>
  </div>
  <div class="flex-grow-1 p-2 skip-container-mobile">
    <button
      type="button"
      class="body-text-link-sm link mobile-skip-link"
      (click)="proceedToCheckout()"
      [disabled]="skipAndSaving"
    >
      {{ 'fffPrebook.skipToCheckout' | cxTranslate }}
    </button>
  </div>
  <div class="p-2 next-btn-container">
    <button
      type="button"
      class="btn btn-primary w-100 btn-sm"
      (click)="proceedToNext()"
      [disabled]="
        saving ||
        !this.userAccountService.isValidLicense ||
        this.userAccountService.hasNoGPOAssigned ||
        this.userAccountService.hasCreditBlock
      "
    >
      {{ 'fffPrebook.addToCartContinue' | cxTranslate }}
      <cx-icon
        class="cx-icon icon-sm ml-1"
        [type]="outlinedIconTypes.ICON_CHEVRON_RIGHT"
      ></cx-icon>
    </button>
  </div>
</div>

<ng-container *ngIf="showBreakup">
  <div class="mftbc-prebook-products-breakdown" #breakdown>
    <button type="button" class="btn p-0">
      <cx-icon
        class="cx-icon icon-md cursor-pointer"
        [type]="outlinedIconTypes.ICON_X"
        (click)="closeBreakup()"
      ></cx-icon>
    </button>
    <ul>
      <li *ngFor="let product of stageData?.boxesOrderedBreakdownByProduct">
        <div>{{ product?.productName }}</div>
        <ng-container
          [ngTemplateOutlet]="conversionTemplate"
          [ngTemplateOutletContext]="{ quantity: product?.qtyOrdered || 0 }"
        ></ng-container>
      </li>
    </ul>
  </div>
</ng-container>

<ng-template #conversionTemplate let-quantity="quantity">
  {{ quantity }} bx ({{ quantity * 10 }} ds)
</ng-template>
