export interface InvoiceFilters {
  manufacturer?: string;
  ccPaymentEligible?: boolean;
  open?: boolean;
}

// Payment - START
export enum INVOICE_PAYMENT_STEPS {
  SELECT_CARD = 1,
  REVIEW = 2,
  CONFIRMATION = 3,
}

export interface InvoiceCreditCard {
  index?: number;
  cardType?: {
    code?: string;
    name?: string;
  };
  defaultPayment?: boolean;
  cardToken?: string;
  expiryMonth?: string;
  expiryYear?: string;
  saved?: boolean;
  subscriptionId?: string;

  icon?: string;
  selected?: boolean;

  oneTimeUse?: boolean;
  ps2000Data?: string;
  paymentUUID?: string;
}

export interface InvoicePaymentCapturePayload {
  cardToken: string;
  total: number;
  invoices: any[];
  ps2000Data: string;
  surchargeConsent: boolean;
  surchargeTotal: number;
}

export interface InvoicePaymentCaptureResponse {
  amount: number;
  approvalCode: string;
  issuerResponse: string;
  result: string;
  resultMessage: string;
  transactionId: string;
}
