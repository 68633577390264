<ng-container>
  <ng-container *ngIf="isStorageAvailable">
    <ng-container *ngIf="isLoggedIn$ | async as login; else loginDialog">
      <ng-container *ngIf="profile$ | async as profile">
        <ng-container *ngIf="profile.user && profile.selected; else loading">
          <ng-container *ngIf="productData && !displayLoading; else loading">
            <div
              class="tiered-pricing-link"
              (click)="displayTieredPricing()"
              *ngIf="
                productData.product.tieredPricing &&
                productData.shouldDisplayTieredPricesButton &&
                !isShortDated
              "
            >
              {{ 'fffProduct.showTieredPricing' | cxTranslate }}
            </div>

            <div class="product-summary-container">
              <div
                *ngIf="getStockInfo(productData.stockData)"
                class="alloc-container"
              >
                <fff-product-allocation
                  [product]="productData.product"
                  [priceData]="productData"
                  [allocation]="getStockInfo(productData.stockData)"
                ></fff-product-allocation>
              </div>

              <fff-product-admin-set-y-connector
                class="col-12 px-0"
                [product]="productData.product"
              >
              </fff-product-admin-set-y-connector>

              <fff-product-actual-strength
                class="col-12 px-0"
                [product]="productData.product"
                [stockData]="productData.stockData"
              >
              </fff-product-actual-strength>

              <div class="d-flex justify-content-between mt-4 px-3">
                <div>
                  <ng-container
                    *ngIf="
                      (prices$ | async)![productData.product.code] as price
                    "
                  >
                    <ng-container
                      *ngIf="
                        !isOfVaccineTypeI(productData.product);
                        else vaccineUomTemplate
                      "
                    >
                      <ng-container
                        *ngIf="
                          getAltPriceI(
                            price[0].price.priceMap,
                            productData.product?.altUnit
                          ) &&
                          getAltPriceI(
                            price[0].price.priceMap,
                            productData.product?.altUnit
                          )?.min &&
                          getAltPriceI(
                            price[0].price.priceMap,
                            productData.product?.altUnit
                          )?.altUOM
                        "
                      >
                        <div class="volume body-text-sm pt-2">
                          1 {{ price[0].price.basicUOM }} =
                          {{
                            getAltPriceI(
                              price[0].price.priceMap,
                              productData.product?.altUnit
                            )?.min
                          }}
                          {{
                            getAltPriceI(
                              price[0].price.priceMap,
                              productData.product?.altUnit
                            )?.altUOM
                          }}
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-template #vaccineUomTemplate>
                      <div class="volume body-text-sm text-nowrap pt-2">
                        1 {{ price[0].price.basicUOM }} = 10 DS
                      </div>
                    </ng-template>
                    <ng-container
                      *ngIf="
                        price[0].price.priceMap[price[0].price.basicUOM]
                          ?.additionalConversion
                      "
                    >
                      <div class="volume body-text-sm pt-2">
                        <span
                          *ngIf="
                            getAltPriceI(
                              price[0].price.priceMap,
                              productData.product?.altUnit
                            ) &&
                            getAltPriceI(
                              price[0].price.priceMap,
                              productData.product?.altUnit
                            )?.min &&
                            getAltPriceI(
                              price[0].price.priceMap,
                              productData.product?.altUnit
                            )?.altUOM
                          "
                          >,&nbsp;
                        </span>
                        {{
                          price[0].price.priceMap[price[0].price.basicUOM]
                            .additionalConversion
                        }}
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
                <div>
                  <div
                    *ngIf="
                      !productData.product.hideStockIndicator &&
                      !productData?.product?.notOrderable
                    "
                  >
                    <div
                      *ngIf="
                        getStockInfo(productData.stockData);
                        else noStockData
                      "
                    >
                      <ng-container>
                        <fff-product-stock-status
                          [pCode]="productData.product?.code"
                          [stock]="getStockInfo(productData.stockData)"
                          [product]="productData.product"
                        ></fff-product-stock-status>
                      </ng-container>
                    </div>
                  </div>

                  <ng-template #noStockData>
                    <h5 class="body-text-xl mb-3">
                      <b
                        *ngIf="productData.product.onDemand"
                        class="on-demand"
                        >{{ 'fffProduct.lblOnDemand' | cxTranslate }}</b
                      >
                      <ng-container *ngIf="!productData.product.onDemand">
                        <b class="in-transit">
                          {{ 'fffProduct.lblInTransit' | cxTranslate }}
                        </b>
                        <div class="outOfStock-note">
                          <small>
                            {{ 'fffProduct.lblOutOfStockNote' | cxTranslate }}
                          </small>
                        </div>
                      </ng-container>
                    </h5>
                  </ng-template>
                </div>
              </div>

              <fff-split-billing
                [allocation]="getStockInfo(productData.stockData)"
                [product]="productData.product"
                [isSplitBillerAccount]="
                  !!profile.selected.autoSplitBillerActive
                "
                [pricesData]="productData.pricesData"
              ></fff-split-billing>

              <div
                class="d-flex justify-content-between py-2 mt-4 pl-0 button-container"
              >
                <fff-add-to-favorites
                  *ngIf="!productData?.product?.notOrderable"
                  [product]="productData.product"
                ></fff-add-to-favorites>
                <fff-tooltip
                  *ngIf="tooltipText !== ''"
                  [alwaysVisible]="false"
                  [isTranslatableLabel]="true"
                  [text]="tooltipText"
                ></fff-tooltip>
                <fff-add-to-cart
                  (cartErrorEventEmitter)="cartErrorEventEmitter($event)"
                  [stockAndAlloc]="getStockInfo(productData.stockData)"
                  [product]="productData.product"
                  [b2bunit]="profile.selected"
                  [pricesData]="productData.pricesData"
                ></fff-add-to-cart>
              </div>

              <ng-container
                *ngIf="
                  getStockInfo(productData.stockData) &&
                  getStockInfo(productData.stockData)?.yearlyAllocatedQty > 0
                "
              >
                <div class="col-8 mt-2 annual-container p-3">
                  <p class="body-text-xl details-title mt-0 mb-0">
                    {{ 'fffProduct.details.annualCommitment' | cxTranslate }} :
                    {{
                      getStockInfo(productData.stockData)?.actionCSY
                        ? getStockInfo(productData.stockData)
                            ?.yearlyAllocatedQty
                        : '~'
                    }}
                  </p>

                  <p class="body-text-xl details-title mt-0">
                    {{ 'fffProduct.details.purchased' | cxTranslate }} :
                    {{
                      getStockInfo(productData.stockData)?.actionCSY
                        ? getStockInfo(productData.stockData)
                            ?.yearlyAllocatedQty -
                          getStockInfo(productData.stockData)
                            ?.yearlyRemainingQty
                        : '~'
                    }}
                  </p>

                  <div
                    class="bar-background"
                    [ngStyle]="{ width: barSize + 'px' }"
                    *ngIf="getStockInfo(productData.stockData)?.actionCSY"
                  >
                    <div
                      class="blue-bar"
                      [ngStyle]="{
                        width:
                          getBarProp(
                            getStockInfo(productData.stockData)
                              ?.yearlyRemainingQty,
                            +getStockInfo(productData.stockData)
                              ?.yearlyAllocatedQty
                          ) + 'px'
                      }"
                    ></div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loginDialog>
  <div class="col-12 d-flex flex-wrap">
    <div class="col-12 loginDialog text-center my-3">
      <div class="login-request">
        <a
          (click)="goTo('/login')"
          class="cursor-pointer text-underline details-title"
          routerLinkActive="active"
          >{{ 'fffLogin.login' | cxTranslate }}</a
        >
        {{ 'fffProduct.lblProductDesc' | cxTranslate }}
      </div>
      <p>
        {{ 'fffProduct.lblNoAccount' | cxTranslate }} <br />
        <a
          (click)="goTo('/register-account')"
          class="cursor-pointer text-underline"
          routerLinkActive="active"
          >{{ 'fffProduct.lblRegisterNow' | cxTranslate }}</a
        >
      </p>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <ng-container *ngIf="!isProductAPIFailed; else unableToLoadInfo">
    <fff-products-loader></fff-products-loader>
  </ng-container>
</ng-template>

<!--color: 003159 ; font-size: 1.5rem ; -->
<ng-template #unableToLoadInfo>
  <div class="unableToLoadInfo">
    <div class="mb-2">
      <cx-icon
        class="cx-icon"
        [type]="outlinedIconTypes.ICON_INFO_CIRCLE"
      ></cx-icon>
    </div>
    <div>{{ 'fffProduct.unableToLoad' | cxTranslate }}</div>
    <div>{{ 'fffProduct.pleaseTryLater' | cxTranslate }}</div>
  </div>
</ng-template>
